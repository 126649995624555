/*-----------------------------------------------------------------------------------
    
    Template Name: Norda - Minimal eCommerce HTML Template
    Version: 1.2
    
-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
    1. Template default CSS
    2. Animation CSS
    3. Header style
    4. Slider style
    5. Service style
    6. About us style
    7. Section title style
    8. Product style
    9. Banner style
    10. Brand logo style
    11. Subscribe style
    12. Footer style
    13. Instagram style
    14. Blog style
    15. Others style
    16. Product details style
    17. Breadcrumb style
    18. Sidebar style
    19. Shop style
    20. Cart style
    21. Compare style
    22. Checkout style
    23. My account style
    24. Login register style
    25. Contect us style
    26. Blog details style
    27. Order tracking style
    
-----------------------------------------------------------------------------------*/
@import 'variabls';
@import 'common';
@import 'animation';
@import 'header';
@import 'slider';
@import 'service';
@import 'about-us';
@import 'section-title';
@import 'product';
@import 'banner';
@import 'brand-logo';
@import 'subscribe';
@import 'footer';
@import 'instagram';
@import 'blog';
@import 'others';
@import 'product-details';
@import 'breadcrumb';
@import 'sidebar';
@import 'shop';
@import 'cart';
@import 'compare';
@import 'checkout';
@import 'my-account';
@import 'login-register';
@import 'contect-us';
@import 'blog-details';
@import 'order-tracking';







/*-----------------------------------------------------------------------------------
    
    Template Name: Norda - Minimal eCommerce HTML Template
    Version: 1.2
    
-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
    1. Template default CSS
    2. Animation CSS
    3. Header style
    4. Slider style
    5. Service style
    6. About us style
    7. Section title style
    8. Product style
    9. Banner style
    10. Brand logo style
    11. Subscribe style
    12. Footer style
    13. Instagram style
    14. Blog style
    15. Others style
    16. Product details style
    17. Breadcrumb style
    18. Sidebar style
    19. Shop style
    20. Cart style
    21. Compare style
    22. Checkout style
    23. My account style
    24. Login register style
    25. Contect us style
    26. Blog details style
    27. Order tracking style
    
-----------------------------------------------------------------------------------*/
/*----------------------------------------*/
/*  1. Template default CSS
/*----------------------------------------*/
/*-- Google Font --*/
@import url("https://fonts.googleapis.com/css2?family=Crimson+Text:wght@700&family=Heebo:wght@300;400;500;600;700;800;900&family=Jost:wght@400;500;600;700&family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
/*-- Common Style --*/
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.row {
  margin-right: calc(var(--bs-gutter-x) * -.6);
  margin-left: calc(var(--bs-gutter-x) * -.6);
}

.row > * {
  padding-right: calc(var(--bs-gutter-x) * .6);
  padding-left: calc(var(--bs-gutter-x) * .6);
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  padding-right: var(--bs-gutter-x, 0.9rem);
  padding-left: var(--bs-gutter-x, 0.9rem);
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  line-height: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #000;
  font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  font-weight: 400;
  margin-top: 0;
  font-family: "Poppins", sans-serif;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #666;
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

p:last-child {
  margin-bottom: 0;
}

a,
button {
  color: inherit;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #000;
}

a,
button,
img,
input,
span {
  transition: all .3s ease 0s;
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button,
input[type="submit"] {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

/*-- 
    - Common Classes
-----------------------------------------*/
.fix {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.section {
  float: left;
  width: 100%;
}

.f-right {
  float: right;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.bg-img {
  background-position: center center;
  background-size: cover;
}

.position-relative {
  position: relative;
}

/*************************
  Basic margin padding
*************************/
.m-0 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.p-0 {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

/*************************
         Margin top
*************************/
.mt-0 {
  margin-top: 0;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-130 {
    margin-top: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-130 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .mt-130 {
    margin-top: 60px;
  }
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-175 {
  margin-top: 175px;
}

/*************************
      Margin right
*************************/
.mr-0 {
  margin-right: 0px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-150 {
  margin-right: 150px;
}

/*************************
      Margin bottom
*************************/
.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-37 {
  margin-bottom: 37px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-180 {
  margin-bottom: 180px;
}

/*************************
        Margin left
*************************/
.ml-0 {
  margin-left: 0;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-105 {
  margin-left: 105px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-135 {
  margin-left: 135px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-200 {
  margin-left: 200px;
}

.ml-300 {
  margin-left: 300px;
}

.ml-ntv5 {
  margin-left: -5px;
}

/*************************
        Padding top
*************************/
.pt-0 {
  padding-top: 0;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-105 {
    padding-top: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-105 {
    padding-top: 25px;
  }
}

.pt-110 {
  padding-top: 110px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-110 {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-110 {
    padding-top: 50px;
  }
}

.pt-115 {
  padding-top: 115px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-115 {
    padding-top: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-115 {
    padding-top: 55px;
  }
}

.pt-120 {
  padding-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-120 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-120 {
    padding-top: 60px;
  }
}

.pt-125 {
  padding-top: 125px;
}

.pt-128 {
  padding-top: 128px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-135 {
    padding-top: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .pt-135 {
    padding-top: 55px;
  }
}

.pt-165 {
  padding-top: 165px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

.pt-220 {
  padding-top: 220px;
}

.pt-250 {
  padding-top: 250px;
}

.pt-255 {
  padding-top: 255px;
}

.pt-210 {
  padding-top: 210px;
}

.pt-230 {
  padding-top: 230px;
}

.pt-270 {
  padding-top: 270px;
}

.pt-290 {
  padding-top: 290px;
}

.pt-295 {
  padding-top: 295px;
}

.pt-280 {
  padding-top: 280px;
}

.pt-240 {
  padding-top: 240px;
}

.pt-330 {
  padding-top: 330px;
}

.pt-340 {
  padding-top: 340px;
}

.pt-350 {
  padding-top: 350px;
}

.pt-365 {
  padding-top: 365px;
}

.pt-360 {
  padding-top: 360px;
}

.pt-370 {
  padding-top: 370px;
}

.pt-380 {
  padding-top: 380px;
}

.pt-400 {
  padding-top: 400px;
}

/*************************
        Padding right
*************************/
.pr-0 {
  padding-right: 0;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-270 {
  padding-right: 270px;
}

/*************************
        Padding bottom
*************************/
.pb-0 {
  padding-bottom: 0;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-105 {
    padding-bottom: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-105 {
    padding-bottom: 45px;
  }
}

.pb-110 {
  padding-bottom: 110px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-110 {
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-110 {
    padding-bottom: 50px;
  }
}

.pb-115 {
  padding-bottom: 115px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-115 {
    padding-bottom: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-115 {
    padding-bottom: 55px;
  }
}

.pb-120 {
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-120 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-120 {
    padding-bottom: 60px;
  }
}

.pb-125 {
  padding-bottom: 125px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-125 {
    padding-bottom: 65px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-125 {
    padding-bottom: 45px;
  }
}

.pb-130 {
  padding-bottom: 130px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-130 {
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-130 {
    padding-bottom: 50px;
  }
}

.pb-135 {
  padding-bottom: 135px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-135 {
    padding-bottom: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-135 {
    padding-bottom: 55px;
  }
}

.pb-140 {
  padding-bottom: 140px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-140 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .pb-140 {
    padding-bottom: 60px;
  }
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-280 {
  padding-bottom: 280px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pb-205 {
  padding-bottom: 205px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pb-220 {
  padding-bottom: 220px;
}

.pb-215 {
  padding-bottom: 215px;
}

.pb-250 {
  padding-bottom: 250px;
}

.pb-285 {
  padding-bottom: 285px;
}

.pb-270 {
  padding-bottom: 270px;
}

.pb-290 {
  padding-bottom: 290px;
}

.pb-315 {
  padding-bottom: 315px;
}

.pb-330 {
  padding-bottom: 330px;
}

.pb-335 {
  padding-bottom: 335px;
}

.pb-340 {
  padding-bottom: 340px;
}

.pb-325 {
  padding-bottom: 325px;
}

.pb-320 {
  padding-bottom: 300px;
}

/*************************
    Padding left
*************************/
.pl-0 {
  padding-left: 0;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-190 {
  padding-left: 190px;
}

/***************************
    Page section padding 
****************************/
.ptb-0 {
  padding: 0;
}

.ptb-10 {
  padding: 10px 0;
}

.ptb-20 {
  padding: 20px 0;
}

.ptb-30 {
  padding: 30px 0;
}

.ptb-35 {
  padding: 35px 0;
}

.ptb-32 {
  padding: 32px 0;
}

.ptb-40 {
  padding: 40px 0;
}

.ptb-50 {
  padding: 50px 0;
}

.ptb-60 {
  padding: 60px 0;
}

.ptb-70 {
  padding: 70px 0;
}

.ptb-80 {
  padding: 80px 0;
}

.ptb-90 {
  padding: 90px 0;
}

.ptb-100 {
  padding: 100px 0;
}

.ptb-110 {
  padding: 110px 0;
}

.ptb-120 {
  padding: 120px 0;
}

.ptb-130 {
  padding: 130px 0;
}

.ptb-140 {
  padding: 140px 0;
}

.ptb-150 {
  padding: 150px 0;
}

.ptb-160 {
  padding: 160px 0;
}

.ptb-170 {
  padding: 170px 0;
}

.ptb-177 {
  padding: 177px 0;
}

.ptb-180 {
  padding: 180px 0;
}

.ptb-190 {
  padding: 190px 0;
}

.ptb-200 {
  padding: 200px 0;
}

.ptb-210 {
  padding: 210px 0;
}

.ptb-220 {
  padding: 220px 0;
}

.ptb-290 {
  padding: 290px 0;
}

.ptb-310 {
  padding: 310px 0;
}

/***************************
    Page section margin 
****************************/
.mtb-0 {
  margin: 0;
}

.mtb-10 {
  margin: 10px 0;
}

.mtb-15 {
  margin: 15px 0;
}

.mtb-20 {
  margin: 20px 0;
}

.mtb-30 {
  margin: 30px 0;
}

.mtb-40 {
  margin: 40px 0;
}

.mtb-50 {
  margin: 50px 0;
}

.mtb-60 {
  margin: 60px 0;
}

.mtb-70 {
  margin: 70px 0;
}

.mtb-80 {
  margin: 80px 0;
}

.mtb-90 {
  margin: 90px 0;
}

.mtb-100 {
  margin: 100px 0;
}

.mtb-110 {
  margin: 110px 0;
}

.mtb-120 {
  margin: 120px 0;
}

.mtb-130 {
  margin: 130px 0;
}

.mtb-140 {
  margin: 140px 0;
}

.mtb-150 {
  margin: 150px 0;
}

.mtb-290 {
  margin: 290px 0;
}

/*-- 
    - Input Placeholder
-----------------------------------------*/
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input {
  background: #eceff8;
  border: 2px solid #eceff8;
  height: 45px;
  box-shadow: none;
  padding-left: 10px;
  font-size: 14px;
  color: #737373;
  width: 100%;
}

select {
  width: 100%;
  background: transparent;
  border: 0px solid #eceff8;
  box-shadow: none;
  font-size: 14px;
  color: #737373;
}

option {
  background: #fff;
  border: 0px solid #626262;
  padding-left: 10px;
  font-size: 14px;
}

input:focus {
  background: transparent;
}

textarea {
  resize: vertical;
  background: #eceff8;
  border: 2px solid #eceff8;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  color: #737373;
}

textarea:focus {
  background: transparent;
  border: 2px solid #4FC1F0;
  outline: none;
}

.custom-d-flex {
  display: flex !important;
}

.custom-align-item-center {
  align-items: center;
}

.custom-align-item-end {
  align-items: flex-end;
}

.justify-content-center {
  align-items: center;
}

.custom-col-5 {
  flex: 0 0 20%;
  max-width: 20%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .custom-col-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-col-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-col-5 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-5 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .custom-col-5 {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-col-5 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

/*-- 
    Scroll Up 
-----------------------------------------*/
#scrollUp {
  width: 40px;
  height: 40px;
  background-color: transparent;
  color: #666;
  right: 70px;
  bottom: 120px;
  text-align: center;
  overflow: hidden;
  border: 1px solid #666;
  z-index: 999 !important;
}

#scrollUp:hover {
  color: #ffffff;
  border: 1px solid #ff2f2f;
  background-color: #ff2f2f;
}

@media only screen and (max-width: 767px) {
  #scrollUp {
    width: 40px;
    height: 40px;
    right: 15px;
  }
}

#scrollUp i {
  display: block;
  line-height: 38px;
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  #scrollUp i {
    line-height: 40px;
    font-size: 16px;
  }
}

.height-100vh {
  height: 100vh !important;
}

.transparent-bar {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}

/*--
    Background Color
------------------------------------------*/
.bg-gray {
  background-color: #f0f4f6;
}

.bg-gray-2 {
  background-color: #efeeed;
}

.bg-gray-3 {
  background-color: #f9f9f9;
}

.bg-gray-4 {
  background-color: #efefef;
}

.bg-gray-5 {
  background-color: #e5e5e5;
}

.bg-gray-6 {
  background-color: #ececec;
}

.bg-gray-7 {
  background-color: #eaeff2;
}

.bg-gray-8 {
  background-color: #f4f4f4;
}

.bg-gray-9 {
  background-color: #f6f6f6;
}

.bg-gray-10 {
  background-color: #e7eaec;
}

.bg-white {
  background-color: #ffffff;
}

.bg-black {
  background-color: #000000;
}

.bg-blue {
  background-color: #0064e0;
}

.bg-orange {
  background-color: #e44311;
}

.bg-green-2 {
  background-color: #6aae27;
}

.border-bottom-1 {
  border-bottom: 1px solid #dadada;
}

.border-bottom-2 {
  border-bottom: 1px solid #e8e8e8;
}

.border-bottom-3 {
  border-bottom: 1px solid #e1e1e1;
}

.border-bottom-4 {
  border-bottom: 1px solid #dbdbdb;
}

.border-bottom-5 {
  border-bottom: 1px solid #e3e3e3;
}

.border-bottom-6 {
  border-bottom: 1px solid #eaeaea;
}

.border-bottom-7 {
  border-bottom: 1px solid #e2e2e2;
}

.border-bottom-8 {
  border-bottom: 1px solid #408be8;
}

.border-bottom-9 {
  border-bottom: 1px solid #eb724d;
}

.default-overlay {
  position: relative;
}

.default-overlay:before {
  content: "";
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 250ms ease-out;
  z-index: 9;
}

.default-overlay.overly-point-4:before {
  background-color: #181818;
  opacity: .4;
}

.default-overlay-white {
  position: relative;
}

.default-overlay-white:before {
  content: "";
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 250ms ease-out;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .res-white-overly-xs {
    position: relative;
  }
  .res-white-overly-xs:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #fff;
    opacity: .4;
    z-index: 9;
  }
}

@media only screen and (max-width: 767px) {
  .res-black-overly-xs {
    position: relative;
  }
  .res-black-overly-xs:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #000;
    opacity: .5;
    z-index: 9;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .res-white-overly-md {
    position: relative;
  }
  .res-white-overly-md:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #fff;
    opacity: .5;
    z-index: 1;
  }
}

@media only screen and (max-width: 767px) {
  .xs-mb-30 {
    margin-bottom: 30px;
  }
}

.section-padding-1 .container-fluid {
  padding: 0 180px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-1 .container-fluid {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-1 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-1 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-1 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-1 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-padding-1 .container-fluid {
    padding: 0 30px;
  }
}

.section-padding-2 .container-fluid {
  padding: 0 70px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-2 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-2 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-2 .container-fluid {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-2 .container-fluid {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-2 .container-fluid {
    padding: 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-padding-2 .container-fluid {
    padding: 0 30px;
  }
}

.padding-10-row-col .row {
  margin-right: -5px;
  margin-left: -5px;
}

.padding-10-row-col .row div[class^="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.box-layout-width {
  display: block;
  margin: 0px auto;
  width: 1310px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .box-layout-width {
    width: 1200px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .box-layout-width {
    width: 1170px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .box-layout-width {
    width: 970px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .box-layout-width {
    width: 730px;
  }
}

@media only screen and (max-width: 767px) {
  .box-layout-width {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .box-layout-width {
    width: 550px;
  }
}

.box-layout-width-2 {
  display: block;
  margin: 0px auto;
  width: 1370px;
  background-color: #ffffff;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .box-layout-width-2 {
    width: 1200px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .box-layout-width-2 {
    width: 1170px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .box-layout-width-2 {
    width: 970px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .box-layout-width-2 {
    width: 730px;
  }
}

@media only screen and (max-width: 767px) {
  .box-layout-width-2 {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .box-layout-width-2 {
    width: 550px;
  }
}

/*-------- 2. Animation CSS ---------*/
@keyframes hastechFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hastechZoomIn {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hastechZoomOut {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hastechMoveUp {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hastechMoveUpBig {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hastechMoveDown {
  0% {
    opacity: 0;
    transform: translateY(-25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hastechMoveDownBig {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hastechMoveLeft {
  0% {
    opacity: 0;
    transform: translateX(25px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes hastechMoveLeftBig {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes hastechMoveRight {
  0% {
    opacity: 0;
    transform: translateX(-25px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes hastechMoveRightBig {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes hastechFallPerspective {
  0% {
    opacity: 0;
    transform: perspective(1000px) translateY(50px) translateZ(-300px) rotateX(-35deg);
  }
  100% {
    opacity: 1;
    transform: perspective(1000px) translateY(0) translateZ(0) rotateX(0deg);
  }
}

@keyframes hastechFlipInX {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}

@keyframes hastechFlipInY {
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes zoomOut {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes moveUp {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes columnMoveUp {
  0% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fallPerspective {
  0% {
    opacity: 0;
    transform: perspective(1000px) translateY(50px) translateZ(-300px) rotateX(-35deg);
  }
  100% {
    opacity: 1;
    transform: perspective(1000px) translateY(0) translateZ(0) rotateX(0deg);
  }
}

@keyframes edit-button-pulse {
  0% {
    box-shadow: 0 0 2px 0 rgba(183, 8, 78, 0.6);
  }
  30% {
    box-shadow: 0 0 2px 10px rgba(183, 8, 78, 0);
  }
  100% {
    box-shadow: 0 0 2px 0 rgba(183, 8, 78, 0);
  }
}

@keyframes shortfadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.shortfadeInUp {
  animation-name: shortfadeInUp;
}

.animated-duration {
  animation-duration: 1.25s;
}

.custom-delay-200 {
  animation-delay: .2s;
}

.custom-delay-300 {
  animation-delay: .3s;
}

.custom-delay-400 {
  animation-delay: .4s;
}

.custom-delay-500 {
  animation-delay: .5s;
}

.custom-delay-600 {
  animation-delay: .6s;
}

.custom-delay-700 {
  animation-delay: .7s;
}

.custom-delay-800 {
  animation-delay: .8s;
}

.custom-delay-900 {
  animation-delay: .9s;
}

/*-------- 3. Header style ---------*/
.header-small-device {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-small-device {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .header-small-device {
    display: block;
  }
}

.header-small-device.small-device-ptb-1 {
  padding: 25px 0 25px;
}

.header-large-device {
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-large-device {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header-large-device {
    display: none;
  }
}

.header-offer-wrap p {
  color: #000000;
  margin: 0;
}

.header-offer-wrap p span {
  color: #ff2f2f;
}

.header-offer-wrap p i {
  font-size: 16px;
  margin: 0 9px 0 0;
  position: relative;
  top: 2px;
}

.header-top-right {
  display: flex;
  justify-content: flex-end;
}

.same-style-wrap {
  display: flex;
}

.same-style-wrap .same-style {
  margin-right: 60px;
  position: relative;
}

.same-style-wrap .same-style.same-style-mrg-2 {
  margin-right: 42px;
}

.same-style-wrap .same-style.same-style-mrg-2:last-child {
  margin-right: 0px;
}

.same-style-wrap .same-style.same-style-mrg-2 a {
  color: #666;
}

.same-style-wrap .same-style.same-style-mrg-3 {
  margin-right: 22px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .same-style-wrap .same-style.same-style-mrg-3 {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .same-style-wrap .same-style.same-style-mrg-3 {
    margin-right: 5px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .same-style-wrap .same-style.same-style-mrg-3 {
    margin-right: 7px;
  }
}

.same-style-wrap .same-style.same-style-mrg-3:last-child {
  margin-right: 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .same-style-wrap .same-style {
    margin-right: 25px;
  }
}

.same-style-wrap .same-style:last-child {
  margin-right: 0px;
}

.same-style-wrap .same-style a {
  color: #000000;
  display: inline-block;
}

.same-style-wrap .same-style a i {
  font-size: 12px;
  margin: 0 0 0 3px;
}

.same-style-wrap .same-style.same-style-border:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 1px;
  background-color: #dedede;
  right: -31px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .same-style-wrap .same-style.same-style-border:before {
    right: -12px;
  }
}

.same-style-wrap .same-style.track-order a {
  text-decoration: underline;
}

.same-style-wrap .same-style .language-dropdown, .same-style-wrap .same-style .currency-dropdown {
  background: #000 none repeat scroll 0 0;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 99;
  box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.1);
  display: none;
}

.same-style-wrap .same-style .language-dropdown ul, .same-style-wrap .same-style .currency-dropdown ul {
  padding: 14px 0px 15px;
}

.same-style-wrap .same-style .language-dropdown ul li, .same-style-wrap .same-style .currency-dropdown ul li {
  display: block;
  padding-bottom: 5px;
}

.same-style-wrap .same-style .language-dropdown ul li:last-child, .same-style-wrap .same-style .currency-dropdown ul li:last-child {
  padding-bottom: 0;
}

.same-style-wrap .same-style .language-dropdown ul li a, .same-style-wrap .same-style .currency-dropdown ul li a {
  color: #ffffff;
  display: block;
  font-size: 13px;
  padding: 0 25px;
}

.same-style-wrap .same-style .language-dropdown ul li a:hover, .same-style-wrap .same-style .currency-dropdown ul li a:hover {
  color: #ff2f2f;
}

.same-style-wrap .same-style .currency-dropdown {
  width: 90px;
}

.same-style-wrap .same-style:hover > a {
  color: #ff2f2f;
}

.social-style-1 {
  display: flex;
}

.social-style-1 a {
  color: #000000;
  font-size: 16px;
  display: inline-block;
  margin-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .social-style-1 a {
    margin-right: 14px;
  }
}

.social-style-1 a:last-child {
  margin-right: 0;
}

.social-style-1 a:hover {
  color: #ff2f2f;
}

.social-style-1.social-style-1-white a {
  color: #ffffff;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .social-style-1.social-style-1-white a {
    margin-right: 12px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .social-style-1.social-style-1-white a {
    margin-right: 8px;
  }
}

.social-style-1.social-style-1-white a:hover {
  color: #ff2f2f;
}

.social-style-1.social-style-1-mrg {
  margin-left: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .social-style-1.social-style-1-mrg {
    margin-left: 25px;
  }
}

.social-style-1.social-style-1-mrg-2 {
  margin: 55px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .social-style-1.social-style-1-mrg-2 {
    margin: 17px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .social-style-1.social-style-1-mrg-2 {
    margin: 20px 0 0;
  }
}

.social-style-1.social-style-1-font-inc a {
  font-size: 18px;
}

.header-top-ptb-1 {
  padding: 14px 0 14px;
}

.header-top-ptb-2 {
  padding: 60px 0 60px;
}

.header-top-ptb-3 {
  padding: 7px 0 7px;
}

.header-top-ptb-6 {
  padding: 11px 0 11px;
}

.header-top-ptb-7 {
  padding: 20px 0 20px;
}

.main-menu > nav > ul > li {
  display: inline-block;
  position: relative;
}

.main-menu > nav > ul > li > ul {
  background: #fff none repeat scroll 0 0;
  box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  display: block;
  left: 0;
  padding: 28px 25px 28px;
  position: absolute;
  top: 100%;
  transform: rotateX(90deg);
  transform-origin: center top 0;
  transition: all 0.5s ease 0s;
  visibility: hidden;
  z-index: 99;
}

.main-menu > nav > ul > li > ul li {
  display: block;
  position: relative;
  line-height: 1;
  margin-bottom: 20px;
}

.main-menu > nav > ul > li > ul li:last-child {
  margin-bottom: 0px;
}

.main-menu > nav > ul > li > ul li a {
  display: block;
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: .2px;
  text-transform: capitalize;
}

.main-menu > nav > ul > li > ul li:hover > a {
  color: #ff2f2f;
}

.main-menu > nav > ul > li > ul.sub-menu-style {
  width: 190px;
  text-align: left;
}

.main-menu > nav > ul > li > ul.mega-menu-style {
  width: 750px;
  padding: 28px 30px 0px;
  text-align: left;
}

.main-menu > nav > ul > li > ul.mega-menu-style.mega-menu-mrg-1 {
  left: -100px;
}

.main-menu > nav > ul > li > ul.mega-menu-style.mega-menu-mrg-2 {
  left: -100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu > nav > ul > li > ul.mega-menu-style.mega-menu-mrg-2 {
    left: -150px;
  }
}

.main-menu > nav > ul > li > ul.mega-menu-style > li > ul {
  display: flex;
  flex-wrap: wrap;
}

.main-menu > nav > ul > li > ul.mega-menu-style > li > ul > li {
  flex: 0 0 33.333%;
  max-width: 33.333%;
}

.main-menu > nav > ul > li > ul.mega-menu-style > li > ul > li > a.dropdown-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
}

.main-menu > nav > ul > li > ul.mega-menu-style > li > ul > li ul li {
  margin-bottom: 22px;
}

.main-menu > nav > ul > li > ul.mega-menu-style > li > ul > li ul li:last-child {
  margin-bottom: 0;
}

.main-menu > nav > ul > li a {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
}

.main-menu > nav > ul > li a span {
  position: absolute;
  top: 13px;
  right: 15px;
  color: #ffffff;
  font-size: 12px;
  line-height: 1;
  padding: 1px 5px 3px;
  border-radius: 3px;
}

.main-menu > nav > ul > li a span.bg-red {
  background-color: #ff2f2f;
}

.main-menu > nav > ul > li a span.bg-green {
  background-color: #70c01a;
}

.main-menu > nav > ul > li a i {
  position: relative;
  top: 1px;
  margin-left: 4px;
}

.main-menu > nav > ul > li:hover > a {
  color: #ff2f2f;
}

.main-menu > nav > ul > li:hover > ul {
  transform: rotateX(0deg);
  visibility: visible;
}

.main-menu.main-menu-white > nav > ul > li > a {
  color: #ffffff;
}

.main-menu.main-menu-white > nav > ul > li:hover > a {
  color: #ff2f2f;
}

.main-menu.main-menu-white.main-menu-hover-black > nav > ul > li:hover > a {
  color: #000000;
}

.main-menu.main-menu-font-size-14 > nav > ul > li a {
  font-size: 14px;
}

.main-menu.main-menu-hover-semibold > nav > ul > li a.active {
  font-weight: 600;
}

.main-menu.main-menu-hover-semibold > nav > ul > li:hover > a {
  font-weight: 600;
}

.main-menu.main-menu-hover-border > nav > ul > li {
  position: relative;
}

.main-menu.main-menu-hover-border > nav > ul > li > a {
  position: relative;
}

.main-menu.main-menu-hover-border > nav > ul > li > a::after {
  content: "";
  height: 1px;
  right: 0px;
  left: auto;
  width: 0;
  bottom: 15px;
  position: absolute;
  transition: width 300ms ease-out;
  background-color: #000000;
}

.main-menu.main-menu-hover-border > nav > ul > li > a.active::after {
  left: 0px;
  right: auto;
  width: 100%;
  transition: width 300ms ease-in;
}

.main-menu.main-menu-hover-border > nav > ul > li:hover > a {
  color: #000000;
}

.main-menu.main-menu-hover-border > nav > ul > li:hover > a::after {
  left: 0px;
  right: auto;
  width: 100%;
  transition: width 300ms ease-in;
}

.main-menu.main-menu-hm4 > nav > ul > li > a {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .5px;
}

.main-menu.main-menu-hm4 > nav > ul > li > a.active {
  font-weight: bold;
}

.main-menu.main-menu-hm4 > nav > ul > li:hover > a {
  font-weight: bold;
  color: #000000;
}

.main-menu.main-menu-padding-1 > nav > ul > li {
  padding-right: 46px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.main-menu-padding-1 > nav > ul > li {
    padding-right: 40px;
  }
}

.main-menu.main-menu-padding-1 > nav > ul > li:last-child {
  padding-right: 0px;
}

.main-menu.main-menu-padding-2 > nav > ul > li {
  padding-right: 50px;
}

.main-menu.main-menu-padding-2 > nav > ul > li:last-child {
  padding-right: 0px;
}

.main-menu.main-menu-padding-3 > nav > ul > li {
  padding-right: 30px;
}

.main-menu.main-menu-padding-3 > nav > ul > li:last-child {
  padding-right: 0px;
}

.main-menu.main-menu-lh-1 > nav > ul > li {
  line-height: 95px;
}

.main-menu.main-menu-lh-2 > nav > ul > li {
  line-height: 78px;
}

.main-menu.main-menu-lh-3 > nav > ul > li {
  line-height: 100px;
}

.main-menu.main-menu-lh-4 > nav > ul > li {
  line-height: 55px;
}

.main-menu.main-menu-lh-5 > nav > ul > li {
  line-height: 48px;
}

.main-menu.main-menu-font-width-400 > nav > ul > li a {
  font-weight: 400;
}

.main-menu.main-menu-center {
  text-align: center;
}

.header-action.header-action-flex {
  display: flex;
  justify-content: flex-end;
}

.header-action.header-action-mrg-right {
  margin-right: 13px;
}

.header-action .same-style-2 {
  line-height: 1;
  margin-right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-action .same-style-2 {
    margin-right: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .header-action .same-style-2 {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-action .same-style-2 {
    margin-right: 25px;
  }
}

.header-action .same-style-2:last-child {
  margin-right: 0;
}

.header-action .same-style-2 a {
  color: #000000;
  font-size: 22px;
  display: inline-block;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .header-action .same-style-2 a {
    font-size: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-action .same-style-2 a {
    font-size: 22px;
  }
}

.header-action .same-style-2 a span.pro-count {
  position: absolute;
  top: -8px;
  font-size: 12px;
  width: 20px;
  height: 20px;
  display: inline-block;
  line-height: 19px;
  color: #fff;
  text-align: center;
  left: 14px;
  border-radius: 50px;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .header-action .same-style-2 a span.pro-count {
    font-size: 10px;
    width: 18px;
    height: 18px;
    line-height: 17px;
  }
}

.header-action .same-style-2 a span.red {
  background-color: #ff2f2f;
}

.header-action .same-style-2 a span.black {
  background-color: #000000;
}

.header-action .same-style-2 a span.green {
  background-color: #4dbdaf;
}

.header-action .same-style-2 a span.purple {
  background-color: #6f50a7;
}

.header-action .same-style-2 a:hover {
  color: #ff2f2f;
}

.header-action .same-style-2.same-style-2-white a {
  color: #ffffff;
}

.header-action .same-style-2.same-style-2-white a:hover {
  color: #ff2f2f;
}

.header-action .same-style-2.same-style-2-white.same-style-2-hover-black a:hover {
  color: #000000;
}

.header-action .same-style-2.header-cart a {
  display: flex;
}

.header-action .same-style-2.header-cart a span.cart-amount {
  color: #df3434;
  font-weight: 600;
  font-size: 14px;
  margin: 8px 0 0 16px;
}

.header-action .same-style-2.header-cart a span.cart-amount.light-yellow {
  color: #ffd74b;
}

.header-action .same-style-2.header-cart a span.cart-amount.white {
  color: #ffffff;
}

.header-action .same-style-2.same-style-2-font-inc a {
  font-size: 24px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-action .same-style-2.same-style-2-font-inc a {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .header-action .same-style-2.same-style-2-font-inc a {
    font-size: 20px;
  }
}

.header-action .same-style-2.same-style-2-font-dec a {
  font-size: 18px;
}

.search-wrap-2 {
  margin-right: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .search-wrap-2 {
    margin: 0 80px 0 0;
  }
}

.search-wrap-2 form {
  position: relative;
}

.search-wrap-2 form input {
  color: #999999;
  padding: 2px 0 2px 30px;
  border: none;
  height: 35px;
  background-color: transparent;
  border-bottom: 1px solid #2d2d2d;
}

.search-wrap-2 form input::-moz-input-placeholder {
  color: #999999;
  opacity: 1;
}

.search-wrap-2 form input::-webkit-input-placeholder {
  color: #999999;
  opacity: 1;
}

.search-wrap-2 form button {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  padding: 0;
  font-size: 16px;
  color: #000000;
  background-color: transparent;
}

.header-login-regi {
  margin-right: 45px;
}

.header-login-regi a {
  color: #000000;
  display: flex;
  align-items: center;
}

.header-login-regi a i {
  font-size: 20px;
  margin-right: 20px;
}

.header-login-regi a:hover {
  color: #ff2f2f;
}

.header-cart-2 a {
  color: #000000;
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 600;
}

.header-cart-2 a i {
  font-size: 22px;
  margin-right: 20px;
}

.header-cart-2 a span {
  position: absolute;
  bottom: -6px;
  font-size: 12px;
  width: 20px;
  height: 20px;
  display: inline-block;
  line-height: 19px;
  color: #fff;
  text-align: center;
  left: 14px;
  border-radius: 50px;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .header-cart-2 a span {
    font-size: 10px;
    width: 18px;
    height: 18px;
    line-height: 17px;
  }
}

.header-cart-2 a span.black {
  background-color: #000000;
}

.header-cart-2 a span.red {
  background-color: #ff2f2f;
}

.header-cart-2 a:hover {
  color: #ff2f2f;
}

.main-wrapper .body-overlay {
  background-color: #232324;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}

.main-wrapper.overlay-active .body-overlay {
  opacity: .7;
  visibility: visible;
  z-index: 9999;
}

.sidebar-cart-active {
  position: fixed;
  top: 0;
  width: 420px;
  padding: 104px 50px 50px;
  min-height: 100vh;
  bottom: 0;
  z-index: 9999;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms ease-in;
  transform: translate(200px, 0);
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
}

.sidebar-cart-active.inside {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active {
    width: 350px;
    padding: 104px 30px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active {
    padding: 60px 15px 50px;
    width: 280px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-close {
  position: absolute;
  top: 37px;
  right: 50px;
  font-size: 36px;
  line-height: 30px;
  color: #6D6D6D;
  transition: all 250ms ease-out;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active .sidebar-cart-all .cart-close {
    font-size: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all .cart-close {
    top: 27px;
    right: 17px;
    font-size: 23px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-close:hover {
  transform: rotate(90deg);
  color: #ff2f2f;
}

.sidebar-cart-active .sidebar-cart-all .cart-content > h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 35px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul {
  margin: 0 -30px 0 0;
  padding: 0 30px 0 0;
  max-height: 310px;
  max-height: calc(100vh - 430px);
  overflow: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content ul {
    max-height: calc(100vh - 365px);
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content ul {
    max-height: calc(100vh - 360px);
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li {
  display: flex;
  margin-bottom: 30px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-img {
  flex: 0 0 70px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-img a img {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title {
  margin-left: 20px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title h4 {
  font-size: 15px;
  margin: 0 0 8px;
  font-weight: 500;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title h4 a {
  color: #000000;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title h4 a:hover {
  color: #ff2f2f;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title span {
  font-size: 15px;
  color: #000000;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete {
  display: flex;
  flex-grow: 100;
  justify-content: flex-end;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete a {
  display: block;
  font-size: 16px;
  height: 20px;
  line-height: 17px;
  overflow: hidden;
  width: 20px;
  text-align: right;
  color: #000000;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete a:hover {
  color: #ff2f2f;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total {
  display: block;
  width: 100%;
  margin: 16px 0 26px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 {
  font-size: 16px;
  margin: 0;
  font-weight: 600;
  color: #000000;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 span {
  font-size: 15px;
  color: #000000;
  float: right;
  font-weight: 400;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn {
  display: block;
  width: 100%;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a {
  width: 100%;
  display: block;
  margin: 10px 0 0;
  text-align: center;
  padding: 18px 20px 17px;
  background-color: #000000;
  color: #fff;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 1;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a:hover {
  background-color: #ff2f2f;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-checkout-btn a:first-child {
  margin-top: 0;
}

.header-search-1 {
  position: relative;
}

.header-search-1 .search-toggle {
  z-index: 99;
}

.header-search-1 .search-toggle i {
  display: block;
}

.header-search-1 .search-toggle i.s-close {
  font-size: 35px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.header-search-1 .search-toggle .s-close {
  opacity: 0;
}

.header-search-1 .search-toggle.open .s-close {
  opacity: 1;
}

.header-search-1 .search-toggle.open .s-open {
  opacity: 0;
}

.search-wrap-1 {
  background-color: transparent;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
  visibility: hidden;
  width: 340px;
  z-index: -99;
  padding-bottom: 1px;
  margin-right: 10px;
}

.search-wrap-1 form {
  position: relative;
  overflow: hidden;
}

.search-wrap-1 form input {
  background-color: #fff;
  border: 1px solid #E2DCDC;
  color: #000;
  line-height: 30px;
  padding: 2px 60px 2px 20px;
  width: 100%;
}

.search-wrap-1 form input::-moz-input-placeholder {
  color: #000;
  opacity: 1;
}

.search-wrap-1 form input::-webkit-input-placeholder {
  color: #000;
  opacity: 1;
}

.search-wrap-1 form button.button-search {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  border: none;
  background-color: transparent;
  height: 100%;
  border-left: 1px solid #E2DCDC;
  padding: 0 15px;
  color: #000000;
}

.search-wrap-1 form button.button-search:hover {
  color: #ff2f2f;
}

.search-wrap-1.open {
  opacity: 1;
  right: 100%;
  visibility: visible;
  z-index: 99;
}

.logo a {
  display: block;
}

.logo a img {
  max-width: 100%;
}

.social-offer-wrap {
  display: flex;
}

.header-offer-wrap-2 {
  margin: 0 0 0 45px;
}

.header-offer-wrap-2.mrg-none {
  margin: 0 0 0 0px;
}

.header-offer-wrap-2 p {
  margin: 0;
}

.header-offer-wrap-2 p span {
  color: #000000;
}

.categori-search-wrap {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;
  border: 1px solid #e1e1e1;
}

.categori-search-wrap .categori-style-1 {
  min-width: 130px;
  margin-right: 20px;
  position: relative;
}

.categori-search-wrap .categori-style-1:before {
  position: absolute;
  content: "";
  right: -14px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 24px;
  background-color: #cccccc;
}

.categori-search-wrap .categori-style-1 .nice-select.nice-select-style-1 {
  border: none;
  background-color: transparent;
  border-radius: 0;
  min-width: 137px;
}

.categori-search-wrap .categori-style-1 .nice-select.nice-select-style-1 span {
  font-size: 13px;
  color: #222222;
}

.categori-search-wrap .categori-style-1 .nice-select::after {
  border-bottom: 1px solid #222222;
  border-right: 1px solid #222222;
  height: 7px;
  right: 12px;
  width: 7px;
}

.categori-search-wrap .search-wrap-3 {
  width: 100%;
}

.categori-search-wrap .search-wrap-3 form {
  position: relative;
}

.categori-search-wrap .search-wrap-3 form input {
  font-size: 13px;
  color: #999;
  border: none;
  height: 40px;
  padding: 2px 50px 2px 10px;
  background-color: transparent;
}

.categori-search-wrap .search-wrap-3 form input::-moz-input-placeholder {
  color: #999999;
  opacity: 1;
}

.categori-search-wrap .search-wrap-3 form input::-webkit-input-placeholder {
  color: #999999;
  opacity: 1;
}

.categori-search-wrap .search-wrap-3 form button {
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  padding: 0;
  color: #222;
  font-size: 18px;
}

.categori-search-wrap .search-wrap-3 form button:hover {
  color: #ff2f2f;
}

.categori-search-wrap.categori-search-wrap-modify {
  margin-right: -50px;
}

.categori-search-wrap.categori-search-wrap-modify .categori-style-1 {
  position: absolute;
  right: 67px;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 0;
  z-index: 6;
}

.categori-search-wrap.categori-search-wrap-modify .categori-style-1::before {
  left: -20px;
}

.categori-search-wrap.categori-search-wrap-modify .search-wrap-3 form input {
  padding: 2px 250px 2px 20px;
  height: 50px;
}

.categori-search-wrap.categori-search-wrap-modify .search-wrap-3 form button {
  color: #ffffff;
  height: 100%;
  right: 0;
  padding: 0 20px;
  background-color: #6f50a7;
}

.categori-search-wrap.categori-search-wrap-modify .search-wrap-3 form button:hover {
  color: #ff2f2f;
}

.categori-search-wrap.categori-search-wrap-modify-2 {
  border: none;
  background-color: #ffffff;
}

.categori-search-wrap.categori-search-wrap-modify-2 .categori-style-1 .nice-select .list {
  margin-top: 0;
  border-radius: 0;
}

.categori-search-wrap.categori-search-wrap-modify-2 .search-wrap-3 form button {
  color: #0064e0;
}

.categori-search-wrap.categori-search-wrap-modify-2 .search-wrap-3 form button.orange {
  color: #e44311;
}

.categori-search-wrap.categori-search-wrap-modify-2 .search-wrap-3 form button.orange:hover {
  color: #000000;
}

.categori-search-wrap.categori-search-wrap-modify-2 .search-wrap-3 form button:hover {
  color: #ff2f2f;
}

.categori-search-wrap.categori-search-wrap-modify-3 {
  border: 1px oslid #dadada;
  background-color: #f7f7f7;
}

.categori-search-wrap.categori-search-wrap-modify-3 .categori-style-1 .nice-select .list {
  margin-top: 0;
  border-radius: 0;
}

.categori-search-wrap.categori-search-wrap-modify-3 .search-wrap-3 form input {
  padding: 2px 75px 2px 10px;
}

.categori-search-wrap.categori-search-wrap-modify-3 .search-wrap-3 form button {
  color: #ffffff;
  height: 100%;
  right: 0;
  min-height: 44px;
  padding: 0 20px;
  background-color: #6aae27;
}

.categori-search-wrap.categori-search-wrap-modify-3 .search-wrap-3 form button.blue {
  background-color: #0064e0;
}

.categori-search-wrap.categori-search-wrap-modify-3 .search-wrap-3 form button:hover {
  color: #ff2f2f;
}

.main-categori-wrap {
  position: relative;
}

.main-categori-wrap > a {
  color: #4dbdaf;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .25px;
  display: block;
  width: 100%;
  line-height: 1;
  padding: 0 14px 0 0;
}

.main-categori-wrap > a i {
  font-size: 22px;
  margin-right: 18px;
  position: relative;
  top: 4px;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-categori-wrap > a i {
    margin-right: 12px;
  }
}

.main-categori-wrap > a i.icon-right {
  float: right;
  font-size: 12px;
  margin-right: 0;
  top: 11px;
}

.main-categori-wrap > a.purple {
  color: #6f50a7;
}

.main-categori-wrap > a.green {
  color: #6aae27;
}

.main-categori-wrap > a.blue {
  color: #0064e0;
}

.main-categori-wrap.main-categori-wrap-modify > a {
  color: #ffffff;
  padding: 9px 20px 18px 20px;
  background-color: #6f50a7;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-categori-wrap.main-categori-wrap-modify > a {
    padding: 9px 12px 18px 12px;
  }
}

.main-categori-wrap.main-categori-wrap-modify-2 > a {
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  min-height: 48px;
  align-items: center;
  padding: 2px 20px 2px 20px;
  background-color: #468707;
}

.main-categori-wrap.main-categori-wrap-modify-2 > a.categori-blue {
  background-color: #03479b;
}

.main-categori-wrap.main-categori-wrap-modify-2 > a i.icon-right {
  top: 1px;
}

.hotline {
  text-align: right;
}

.hotline p {
  font-size: 14px;
  font-weight: 600;
  color: #222222;
}

.hotline p i {
  font-size: 18px;
  color: #000000;
  margin-right: 13px;
}

.hotline p span {
  font-size: 13px;
  color: #666666;
  font-weight: 400;
}

.header-middle-padding-1 {
  padding: 34px 0 0;
}

.header-middle-padding-2 {
  padding: 30px 0 30px;
}

.category-menu {
  border: 1px solid #e1e1e1;
  width: 270px;
  position: absolute;
  top: 220%;
  left: 0;
  z-index: 5;
  background-color: #fff;
  padding: 9px 0 3px;
}

.category-menu.categori-not-visible {
  display: none;
  top: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-menu {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (max-width: 767px) {
  .category-menu {
    left: 50%;
    transform: translateX(-50%);
  }
}

.category-menu nav > ul > li {
  padding: 12px 0;
  position: relative;
  border-bottom: 1px solid #e1e1e1;
}

.category-menu nav > ul > li:last-child {
  border-bottom: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-menu nav > ul > li {
    padding: 10px 0;
  }
}

.category-menu nav > ul > li > a {
  color: #000000;
  font-weight: 600;
  padding: 0 20px;
  display: block;
}

.category-menu nav > ul > li > a span {
  float: right;
  font-size: 10px;
  position: relative;
  top: 6px;
  color: #000000;
}

.category-menu nav > ul > li .category-menu-dropdown {
  position: absolute;
  left: 100%;
  top: 0px;
  width: 570px;
  background-color: #fff;
  border-radius: 5px;
  padding: 30px 52px 40px 30px;
  margin-left: 1px;
  transform: rotateX(90deg);
  transform-origin: center top 0;
  transition: all 0.5s ease 0s;
  visibility: hidden;
  z-index: 99;
  box-shadow: 0 0 30px 0.4px rgba(0, 0, 0, 0.1);
}

.category-menu nav > ul > li .category-menu-dropdown .single-category-menu {
  display: inline-block;
  width: 50%;
  float: left;
}

.category-menu nav > ul > li .category-menu-dropdown .single-category-menu.ct-menu-mrg-bottom {
  margin-bottom: 74px;
}

.category-menu nav > ul > li .category-menu-dropdown .single-category-menu.ct-menu-mrg-left {
  padding-left: 38px;
}

.category-menu nav > ul > li .category-menu-dropdown .single-category-menu.category-menu-border {
  position: relative;
}

.category-menu nav > ul > li .category-menu-dropdown .single-category-menu.category-menu-border:before {
  position: absolute;
  content: "";
  bottom: -36px;
  left: 0;
  background-color: #d6d7da;
  height: 1px;
  width: 180px;
}

.category-menu nav > ul > li .category-menu-dropdown .single-category-menu h4 {
  color: #000000;
  font-weight: 600;
  font-size: 14px;
  margin: 0 0 18px;
  letter-spacing: .2px;
  text-transform: uppercase;
}

.category-menu nav > ul > li .category-menu-dropdown .single-category-menu ul li {
  padding: 0 0 9px;
}

.category-menu nav > ul > li .category-menu-dropdown .single-category-menu ul li:last-child {
  padding: 0 0 0px;
}

.category-menu nav > ul > li .category-menu-dropdown .single-category-menu ul li a {
  color: #333;
}

.category-menu nav > ul > li .category-menu-dropdown .single-category-menu ul li a:hover {
  color: #4dbdaf;
}

.category-menu nav > ul > li .category-menu-dropdown .single-category-menu > a img {
  width: 100%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .category-menu nav > ul > li .category-menu-dropdown.ct-menu-res-height-1 {
    height: 280px;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .category-menu nav > ul > li .category-menu-dropdown.ct-menu-res-height-1 {
    height: 280px;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-menu nav > ul > li .category-menu-dropdown.ct-menu-res-height-1 {
    height: 280px;
    overflow-y: auto;
  }
}

.category-menu nav > ul > li:hover > a, .category-menu nav > ul > li:hover > a span {
  color: #4dbdaf;
}

.category-menu nav > ul > li:hover .category-menu-dropdown {
  transform: rotateX(0deg);
  visibility: visible;
}

.category-menu-2 {
  border: 1px solid #468707;
  width: 270px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  background-color: #fff;
  padding: 5px 17px 3px;
}

.category-menu-2.categori-not-visible-2 {
  display: none;
  top: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-menu-2 {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (max-width: 767px) {
  .category-menu-2 {
    left: 50%;
    transform: translateX(-50%);
  }
}

.category-menu-2 nav > ul > li {
  padding: 12px 0;
  position: relative;
  border-bottom: 1px solid #e1e1e1;
}

.category-menu-2 nav > ul > li:last-child {
  border-bottom: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-menu-2 nav > ul > li {
    padding: 10px 0;
  }
}

.category-menu-2 nav > ul > li > a {
  color: #000000;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.category-menu-2 nav > ul > li > a i {
  font-size: 18px;
  margin-right: 14px;
}

.category-menu-2 nav > ul > li:hover > a, .category-menu-2 nav > ul > li:hover > a span {
  color: #6aae27;
}

.category-menu-2 nav > ul > li:hover .category-menu-dropdown {
  transform: rotateX(0deg);
  visibility: visible;
}

.category-menu-2.category-menu-2-blue {
  border: 1px solid #03479b;
}

.category-menu-2.category-menu-2-blue nav > ul > li:hover > a, .category-menu-2.category-menu-2-blue nav > ul > li:hover > a span {
  color: #0064e0;
}

.header-quick-contect ul {
  display: flex;
  flex-wrap: wrap;
}

.header-quick-contect ul li {
  margin-right: 30px;
  color: #ffffff;
}

.header-quick-contect ul li:last-child {
  margin-right: 0;
}

.header-quick-contect ul li i {
  margin-right: 10px;
}

.header-offer-wrap-3 p {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: .25px;
  margin: 0;
  color: #ffffff;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-offer-wrap-3 p {
    font-size: 14px;
  }
}

.header-offer-wrap-3 p a {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: .5px;
  text-decoration: underline;
  color: #fff;
  font-weight: 400;
  margin: 0 0 0 14px;
  padding: 0 0 2px;
  display: inline-block;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-offer-wrap-3 p a {
    margin: 0 0 0 5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-offer-wrap-3 p a {
    margin: 0 0 0 5px;
  }
}

.header-offer-wrap-3 p a:hover {
  color: #ff2f2f;
}

.header-offer-wrap-3 p.black {
  color: #000000;
}

.header-offer-wrap-3 p.black a {
  color: #000000;
}

.social-hm4-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 32px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .social-hm4-wrap {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .social-hm4-wrap {
    margin-right: 8px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .social-hm4-wrap {
    margin-right: 8px;
  }
}

.social-hm4-wrap span {
  color: #ffffff;
  display: inline-block;
  line-height: 1;
  margin: -6px 23px 0 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .social-hm4-wrap span {
    margin: -6px 15px 0 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .social-hm4-wrap span {
    margin: -6px 12px 0 0;
  }
}

.hm4-currency-language-wrap.same-style-wrap .same-style > a {
  background-color: transparent;
  color: #fff;
  padding: 0 14px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hm4-currency-language-wrap.same-style-wrap .same-style > a {
    padding: 0 8px;
  }
}

.hm4-currency-language-wrap.same-style-wrap .same-style:hover > a {
  background-color: #404040;
}

.header-top-ptb-4 {
  padding: 36px 0 36px;
}

.header-offer-wrap-4 p {
  color: #ffffff;
}

.header-offer-wrap-4 p span.uppercase {
  text-transform: uppercase;
  font-weight: 600;
}

.header-offer-wrap-4 p span.amount {
  color: #fc302f;
  font-weight: 600;
}

.header-offer-wrap-4 p a {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: .5px;
  text-decoration: underline;
  color: #fff;
  font-weight: 400;
  margin: 0 0 0 12px;
  display: inline-block;
}

.header-offer-wrap-4 p a:hover {
  text-decoration: none;
}

.header-offer-wrap-4 p.black {
  color: #000000;
}

.header-offer-wrap-4 p.black a {
  color: #000000;
}

.header-top-ptb-5 {
  padding: 13px 0 18px;
}

.header-offer-wrap-5 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 20px 11px;
  border: 1px solid #6f50a7;
  margin-left: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-offer-wrap-5 {
    padding: 13px 10px 11px;
  }
}

.header-offer-wrap-5 h3 {
  font-size: 28px;
  font-weight: 800;
  color: #6f50a7;
  margin: 0 12px 0 0;
  text-transform: uppercase;
  letter-spacing: -.35px;
  line-height: 22px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-offer-wrap-5 h3 {
    font-size: 20px;
  }
}

.header-offer-wrap-5 h4 {
  font-size: 12px;
  font-weight: 800;
  color: #4dbdaf;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: -.25px;
  line-height: 12px;
}

.header-bottom-ptb {
  padding: 0 0 15px;
}

.hotline-2-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.hotline-2-wrap .hotline-2-icon {
  margin-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hotline-2-wrap .hotline-2-icon {
    margin-right: 10px;
  }
}

.hotline-2-wrap .hotline-2-icon i {
  font-size: 24px;
  color: #6aae27;
}

.hotline-2-wrap .hotline-2-icon i.blue {
  color: #0064e0;
}

.hotline-2-wrap .hotline-2-content span {
  font-size: 12px;
  color: #666666;
  display: block;
  line-height: 1;
}

.hotline-2-wrap .hotline-2-content h5 {
  font-size: 18px;
  color: #222222;
  font-weight: 600;
  margin: 4px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hotline-2-wrap .hotline-2-content h5 {
    font-size: 16px;
  }
}

.main-wrapper .body-overlay-1 {
  background: rgba(35, 35, 36, 0.7) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}

.main-wrapper.overlay-active-1 .body-overlay-1 {
  opacity: 1;
  visibility: visible;
}

.mobile-header-active {
  position: fixed;
  top: 0;
  width: 350px;
  min-height: 100vh;
  bottom: 0;
  z-index: 111;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 400ms ease-out;
  transform: translate(200px, 0);
  background-color: #ffffff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
  z-index: 9999;
}

@media only screen and (max-width: 767px) {
  .mobile-header-active {
    width: 290px;
  }
}

.mobile-header-active.sidebar-visible {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

.mobile-header-active .clickalbe-sidebar-wrap {
  padding: 80px 30px 20px;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .mobile-header-active .clickalbe-sidebar-wrap {
    padding: 80px 20px 20px;
  }
}

.mobile-header-active .clickalbe-sidebar-wrap .sidebar-close {
  position: absolute;
  top: 20px;
  left: 30px;
  font-size: 30px;
  line-height: 30px;
  transition: all 250ms ease-out;
  color: #000000;
}

@media only screen and (max-width: 767px) {
  .mobile-header-active .clickalbe-sidebar-wrap .sidebar-close {
    left: 20px;
  }
}

.mobile-header-active .clickalbe-sidebar-wrap .sidebar-close:hover {
  transform: rotate(90deg);
  color: #ff2f2f;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding-right: 10px;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-header-padding-border-1 {
  border-bottom: 1px solid #ddd;
  margin-bottom: 17px;
  padding-bottom: 30px;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-header-padding-border-2 {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding-bottom: 17px;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-header-padding-border-3 {
  border-bottom: 1px solid #ddd;
  margin-bottom: 24px;
  padding-bottom: 30px;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-header-padding-border-4 {
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
  padding-bottom: 24px;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-search form {
  position: relative;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-search form input {
  background-color: #f6f6f6;
  border: none;
  border-radius: 0px;
  height: 50px;
  padding: 0 60px 0 15px;
  width: 100%;
  font-size: 14px;
  color: #181818;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-search form button {
  background-color: transparent;
  border-color: #ddd;
  border-image: none;
  border-radius: 5px 0 0 5px;
  border-style: none none none solid;
  border-width: medium medium medium 1px;
  color: #000000;
  font-size: 18px;
  height: 100%;
  padding: 0 15px 0 14px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-search form button {
    font-size: 16px;
  }
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-search form button:hover {
  color: #ff2f2f;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-search form button i {
  margin-top: 5px;
  display: inline-block;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-menu-wrap nav {
  height: 100%;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li {
  display: block;
  position: relative;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.menu-item-has-children .menu-expand {
  line-height: 50;
  top: -5px;
  left: 95%;
  width: 30px;
  position: absolute;
  height: 50px;
  text-align: center;
  cursor: pointer;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.menu-item-has-children .menu-expand i {
  display: block;
  position: relative;
  width: 10px;
  margin-top: 25px;
  border-bottom: 1px solid #000000;
  transition: all 250ms ease-out;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.menu-item-has-children .menu-expand i::before {
  top: 0;
  width: 100%;
  content: "";
  display: block;
  position: absolute;
  transform: rotate(90deg);
  border-bottom: 1px solid #000000;
  transition: 0.4s;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li.menu-item-has-children.active > .menu-expand i::before {
  transform: rotate(0);
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li a {
  font-size: 16px;
  text-transform: capitalize;
  line-height: 18px;
  position: relative;
  display: inline-block;
  padding: 10px 0;
  color: #000000;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li a i {
  margin-right: 5px;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li a {
  padding: 10px 15px 5px;
  font-size: 14px;
  color: #333;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li a:hover {
  color: #ff2f2f;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li ul li ul li a {
  padding: 10px 30px 5px;
  font-size: 14px;
  color: #333;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li:hover > a {
  color: #ff2f2f;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li:hover.menu-item-has-children > .menu-expand > i {
  border-bottom: 1px solid #ff2f2f;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-menu-wrap nav .mobile-menu li:hover.menu-item-has-children > .menu-expand > i::before {
  border-bottom: 1px solid #ff2f2f;
}

@media only screen and (max-width: 767px) {
  .mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-header-info-wrap {
    display: block;
  }
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info {
  position: relative;
  margin-bottom: 13px;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info:last-child {
  margin-bottom: 0;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info a {
  font-size: 15px;
  display: block;
  color: #000000;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info a span {
  float: right;
  font-size: 10px;
  position: relative;
  top: 8px;
  line-height: 1;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown {
  margin-top: 5px;
  display: none;
  background-color: #fff;
  box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  padding: 22px 30px 26px;
  width: 100%;
  z-index: 11;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li {
  padding-bottom: 10px;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li:last-child {
  padding-bottom: 0px;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li a {
  font-size: 14px;
  color: #000000;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info .lang-curr-dropdown ul li a:hover {
  color: #ff2f2f;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-header-info-wrap .single-mobile-header-info:hover > a {
  color: #ff2f2f;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-contact-info ul li {
  color: #000000;
  display: flex;
  margin: 0 0 14px;
  font-size: 15px;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-contact-info ul li:last-child {
  margin: 0 0 0px;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-contact-info ul li i {
  font-size: 14px;
  margin-right: 12px;
  position: relative;
  top: 4px;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-social-icon a {
  display: inline-block;
  width: 33px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  border-radius: 100%;
  color: #ffffff;
  font-size: 15px;
  margin-right: 10px;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-social-icon a:last-child {
  margin-right: 0;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-social-icon a.facebook {
  background-color: #3b5999;
  border: 1px solid #3b5999;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-social-icon a.facebook:hover {
  color: #3b5999;
  background-color: transparent;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-social-icon a.twitter {
  background-color: #55acee;
  border: 1px solid #55acee;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-social-icon a.twitter:hover {
  color: #55acee;
  background-color: transparent;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-social-icon a.pinterest {
  background-color: #bd081c;
  border: 1px solid #bd081c;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-social-icon a.pinterest:hover {
  color: #bd081c;
  background-color: transparent;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-social-icon a.instagram {
  background-color: #e4405f;
  border: 1px solid #e4405f;
}

.mobile-header-active .clickalbe-sidebar-wrap .mobile-header-content-area .mobile-social-icon a.instagram:hover {
  color: #e4405f;
  background-color: transparent;
}

.categori-hide-2 {
  display: none;
  padding-top: 20px;
}

/*-------- 4. Slider style ---------*/
.slider-height-1 {
  height: 585px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-1 {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-1 {
    height: 440px;
  }
}

.slider-height-2 {
  height: 710px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-2 {
    height: 600px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-2 {
    height: 600px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-2 {
    height: 550px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-2 {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-2 {
    height: 400px;
  }
}

.slider-height-3 {
  height: 350px;
}

@media only screen and (max-width: 767px) {
  .slider-height-3 {
    height: auto;
    padding: 50px 0;
  }
}

.slider-height-4 {
  height: 520px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-4 {
    height: 475px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-4 {
    height: 475px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-height-4 {
    height: 450px;
  }
}

.hero-slider-pt-1 {
  padding-top: 150px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-pt-1 {
    padding-top: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-pt-1 {
    padding-top: 110px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-pt-1 {
    padding-top: 90px;
  }
}

.hero-slider-content-1-pt-1 {
  padding-top: 125px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-1-pt-1 {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-1-pt-1 {
    padding-top: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-1-pt-1 {
    padding-top: 40px;
  }
}

.hero-slider-content-1 h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 0;
}

.hero-slider-content-1 h4.font-dec {
  font-size: 16px;
}

.hero-slider-content-1 h1 {
  font-size: 72px;
  line-height: 72px;
  font-weight: bold;
  margin: 20px 0 42px;
  font-family: "Heebo", sans-serif;
  letter-spacing: -.25px;
}

.hero-slider-content-1 h1.font-dec {
  font-size: 39px;
  line-height: 48px;
  font-family: "Poppins", sans-serif;
  margin: 16px 0 28px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-1 h1.font-dec {
    font-size: 30px;
    line-height: 40px;
    margin: 6px 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-1 h1.font-dec {
    font-size: 35px;
    line-height: 45px;
    margin: 12px 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-1 h1.font-dec {
    font-size: 28px;
    line-height: 40px;
    margin: 10px 0 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-1 h1.font-dec {
    font-size: 24px;
    line-height: 32px;
    margin: 5px 0 5px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-1 h1 {
    font-size: 55px;
    line-height: 65px;
    margin: 12px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-1 h1 {
    font-size: 45px;
    line-height: 56px;
    margin: 10px 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-1 h1 {
    font-size: 32px;
    line-height: 45px;
    margin: 12px 0 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-1 h1 {
    font-size: 30px;
    line-height: 35px;
    margin: 5px 0 10px;
  }
}

.hero-slider-content-1 p {
  font-size: 16px;
  line-height: 30px;
  margin: 0px 0 80px;
  width: 78%;
}

.hero-slider-content-1 p.width-inc {
  width: 96%;
  margin: 0px 0 36px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-1 p.width-inc {
    width: 100%;
    margin: 0px 0 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-1 p.width-inc {
    margin: 0px 0 16px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-1 p.width-inc {
    margin: 0px 0 16px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-1 p.width-inc {
    width: 100%;
    line-height: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-1 p {
    margin: 0px 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-1 p {
    font-size: 15px;
    margin: 0px 0 30px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-1 p {
    margin: 0px 0 20px;
    width: 98%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-1 p {
    font-size: 15px;
    line-height: 28px;
    margin: 0px 0 15px;
  }
}

.hero-slider-content-1.hero-slider-content-1-padding1 {
  padding: 63px 0 0 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-1.hero-slider-content-1-padding1 {
    padding: 40px 0 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-1.hero-slider-content-1-padding1 {
    padding: 63px 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-1.hero-slider-content-1-padding1 {
    padding: 50px 15px 0 15px;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-1.hero-slider-content-1-padding1 {
    padding: 50px 0px 0 15px;
    text-align: left;
  }
}

.btn-style-1 a {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  background-color: #000000;
  color: #ffffff;
}

.btn-style-1 a:hover {
  background-color: #ff2f2f;
}

@media only screen and (max-width: 767px) {
  .btn-style-1 a {
    font-size: 15px;
    font-weight: 500;
  }
}

.btn-style-1 a.btn-1-padding-1 {
  padding: 11px 26px 15px;
}

@media only screen and (max-width: 767px) {
  .btn-style-1 a.btn-1-padding-1 {
    padding: 8px 20px 12px;
  }
}

.btn-style-1 a.btn-1-bg-purple {
  background-color: #6f50a7;
}

.btn-style-1 a.btn-1-padding-2 {
  padding: 8px 22px 12px;
}

.btn-style-1 a.btn-1-padding-3 {
  padding: 12px 25px 14px;
}

@media only screen and (max-width: 767px) {
  .btn-style-1 a.btn-1-padding-3.banner-btn-res {
    padding: 5px 15px 5px;
    font-size: 13px;
  }
}

.btn-style-1 a.btn-1-padding-4 {
  padding: 8px 20px 13px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn-style-1 a.btn-1-padding-4 {
    padding: 6px 15px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-style-1 a.btn-1-padding-4 {
    font-size: 13px;
    padding: 2px 12px 5px;
  }
}

.btn-style-1 a.bg-white {
  background-color: #ffffff;
  color: #000000;
}

.btn-style-1 a.bg-white:hover {
  background-color: #000000 !important;
  color: #ffffff;
}

.btn-style-1 a.btn-1-green-2 {
  background-color: #6aae27;
}

.btn-style-1 a.btn-1-green-2:hover {
  background-color: #000000;
  color: #ffffff;
}

.btn-style-1 a.btn-1-blue {
  background-color: #0064e0;
}

.btn-style-1 a.btn-1-blue:hover {
  background-color: #000000;
  color: #ffffff;
}

.btn-style-1 a.btn-1-orange {
  background-color: #e44311;
}

.btn-style-1 a.btn-1-orange:hover {
  background-color: #000000;
  color: #ffffff;
}

.btn-style-1 a.btn-1-font-14 {
  font-size: 14px;
}

.hero-slider-img-1 {
  margin-right: -80px;
  margin-left: 80px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-img-1 {
    margin-right: 0px;
    margin-left: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-img-1 {
    margin-right: 0px;
    margin-left: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-img-1 {
    margin-right: 0px;
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-img-1 {
    margin-right: 0px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-img-1 {
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 30px;
  }
}

.hero-slider-img-1 img {
  width: 100%;
}

.hero-slider-active-1 {
  position: relative;
}

.dot-style-1 ul {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 12.7%;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .dot-style-1 ul {
    bottom: 7.7%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .dot-style-1 ul {
    bottom: 10.7%;
  }
}

.dot-style-1 ul li {
  margin: 0 9px;
}

.dot-style-1 ul li button {
  border: none;
  padding: 0;
  font-size: 0px;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: #cccccc;
}

.dot-style-1 ul li.slick-active button {
  background-color: #ff2f2f;
}

.nav-style-1 > span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 7.5%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #000000;
  font-size: 24px;
  background-color: #ffffff;
  cursor: pointer;
  transition: all .3s ease 0s;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .nav-style-1 > span {
    left: 1.5%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nav-style-1 > span {
    left: 1.5%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-1 > span {
    left: 1.5%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-1 > span {
    left: 1.5%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-1 > span {
    left: 1.5%;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
  }
}

.nav-style-1 > span.slider-icon-1-next {
  left: auto;
  right: 7.5%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .nav-style-1 > span.slider-icon-1-next {
    right: 1.5%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nav-style-1 > span.slider-icon-1-next {
    right: 1.5%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-1 > span.slider-icon-1-next {
    right: 1.5%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-1 > span.slider-icon-1-next {
    right: 1.5%;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-1 > span.slider-icon-1-next {
    right: 1.5%;
  }
}

.nav-style-1 > span:hover {
  background-color: #ff2f2f;
  color: #ffffff;
}

.nav-style-1:hover span {
  opacity: 1;
  visibility: visible;
  left: 9.5%;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .nav-style-1:hover span {
    left: 2.5%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nav-style-1:hover span {
    left: 2.5%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-1:hover span {
    left: 2.5%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-1:hover span {
    left: 2.5%;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-1:hover span {
    left: 2.5%;
  }
}

.nav-style-1:hover span.slider-icon-1-next {
  right: 9.5%;
  left: auto;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .nav-style-1:hover span.slider-icon-1-next {
    right: 2.5%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nav-style-1:hover span.slider-icon-1-next {
    right: 2.5%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-1:hover span.slider-icon-1-next {
    right: 2.5%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-1:hover span.slider-icon-1-next {
    right: 2.5%;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-1:hover span.slider-icon-1-next {
    right: 2.5%;
  }
}

.nav-style-1.nav-style-1-modify > span {
  left: 1%;
}

.nav-style-1.nav-style-1-modify > span.slider-icon-1-next {
  right: 1%;
  left: auto;
}

.nav-style-1.nav-style-1-modify:hover span {
  left: 2%;
}

.nav-style-1.nav-style-1-modify:hover span.slider-icon-1-next {
  right: 2%;
  left: auto;
}

.nav-style-1.nav-style-1-modify-2 > span {
  left: 1%;
}

.nav-style-1.nav-style-1-modify-2 > span.slider-icon-1-next {
  right: 1%;
  left: auto;
}

.nav-style-1.nav-style-1-modify-2:hover span {
  left: 0%;
}

.nav-style-1.nav-style-1-modify-2:hover span.slider-icon-1-next {
  right: 0%;
  left: auto;
}

.nav-style-1.nav-style-1-green > span:hover {
  background-color: #4dbdaf;
  color: #ffffff;
}

.nav-style-1.nav-style-1-blue > span:hover {
  background-color: #0064e0;
  color: #ffffff;
}

.nav-style-1.nav-style-1-green-2 > span:hover {
  background-color: #6aae27;
  color: #ffffff;
}

.nav-style-1.nav-style-1-orange > span:hover {
  background-color: #e44311;
  color: #ffffff;
}

.single-animation-wrap.slick-active .slider-animated-1 h4 {
  animation-delay: 1.1s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 h1 {
  animation-delay: 1.4s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 p {
  animation-delay: 1.7s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 h5 {
  animation-delay: 2.0s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 a {
  animation-delay: 2.0s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 .slider-tab-small-img {
  animation-delay: 2.3s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 .slider-tab-big-img-wrap {
  animation-delay: 2.0s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 .btn-style-3 a {
  animation-delay: 2.5s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1.hero-slider-img-1 img {
  animation-delay: 1.7s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1.hm6-hero-slider-img img {
  animation-delay: 1.7s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 .hm9-hero-slider-img img {
  animation-delay: 1.7s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 .hm10-hero-slider-img img {
  animation-delay: 1.7s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 .hero-slider-content-5 h5,
.single-animation-wrap.slick-active .slider-animated-1 .hero-slider-content-6 h5 {
  animation-delay: 1.1s;
  animation-name: fadeInUp;
}

.single-animation-wrap.slick-active .slider-animated-1 .hm7-hero-slider-img img {
  animation-delay: 1.7s;
  animation-name: fadeInUp;
}

.hero-slider-content-2 {
  padding: 0 0 0 170px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-2 {
    padding: 0 0 0 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-2 {
    padding: 0 0 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-2 {
    padding: 0 0 0 15px;
  }
}

.hero-slider-content-2 h1 {
  font-size: 48px;
  line-height: 60px;
  font-weight: 300;
  letter-spacing: -.25px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-2 h1 {
    font-size: 30px;
    line-height: 40px;
  }
}

.hero-slider-content-2 h1 span {
  color: #628787;
  font-weight: bold;
}

.hero-slider-content-2 p {
  width: 67%;
  margin: 15px 0 65px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-2 p {
    margin: 15px 0 35px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-2 p {
    width: 80%;
    color: #000000;
    margin: 15px 0 24px;
  }
}

.hero-slider-content-2.hero-slider-content-hm5 {
  padding: 0 0 0 0px;
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-2.hero-slider-content-hm5 h1 {
    color: #ffffff;
  }
}

.hero-slider-content-2.hero-slider-content-hm5 h1 span {
  color: #000000;
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-2.hero-slider-content-hm5 h1 span {
    color: #ffffff;
  }
}

.hero-slider-content-2.hero-slider-content-hm5 p {
  width: 29%;
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-2.hero-slider-content-hm5 p {
    width: 100%;
    color: #ffffff;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-2.hero-slider-content-hm5 .btn-style-2 a {
    color: #ffffff;
  }
  .hero-slider-content-2.hero-slider-content-hm5 .btn-style-2 a:hover {
    color: #ff2f2f;
  }
}

.btn-style-2 a {
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .25px;
  color: #000000;
}

.btn-style-2 a i {
  margin-left: 10px;
  position: relative;
  top: 1px;
}

.btn-style-2 a:hover {
  color: #ff2f2f;
}

.dot-style-2.dot-style-2-position-1 ul {
  left: 170px;
  bottom: 9%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dot-style-2.dot-style-2-position-1 ul {
    left: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dot-style-2.dot-style-2-position-1 ul {
    left: 30px;
  }
}

.dot-style-2.dot-style-2-position-2 ul {
  left: 50%;
  transform: translateX(-50%);
  bottom: 9%;
}

.dot-style-2.dot-style-2-position-3 ul {
  left: 70px;
  bottom: 60px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .dot-style-2.dot-style-2-position-3 ul {
    left: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .dot-style-2.dot-style-2-position-3 ul {
    left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dot-style-2.dot-style-2-position-3 ul {
    left: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dot-style-2.dot-style-2-position-3 ul {
    left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .dot-style-2.dot-style-2-position-3 ul {
    left: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .dot-style-2.dot-style-2-position-3 ul {
    left: 20px;
  }
}

.dot-style-2.dot-style-2-position-4 ul {
  left: 70px;
  bottom: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dot-style-2.dot-style-2-position-4 ul {
    left: 15px;
    bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dot-style-2.dot-style-2-position-4 ul {
    left: 20px;
    bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .dot-style-2.dot-style-2-position-4 ul {
    left: 15px;
    bottom: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .dot-style-2.dot-style-2-position-4 ul {
    left: 20px;
  }
}

.dot-style-2.dot-style-2-position-5 ul {
  left: 100px;
  bottom: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dot-style-2.dot-style-2-position-5 ul {
    left: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dot-style-2.dot-style-2-position-5 ul {
    left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .dot-style-2.dot-style-2-position-5 ul {
    left: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .dot-style-2.dot-style-2-position-5 ul {
    left: 20px;
  }
}

.dot-style-2.dot-style-2-position-static ul {
  position: static;
}

.dot-style-2.dot-style-2-left-0 ul {
  justify-content: flex-start;
}

.dot-style-2.dot-style-2-mrg-1 ul {
  margin: 27px 0 0;
}

@media only screen and (max-width: 767px) {
  .dot-style-2.dot-style-2-mrg-1 ul {
    margin: 5px 0 0;
  }
}

.dot-style-2.dot-style-2-mrg-2 ul {
  margin: 17px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dot-style-2.dot-style-2-mrg-2 ul {
    margin: 5px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .dot-style-2.dot-style-2-mrg-2 ul {
    margin: -10px 0 0;
  }
}

.dot-style-2.dot-style-2-mrg-3 ul {
  margin: 30px 0 0;
}

.dot-style-2.dot-style-2-mrg-4 ul {
  margin: 50px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dot-style-2.dot-style-2-mrg-4 ul {
    margin: 15px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dot-style-2.dot-style-2-mrg-4 ul {
    margin: 30px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .dot-style-2.dot-style-2-mrg-4 ul {
    margin: 20px 0 0;
  }
}

.dot-style-2 ul {
  position: absolute;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .dot-style-2 ul {
    bottom: 7.7%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .dot-style-2 ul {
    bottom: 10.7%;
  }
}

.dot-style-2 ul li {
  margin: 0 9px 0 0;
}

.dot-style-2 ul li:last-child {
  margin: 0 0px 0 0;
}

.dot-style-2 ul li button {
  border: none;
  padding: 0;
  font-size: 0px;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: #cccccc;
}

.dot-style-2 ul li.slick-active button {
  background-color: #ff2f2f;
}

.dot-style-2.dot-style-2-active-black ul li.slick-active button {
  background-color: #666666;
}

.dot-style-2.dot-style-2-active-purple ul li.slick-active button {
  background-color: #6f50a7;
}

.dot-style-2.dot-style-2-active-green-2 ul li.slick-active button {
  background-color: #6aae27;
}

.slider-area .nav-style-2 > span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) scale(0);
  left: -6.5%;
  text-align: center;
  color: #000000;
  font-size: 36px;
  cursor: pointer;
  transition: all .6s ease 0s;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-area .nav-style-2 > span {
    left: 0%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-area .nav-style-2 > span {
    left: 0%;
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-area .nav-style-2 > span {
    font-size: 25px;
    left: 0%;
  }
}

@media only screen and (max-width: 767px) {
  .slider-area .nav-style-2 > span {
    font-size: 20px;
    left: 0%;
  }
}

.slider-area .nav-style-2 > span.slider-icon-1-next {
  left: auto;
  right: -6.5%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-area .nav-style-2 > span.slider-icon-1-next {
    right: 0%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-area .nav-style-2 > span.slider-icon-1-next {
    right: 0%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-area .nav-style-2 > span.slider-icon-1-next {
    right: 0%;
  }
}

@media only screen and (max-width: 767px) {
  .slider-area .nav-style-2 > span.slider-icon-1-next {
    right: 0%;
  }
}

.slider-area .nav-style-2 > span:hover {
  color: #ff2f2f;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-area .nav-style-2.nav-style-2-modify > span {
    left: -4.5%;
  }
  .slider-area .nav-style-2.nav-style-2-modify > span.slider-icon-1-next {
    left: auto;
    right: -4.5%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-area .nav-style-2.nav-style-2-modify > span {
    left: 0%;
  }
  .slider-area .nav-style-2.nav-style-2-modify > span.slider-icon-1-next {
    left: auto;
    right: 0%;
  }
}

@media only screen and (max-width: 767px) {
  .slider-area .nav-style-2.nav-style-2-modify > span {
    left: 0%;
    color: #ffffff;
  }
  .slider-area .nav-style-2.nav-style-2-modify > span.slider-icon-1-next {
    left: auto;
    right: 0%;
  }
}

.slider-area:hover .nav-style-2 > span {
  opacity: 1;
  visibility: visible;
  transform: translateY(-50%) scale(1);
}

.hero-slider-content-3 {
  margin-left: -30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-3 {
    margin-left: -20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-3 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-3 {
    margin-left: 0px;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-3 {
    margin-left: 0px;
    text-align: left;
  }
}

.hero-slider-content-3 h4 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #4dbdaf;
  margin: 0;
  letter-spacing: .5px;
}

.hero-slider-content-3 h1 {
  font-size: 48px;
  font-weight: 600;
  line-height: 52px;
  margin: 10px 0 24px;
  letter-spacing: -.25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-3 h1 {
    font-size: 35px;
    line-height: 40px;
    margin: 10px 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-3 h1 {
    font-size: 42px;
    line-height: 50px;
    margin: 10px 0 12px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-3 h1 {
    font-size: 30px;
    line-height: 38px;
    margin: 5px 0 10px;
  }
}

.hero-slider-content-3 p {
  width: 60%;
  margin: 0 0 28px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-3 p {
    width: 80%;
    margin: 0 0 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-3 p {
    width: 100%;
    margin: 0 0 18px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-3 p {
    width: 100%;
    margin: 0 0 16px;
  }
}

.hero-slider-content-3 h5 {
  font-size: 16px;
  color: #666666;
  margin: 0 0 0px;
  letter-spacing: .25px;
}

.hero-slider-content-3 h5 span {
  font-weight: 600;
  color: #000000;
  text-transform: uppercase;
}

.hero-slider-content-3 .slider-tab-small-img {
  margin: 20px 0 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-3 .slider-tab-small-img {
    margin: 20px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-3 .slider-tab-small-img {
    margin: 15px 0 25px;
    justify-content: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-3 .slider-tab-small-img {
    justify-content: flex-start;
  }
}

.hero-slider-content-3 .slider-tab-small-img a {
  display: inline-block;
  border: 1px solid transparent;
  margin: 0 10px 0 0;
}

.hero-slider-content-3 .slider-tab-small-img a.active {
  border: 1px solid #cccccc;
}

.hero-slider-content-3 .slider-tab-small-img a:last-child {
  margin: 0 0px 0 0;
}

.hero-slider-content-3 .slider-tab-small-img a img {
  max-width: 100%;
}

.btn-style-3 a {
  display: inline-block;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  background-color: #4dbdaf;
  padding: 12px 27px 11px;
}

@media only screen and (max-width: 767px) {
  .btn-style-3 a {
    padding: 10px 20px 9px;
  }
}

.btn-style-3 a i {
  font-size: 14px;
  margin-left: 5px;
}

.btn-style-3 a:hover {
  background-color: #000000;
}

@media only screen and (max-width: 767px) {
  .slider-tab-big-img-wrap .slider-tab-big-img {
    text-align: center;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-tab-big-img-wrap .slider-tab-big-img {
    text-align: left;
    margin-top: 0px;
  }
}

.slider-tab-big-img-wrap .slider-tab-big-img img {
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .slider-tab-big-img-wrap .slider-tab-big-img img {
    max-width: 130px;
    display: inline-block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-tab-big-img-wrap .slider-tab-big-img img {
    max-width: 100%;
    display: block;
  }
}

.hero-slider-content-4 {
  position: relative;
  z-index: 9;
}

.hero-slider-content-4 h4 {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  display: block;
  text-transform: uppercase;
}

.hero-slider-content-4 h1 {
  font-size: 60px;
  line-height: 72px;
  letter-spacing: -.25px;
  text-transform: uppercase;
  margin: 24px 0 30px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-content-4 h1 {
    font-size: 50px;
    line-height: 60px;
    margin: 14px 0 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-4 h1 {
    font-size: 50px;
    line-height: 60px;
    margin: 14px 0 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-4 h1 {
    font-size: 50px;
    line-height: 60px;
    margin: 14px 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-4 h1 {
    font-size: 40px;
    line-height: 50px;
    margin: 14px 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-4 h1 {
    font-size: 25px;
    line-height: 32px;
    margin: 10px 0 10px;
  }
}

.hero-slider-content-4 p {
  font-size: 16px;
  line-height: 26px;
  margin: 0 0 43px;
  width: 29%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-4 p {
    width: 46%;
    margin: 0 0 23px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-4 p {
    width: 37%;
    margin: 0 0 23px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-4 p {
    width: 100%;
    font-size: 15px;
    margin: 0 0 20px;
    color: #000000;
  }
}

.hm6-hero-slider-img {
  margin-left: 33px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hm6-hero-slider-img {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .hm6-hero-slider-img {
    text-align: center;
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hm6-hero-slider-img {
    margin-left: 0px;
    margin-top: 0px;
    margin-right: 0px;
  }
}

.hm6-hero-slider-img img {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .hm6-hero-slider-img img {
    display: inline-block;
  }
}

.hero-slider-content-5 {
  padding-left: 170px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hero-slider-content-5 {
    padding-left: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hero-slider-content-5 {
    padding-left: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-5 {
    padding-left: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-5 {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-5 {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-5 {
    text-align: left;
    margin-bottom: 0;
    padding-left: 30px;
    padding-right: 0px;
  }
}

.hero-slider-content-5 h5 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.hero-slider-content-5 h1 {
  font-size: 42px;
  line-height: 48px;
  margin: 18px 0 28px;
  letter-spacing: -.25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-5 h1 {
    font-size: 30px;
    line-height: 42px;
    margin: 10px 0 18px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-5 h1 {
    font-size: 30px;
    line-height: 42px;
    margin: 6px 0 9px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-5 h1 {
    font-size: 27px;
    line-height: 40px;
  }
}

.hero-slider-content-5 p {
  width: 61%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-5 p {
    width: 78%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-5 p {
    width: 100%;
  }
}

.hm7-hero-slider-img {
  margin: 0 165px 0 0;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hm7-hero-slider-img {
    margin: 0 100px 0 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hm7-hero-slider-img {
    margin: 0 100px 0 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hm7-hero-slider-img {
    margin: 0 70px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hm7-hero-slider-img {
    margin: 0 50px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .hm7-hero-slider-img {
    text-align: center;
    margin: 0 15px 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hm7-hero-slider-img {
    text-align: center;
    margin: 0 30px 0 0;
  }
}

.hm7-hero-slider-img img {
  width: 100%;
}

.hero-slider-content-6 {
  padding-left: 100px;
  position: relative;
  z-index: 9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-6 {
    padding-left: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-6 {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-6 {
    padding-left: 15px;
  }
}

.hero-slider-content-6 h5 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.hero-slider-content-6 h1 {
  font-size: 42px;
  line-height: 48px;
  font-weight: 600;
  letter-spacing: -.25px;
  text-transform: uppercase;
  margin: 15px 0 28px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-6 h1 {
    font-size: 30px;
    line-height: 40px;
    margin: 15px 0 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-6 h1 {
    font-size: 30px;
    line-height: 40px;
    margin: 15px 0 12px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-6 h1 {
    font-size: 34px;
    line-height: 40px;
    margin: 15px 0 12px;
  }
}

.hero-slider-content-6 p {
  width: 42%;
  margin: 0 0 33px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-6 p {
    width: 46%;
    margin: 0 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-6 p {
    width: 46%;
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-6 p {
    color: #000000;
    width: 100%;
    margin: 0 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-6 p {
    width: 50%;
  }
}

.hero-slider-content-6.slider-content-hm9 {
  padding-left: 180px;
  padding-top: 67px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-6.slider-content-hm9 {
    padding-left: 70px;
    padding-top: 57px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-6.slider-content-hm9 {
    padding-left: 0px;
    padding-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-6.slider-content-hm9 {
    padding-left: 0px;
    padding-top: 50px;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-6.slider-content-hm9 {
    text-align: left;
    padding-top: 0px;
  }
}

.hero-slider-content-6.slider-content-hm9 h1 {
  margin: 12px 0 21px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-6.slider-content-hm9 h1 {
    margin: 12px 0 11px;
    line-height: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-6.slider-content-hm9 h1 {
    margin: 7px 0 6px;
    line-height: 28px;
    font-size: 27px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-6.slider-content-hm9 h1 {
    margin: 12px 0 10px;
    line-height: 30px;
    font-size: 27px;
  }
}

.hero-slider-content-6.slider-content-hm9 p {
  width: 100%;
  margin: 0 0 37px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-6.slider-content-hm9 p {
    margin: 0 0 17px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-6.slider-content-hm9 p {
    margin: 0 0 9px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-6.slider-content-hm9 p {
    margin: 0 0 15px;
  }
}

.hero-slider-content-6.slider-content-hm9.slider-content-hm10 {
  padding-left: 150px;
  padding-top: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-content-6.slider-content-hm9.slider-content-hm10 {
    padding-left: 70px;
    padding-top: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-content-6.slider-content-hm9.slider-content-hm10 {
    padding-left: 0px;
    padding-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slider-content-6.slider-content-hm9.slider-content-hm10 {
    padding-left: 0px;
    padding-top: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-slider-content-6.slider-content-hm9.slider-content-hm10 {
    text-align: center;
  }
  .hero-slider-content-6.slider-content-hm9.slider-content-hm10 p {
    width: 67%;
    margin: 0 auto 15px;
  }
}

.hm9-hero-slider-img {
  margin-left: -40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hm9-hero-slider-img {
    margin-left: -20px;
  }
}

@media only screen and (max-width: 767px) {
  .hm9-hero-slider-img {
    margin-left: 0px;
    padding-top: 30px;
    padding-bottom: 50px;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hm9-hero-slider-img {
    padding-bottom: 0px;
  }
}

.hm9-hero-slider-img img {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .hm9-hero-slider-img img {
    display: inline-block;
  }
}

.single-hero-slider-hm10 {
  padding-top: 55px;
}

.hm10-hero-slider-img {
  margin-left: -170px;
  margin-right: 50px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hm10-hero-slider-img {
    margin-left: -120px;
    margin-right: 0px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .hm10-hero-slider-img {
    margin-left: -120px;
    margin-right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hm10-hero-slider-img {
    margin-left: -120px;
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hm10-hero-slider-img {
    margin-left: -50px;
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .hm10-hero-slider-img {
    margin-left: 0px;
    padding-top: 30px;
    text-align: center;
  }
}

.hm10-hero-slider-img img {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .hm10-hero-slider-img img {
    display: inline-block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-area.pt-30 {
    padding-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .hm2-slider-bg {
    background-position: 80%;
  }
  .single-hero-slider-hm3 {
    padding: 50px 0 0;
  }
  .slider-area.pt-30 {
    padding-top: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hm2-slider-bg {
    background-position: 75%;
  }
  .single-hero-slider-hm3 {
    padding: 0px 0 0;
  }
  .single-hero-slider-hm9 {
    padding: 50px 0 55px;
  }
}

/*-------- 5. Service style ---------*/
.service-wrap {
  background-color: #ffffff;
  margin-top: -100px;
  position: relative;
  z-index: 1;
  padding: 51px 0 25px;
}

.service-wrap.service-wrap-hm9 {
  margin-top: -70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-wrap.service-wrap-hm9 {
    margin-top: -50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-wrap.service-wrap-hm9 {
    margin-top: -30px;
  }
}

@media only screen and (max-width: 767px) {
  .service-wrap.service-wrap-hm9 {
    margin-top: 0px;
    border-bottom: 1px solid #e1e1e1;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-wrap {
    margin-top: -50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-wrap {
    margin-top: -50px;
  }
}

@media only screen and (max-width: 767px) {
  .service-wrap {
    margin-top: -30px;
    padding: 41px 0 15px;
  }
}

.service-wrap-border {
  border: 1px solid #dfdfdf;
}

.service-wrap-padding {
  padding: 32px 0 3px;
}

.single-service-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-service-wrap .service-icon {
  margin-right: 23px;
  line-height: 1;
}

.single-service-wrap .service-icon i {
  font-size: 24px;
  color: #ff2f2f;
  display: inline-block;
  transition: all .3s ease 0s;
}

.single-service-wrap .service-icon.service-icon-black i {
  color: #000000;
}

.single-service-wrap .service-icon.service-icon-blue i {
  color: #0064e0;
}

.single-service-wrap .service-content h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 8px;
  color: #222222;
}

.single-service-wrap .service-content span {
  display: block;
  line-height: 1;
  color: #999999;
}

.single-service-wrap:hover .service-icon i {
  animation: 500ms ease-in-out 0s normal none 1 running flipOutY;
}

.service-border-1 {
  position: relative;
}

.service-border-1:before {
  position: absolute;
  content: "";
  right: 0;
  top: -6%;
  width: 1px;
  height: 63px;
  background-color: #e0e0e0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-border-1.service-border-1-none-md:before {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .service-border-1:before {
    display: none;
  }
  .service-border-1.service-border-1-none-xs:before {
    display: none;
  }
}

.service-wrap-padding-2 {
  padding: 37px 35px 7px 35px;
}

.service-wrap-padding-3 {
  padding: 32px 0 3px;
}

@media only screen and (max-width: 767px) {
  .service-wrap-padding-3 {
    padding: 32px 20px 3px;
  }
}

.single-service-wrap-2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .single-service-wrap-2 {
    justify-content: flex-start;
  }
}

.single-service-wrap-2 .service-icon-2 {
  margin-right: 25px;
}

.single-service-wrap-2 .service-icon-2 i {
  font-size: 34px;
  color: #999999;
  transition: all .3s ease 0s;
}

.single-service-wrap-2 .service-content-2 h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 5px;
}

.single-service-wrap-2 .service-content-2 p {
  margin: 0;
  letter-spacing: .25px;
}

.single-service-wrap-2.single-service-wrap-2-modify {
  justify-content: flex-start;
}

.single-service-wrap-2.single-service-wrap-2-modify .service-icon-2 i {
  font-size: 30px;
}

.single-service-wrap-2.single-service-wrap-2-modify .service-content-2 h3 {
  font-size: 16px;
}

.single-service-wrap-2:hover .service-icon-2 i {
  color: #4dbdaf;
}

.single-service-wrap-2:hover .service-icon-2.icon-purple i {
  color: #6f50a7;
}

.single-service-wrap-2:hover .service-icon-2.icon-green-2 i {
  color: #6aae27;
}

.single-service-wrap-2:hover .service-icon-2.icon-red i {
  color: #ff2f2f;
}

@media only screen and (max-width: 767px) {
  .service-area.pt-45 {
    padding-top: 25px;
  }
  .service-area .pb-50 {
    padding-bottom: 30px;
  }
}

/*-------- 6. About us style ---------*/
.about-us-content h3 {
  font-size: 24px;
  font-weight: bold;
  font-family: "Heebo", sans-serif;
  margin: 0;
  text-transform: uppercase;
}

.about-us-content p {
  font-size: 20px;
  font-family: "Jost", sans-serif;
  line-height: 36px;
  margin: 23px 0 51px;
  width: 93%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content p {
    font-size: 18px;
    line-height: 34px;
    margin: 15px 0 40px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content p {
    font-size: 18px;
    line-height: 34px;
    margin: 10px 0 30px;
    width: 100%;
  }
}

.about-us-content .signature h2 {
  font-size: 24px;
  font-family: "Signerica Fat";
}

@media only screen and (max-width: 767px) {
  .about-us-content {
    margin-top: 20px;
  }
}

.about-content-pb {
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-content-pb {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .about-content-pb {
    padding-bottom: 60px;
  }
}

.about-us-content-2 .about-us-content-2-title {
  margin: 0 0 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content-2 .about-us-content-2-title {
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content-2 .about-us-content-2-title {
    margin: 0 0 15px;
  }
}

.about-us-content-2 .about-us-content-2-title h4 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -.25px;
  margin: 0;
}

.about-us-content-2 p {
  line-height: 30px;
  margin: 0 0 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content-2 p {
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-content-2 p {
    margin: 0 0 15px;
  }
}

.about-us-content-2 p:last-child {
  margin-bottom: 0;
}

.about-us-content-3 h3 {
  font-size: 24px;
  text-transform: uppercase;
  margin: 0 0 10px;
}

@media only screen and (max-width: 767px) {
  .about-us-content-3 h3 {
    font-size: 17px;
    line-height: 24px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-us-content-3 h3 {
    font-size: 20px;
    line-height: 26px;
  }
}

.about-us-content-3 h3 span {
  font-weight: bold;
  color: #0064e0;
}

.about-us-content-3 p {
  font-size: 16px;
  color: #666666;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .about-us-content-3 p {
    font-size: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-us-content-3 p {
    font-size: 16px;
    width: 78%;
    margin: 0 auto;
  }
}

.about-us-content-4 .about-us-content-4-title {
  margin: 0 0 26px;
}

@media only screen and (max-width: 767px) {
  .about-us-content-4 .about-us-content-4-title {
    margin: 0 0 15px;
  }
}

.about-us-content-4 .about-us-content-4-title h4 {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -.25px;
  margin: 0;
}

.about-us-content-4 p {
  line-height: 28px;
  margin: 0 0 21px;
}

@media only screen and (max-width: 767px) {
  .about-us-content-4 p {
    margin: 0 0 15px;
  }
}

.about-us-content-4 p:last-child {
  margin-bottom: 0;
}

.team-wrapper .team-img {
  overflow: hidden;
  position: relative;
}

.team-wrapper .team-img img {
  width: 100%;
}

.team-wrapper .team-img .team-action {
  background: #fff none repeat scroll 0 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  padding: 10px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 70%;
  transform: translateY(-50%);
  transition: all 0.6s ease 0s;
  width: 100%;
  z-index: 5;
}

.team-wrapper .team-img .team-action a {
  color: #fff;
  font-size: 12px;
  transition: all 0.3s ease 0s;
  margin: 0 7px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  border-radius: 100%;
}

.team-wrapper .team-img .team-action a.facebook {
  background-color: #3b5998;
  border: 1px solid #3b5998;
}

.team-wrapper .team-img .team-action a.facebook:hover {
  background-color: transparent;
  color: #3b5998;
}

.team-wrapper .team-img .team-action a.twitter {
  background-color: #55acee;
  border: 1px solid #55acee;
}

.team-wrapper .team-img .team-action a.twitter:hover {
  background-color: transparent;
  color: #55acee;
}

.team-wrapper .team-img .team-action a.instagram {
  background-color: #c32aa3;
  border: 1px solid #c32aa3;
}

.team-wrapper .team-img .team-action a.instagram:hover {
  background-color: transparent;
  color: #c32aa3;
}

.team-wrapper .team-content {
  padding: 20px 10px 21px;
  box-shadow: 0 3px 5px rgba(85, 85, 85, 0.2);
}

.team-wrapper .team-content h4 {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 5px;
  text-transform: capitalize;
  color: #000000;
}

.team-wrapper .team-content span {
  font-size: 15px;
  font-style: italic;
  color: #000000;
}

.team-wrapper:hover .team-action {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.single-testimonial-2 .testimonial-img {
  text-align: center;
}

.single-testimonial-2 .testimonial-img img {
  display: inline-block;
}

.single-testimonial-2 p {
  width: 70%;
  margin: 20px auto 20px;
  font-size: 18px;
  line-height: 35px;
  color: #333;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial-2 p {
    width: 85%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-testimonial-2 p {
    width: 79%;
    margin: 15px auto 20px;
    font-size: 17px;
    line-height: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .single-testimonial-2 p {
    width: 100%;
    margin: 14px auto 15px;
    font-size: 16px;
    line-height: 28px;
  }
}

.single-testimonial-2 .client-info h5 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 6px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .single-testimonial-2 .client-info h5 {
    font-size: 18px;
  }
}

.single-testimonial-2 .client-info span {
  font-size: 16px;
  color: #333;
  display: block;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-area.pt-85 {
    padding-top: 25px;
  }
  .team-area.pb-90 {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us-area.pt-85 {
    padding-top: 20px;
  }
  .team-area.pb-90 {
    padding-bottom: 30px;
  }
}

/*-------- 7. Section title style ---------*/
.section-title-tab-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.section-title-tab-wrap.align-items-inherit {
  align-items: inherit;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-tab-wrap.mb-45 {
    margin-bottom: 40px;
  }
  .section-title-tab-wrap.mb-55 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-tab-wrap.mb-45 {
    margin-bottom: 25px;
  }
  .section-title-tab-wrap.mb-30 {
    margin-bottom: 15px;
  }
  .section-title-tab-wrap.mb-50 {
    margin-bottom: 23px;
  }
  .section-title-tab-wrap.mb-55 {
    margin-bottom: 20px;
  }
}

.section-title h2 {
  font-size: 24px;
  font-weight: bold;
  font-family: "Heebo", sans-serif;
  margin: 0;
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title h2 {
    font-size: 22px;
  }
}

.section-title p {
  font-size: 16px;
  margin: 10px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title.mb-45 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title.mb-45 {
    margin-bottom: 25px;
  }
}

.section-title-tag-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-tag-wrap.mb-70 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-tag-wrap.mb-70 {
    margin-bottom: 25px;
  }
}

.section-title-2 h2 {
  font-size: 30px;
  font-weight: 300;
  margin: 0;
  line-height: 1;
  letter-spacing: .25px;
}

.section-title-2 h2 span {
  font-weight: bold;
}

.section-title-2 p {
  color: #999999;
  margin: 12px 0 0;
}

@media only screen and (max-width: 767px) {
  .section-title-2 p {
    margin: 7px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-2.mb-45 {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-2.mb-45 {
    margin-bottom: 20px;
  }
}

.section-title-3 h2 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  letter-spacing: -.25px;
}

@media only screen and (max-width: 767px) {
  .section-title-3 h2 {
    font-size: 19px;
  }
}

.section-title-3 p {
  margin: 8px 0 0;
}

.section-title-3.section-title-3-white h2 {
  color: #ffffff;
}

.section-title-3.section-title-3-white p {
  color: #ffffff;
}

.section-title-4 h2 {
  font-size: 30px;
  margin: 0;
  letter-spacing: -.25px;
  text-transform: uppercase;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .section-title-4 h2 {
    font-size: 20px;
  }
}

.section-title-5 {
  display: flex;
  padding: 15px 24px;
}

@media only screen and (max-width: 767px) {
  .section-title-5 {
    justify-content: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title-5 {
    justify-content: flex-start;
  }
}

.section-title-5 i {
  font-size: 20px;
  margin-right: 12px;
}

.section-title-5 i.red {
  color: #ff2f2f;
}

.section-title-5 i.purple {
  color: #6f50a7;
}

.section-title-5 i.green {
  color: #6e9b00;
}

.section-title-5 h5 {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .25px;
  margin: 0;
  display: inline-block;
}

.section-title-5 h5.red {
  color: #ff2f2f;
  border-bottom: 1px solid #ff2f2f;
}

.section-title-5 h5.purple {
  color: #6f50a7;
  border-bottom: 1px solid #6f50a7;
}

.section-title-5 h5.green {
  color: #6e9b00;
  border-bottom: 1px solid #6e9b00;
}

.section-title-5.section-title-5-bg-1 {
  background-color: #fdefed;
}

.section-title-5.section-title-5-bg-2 {
  background-color: #f2edfc;
}

.section-title-5.section-title-5-bg-3 {
  background-color: #f7faee;
}

.section-title-6 h2 {
  font-size: 24px;
  text-transform: uppercase;
  margin: 0;
  line-height: 1;
  letter-spacing: .25px;
}

@media only screen and (max-width: 767px) {
  .section-title-6 h2 {
    font-size: 17px;
  }
}

.section-title-6 h2 i {
  position: relative;
  top: 2px;
  margin-right: 14px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-6.mb-60 {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-6.section-title-6-xs h2 {
    font-size: 20px;
  }
  .section-title-6.mb-60 {
    margin-bottom: 25px;
  }
  .section-title-6.mb-25 {
    margin-bottom: 15px;
  }
}

.section-title-7 h2 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 0;
  line-height: 1;
}

.section-title-8 h2 {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 0;
  line-height: 1;
  letter-spacing: -.25px;
}

@media only screen and (max-width: 767px) {
  .section-title-8 h2 {
    font-size: 20px;
    margin: 0 0 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title-8 h2 {
    font-size: 18px;
    margin: 0 0 0px;
  }
}

/*-------- 8. Product style ---------*/
.product-area {
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .tab-style-1 {
    margin-top: 15px;
  }
}

.tab-style-1 a {
  display: inline-block;
  font-size: 16px;
  margin-right: 40px;
  color: #000000;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-style-1 a {
    margin-right: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-style-1 a {
    margin-right: 12px;
    margin-bottom: 5px;
  }
}

.tab-style-1 a:last-child {
  margin-right: 0;
}

.tab-style-1 a.active {
  color: #ff2f2f;
}

.single-product-wrap {
  position: relative;
  overflow: hidden;
}

.single-product-wrap .product-img {
  position: relative;
  overflow: hidden;
}

.single-product-wrap .product-img a {
  display: block;
}

.single-product-wrap .product-img a img {
  width: 100%;
}

.single-product-wrap .product-img span.pro-badge {
  position: absolute;
  top: 17px;
  font-weight: 600;
  color: #ffffff;
  display: inline-block;
  padding: 4px 6px 5px;
  line-height: 1;
}

.single-product-wrap .product-img span.pro-badge.left {
  left: 17px;
}

.single-product-wrap .product-img span.pro-badge.right {
  right: 17px;
}

.single-product-wrap .product-img span.pro-badge.bg-black {
  background-color: #000000;
}

.single-product-wrap .product-img span.pro-badge.bg-red {
  background-color: #ff2f2f;
}

.single-product-wrap .product-img .product-action-2 {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 9;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-product-wrap .product-img .product-action-2 {
    right: 10px;
    top: 10px;
  }
}

.single-product-wrap .product-img .product-action-2 button {
  display: block;
  border: none;
  padding: 0;
  line-height: 1;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #000000;
  border-radius: 100%;
  background-color: #ffffff;
  margin-bottom: 7px;
  border: 1px solid #ebebeb;
}

.single-product-wrap .product-img .product-action-2 button:nth-child(1) {
  transition-delay: 0.2s;
  opacity: 0;
  visibility: hidden;
  margin-top: -10px;
}

.single-product-wrap .product-img .product-action-2 button:nth-child(2) {
  transition-delay: 0.1s;
  opacity: 0;
  visibility: hidden;
  margin-top: -10px;
}

.single-product-wrap .product-img .product-action-2 button:nth-child(3) {
  transition-delay: 0s;
  opacity: 0;
  visibility: hidden;
  margin-top: -10px;
}

.single-product-wrap .product-img .product-action-2 button:hover {
  background-color: #000000;
  border: 1px solid #000000;
  color: #ffffff;
}

.single-product-wrap .product-img .product-action-2 button i {
  line-height: 40px;
}

.single-product-wrap .product-img .product-action-2 button:last-child {
  margin-bottom: 0;
}

.single-product-wrap .product-img .product-action-wrap {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  background-color: #ffffff;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-product-wrap .product-img .product-action-wrap {
    padding: 18px 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-product-wrap .product-img .product-action-wrap {
    padding: 18px 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-product-wrap .product-img .product-action-wrap {
    padding: 18px 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-product-wrap .product-img .product-action-wrap {
    padding: 18px 15px;
  }
}

.single-product-wrap .product-img .product-action-wrap .product-action-left {
  flex: 0 0 60%;
  max-width: 60%;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-product-wrap .product-img .product-action-wrap .product-action-left {
    flex: 0 0 65%;
    max-width: 65%;
  }
}

.single-product-wrap .product-img .product-action-wrap .product-action-left button {
  border: none;
  padding: 0;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: transparent;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-product-wrap .product-img .product-action-wrap .product-action-left button {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-product-wrap .product-img .product-action-wrap .product-action-left button {
    font-size: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-product-wrap .product-img .product-action-wrap .product-action-left button {
    font-size: 14px;
  }
}

.single-product-wrap .product-img .product-action-wrap .product-action-left button i {
  font-size: 20px;
  margin-right: 15px;
  position: relative;
  top: 2px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .single-product-wrap .product-img .product-action-wrap .product-action-left button i {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-product-wrap .product-img .product-action-wrap .product-action-left button i {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-product-wrap .product-img .product-action-wrap .product-action-left button i {
    margin-right: 10px;
  }
}

.single-product-wrap .product-img .product-action-wrap .product-action-left button:hover {
  color: #ff2f2f;
}

.single-product-wrap .product-img .product-action-wrap .product-action-right {
  flex: 0 0 40%;
  max-width: 40%;
  text-align: right;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-product-wrap .product-img .product-action-wrap .product-action-right {
    flex: 0 0 35%;
    max-width: 35%;
  }
}

.single-product-wrap .product-img .product-action-wrap .product-action-right button {
  border: none;
  padding: 0;
  color: #000000;
  margin-left: 24px;
  position: relative;
  background-color: transparent;
  transition: all .3s ease 0s;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-product-wrap .product-img .product-action-wrap .product-action-right button {
    margin-left: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-product-wrap .product-img .product-action-wrap .product-action-right button {
    margin-left: 18px;
  }
}

.single-product-wrap .product-img .product-action-wrap .product-action-right button:first-child {
  margin-left: 0;
}

.single-product-wrap .product-img .product-action-wrap .product-action-right button i {
  font-size: 18px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-product-wrap .product-img .product-action-wrap .product-action-right button i {
    font-size: 16px;
  }
}

.single-product-wrap .product-img .product-action-wrap .product-action-right button.font-inc i {
  font-size: 22px;
  position: relative;
  top: 1px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-product-wrap .product-img .product-action-wrap .product-action-right button.font-inc i {
    font-size: 20px;
  }
}

.single-product-wrap .product-img .product-action-wrap .product-action-right button:hover {
  color: #ff2f2f;
}

.single-product-wrap .product-img.product-img-zoom a img {
  transform: scale(1);
  transition: all .5s ease 0s;
}

.single-product-wrap .product-img.product-img-border a img {
  border: 1px solid #e1e1e1;
  border-radius: 100%;
}

.single-product-wrap .product-img.product-img-border-transparent a img {
  border: 1px solid transparent;
}

.single-product-wrap .product-content-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.single-product-wrap .product-content-wrap .product-content-left {
  flex: 0 0 80%;
  max-width: 80%;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-product-wrap .product-content-wrap .product-content-left {
    flex: 0 0 85%;
    max-width: 85%;
  }
}

.single-product-wrap .product-content-wrap .product-content-left h4 {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 7px;
}

.single-product-wrap .product-content-wrap .product-content-left h4 a {
  color: #000000;
}

.single-product-wrap .product-content-wrap .product-content-left h4 a:hover {
  text-decoration: underline;
}

.single-product-wrap .product-content-wrap .product-content-left span {
  font-size: 20px;
  color: #000000;
  font-family: "Heebo", sans-serif;
}

.single-product-wrap .product-content-wrap .product-content-left span.new-price {
  color: #ff2f2f;
}

.single-product-wrap .product-content-wrap .product-content-left span.old-price {
  text-decoration: line-through;
  margin-left: 12px;
}

.single-product-wrap .product-content-wrap .product-content-right {
  flex: 0 0 20%;
  max-width: 20%;
  text-align: right;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-product-wrap .product-content-wrap .product-content-right {
    flex: 0 0 15%;
    max-width: 15%;
  }
}

.single-product-wrap .product-content-wrap .product-content-right button {
  border: none;
  padding: 0;
  background-color: transparent;
  font-size: 16px;
  color: #000000;
  position: relative;
}

.single-product-wrap .product-content-wrap .product-content-right button:hover {
  color: #ff2f2f;
}

.single-product-wrap .product-content-wrap-2 {
  transition: all .3s ease 0s;
}

.single-product-wrap .product-content-wrap-2 .product-rating-wrap {
  display: flex;
  justify-content: center;
}

.single-product-wrap .product-content-wrap-2 .product-rating-wrap .product-rating i {
  color: #ff9806;
}

.single-product-wrap .product-content-wrap-2 .product-rating-wrap .product-rating i.gray {
  color: #cccccc;
}

.single-product-wrap .product-content-wrap-2 .product-rating-wrap span {
  margin-left: 6px;
  color: #999999;
  font-family: "Heebo", sans-serif;
}

.single-product-wrap .product-content-wrap-2 h3 {
  font-size: 14px;
  font-weight: bold;
  margin: 2px 0 8px;
}

.single-product-wrap .product-content-wrap-2 h3 a {
  color: #000000;
}

.single-product-wrap .product-content-wrap-2 h3 a:hover {
  color: #ff2f2f;
}

.single-product-wrap .product-content-wrap-2 .product-price-2 span {
  font-size: 18px;
  color: #000000;
  font-family: "Heebo", sans-serif;
  display: inline-block;
  margin: 0 4px;
}

.single-product-wrap .product-content-wrap-2 .product-price-2 span.new-price {
  color: #ff2f2f;
}

.single-product-wrap .product-content-wrap-2 .product-price-2 span.old-price {
  font-size: 14px;
  color: #999999;
  text-decoration: line-through;
}

.single-product-wrap .product-content-wrap-2 .product-author {
  margin: 7px 0 0;
}

.single-product-wrap .product-content-wrap-2 .product-author span {
  color: #666666;
  display: block;
  line-height: 1;
}

.single-product-wrap .product-content-wrap-2 .product-author span a {
  color: #000000;
}

.single-product-wrap .product-content-wrap-2 .product-author span a:hover {
  color: #0064e0;
}

.single-product-wrap .product-content-wrap-2 .pro-add-to-cart {
  margin: 14px 0 0;
}

.single-product-wrap .product-content-wrap-2 .pro-add-to-cart button {
  border: none;
  font-weight: bold;
  color: #ffffff;
  background-color: #000000;
  font-size: 14px;
  padding: 8px 28px 8px;
  border-radius: 50px;
  transition: all .3s ease 0s;
}

.single-product-wrap .product-content-wrap-2 .pro-add-to-cart button:hover {
  background-color: #ff2f2f;
}

.single-product-wrap .product-content-wrap-2.product-content-wrap-2-modify .pro-add-to-cart {
  margin: 4px 0 0;
}

.single-product-wrap .product-content-wrap-2.product-content-wrap-2-modify .pro-add-to-cart button {
  font-size: 13px;
  padding: 4px 20px 4px;
}

.single-product-wrap .product-content-wrap-3 .product-content-categories a {
  color: #999999;
  font-size: 13px;
  text-transform: uppercase;
}

.single-product-wrap .product-content-wrap-3 .product-content-categories a:hover {
  color: #4dbdaf;
}

.single-product-wrap .product-content-wrap-3 .product-content-categories a:hover.blue {
  color: #0064e0;
}

.single-product-wrap .product-content-wrap-3 .product-content-categories a:hover.purple {
  color: #6f50a7;
}

.single-product-wrap .product-content-wrap-3 h3 {
  font-size: 14px;
  font-weight: 600;
  color: #222222;
  margin: 5px 0 3px;
}

.single-product-wrap .product-content-wrap-3 h3.mrg-none {
  margin: 0px 0 3px;
}

.single-product-wrap .product-content-wrap-3 h3.bold {
  font-weight: bold;
}

.single-product-wrap .product-content-wrap-3 h3 a {
  color: #222222;
}

.single-product-wrap .product-content-wrap-3 h3 a:hover {
  color: #4dbdaf;
}

.single-product-wrap .product-content-wrap-3 h3 a:hover.blue {
  color: #0064e0;
}

.single-product-wrap .product-content-wrap-3 h3 a:hover.purple {
  color: #6f50a7;
}

.single-product-wrap .product-content-wrap-3 h3 a:hover.orange {
  color: #e44311;
}

.single-product-wrap .product-content-wrap-3 .product-rating-wrap-2 {
  display: flex;
  margin: 0 0 8px;
}

.single-product-wrap .product-content-wrap-3 .product-rating-wrap-2 .product-rating-4 {
  display: flex;
  align-items: center;
}

.single-product-wrap .product-content-wrap-3 .product-rating-wrap-2 .product-rating-4 i {
  font-size: 12px;
  color: #ff9806;
  margin-right: 2px;
}

.single-product-wrap .product-content-wrap-3 .product-rating-wrap-2 .product-rating-4 i.gray {
  color: #cccccc;
}

.single-product-wrap .product-content-wrap-3 .product-rating-wrap-2 span {
  color: #999999;
  font-size: 12px;
  font-family: "Heebo", sans-serif;
  margin-left: 4px;
}

.single-product-wrap .product-content-wrap-3 .product-price-4 span {
  font-size: 18px;
  font-family: "Heebo", sans-serif;
  color: black;
}

.single-product-wrap .product-content-wrap-3 .product-price-4 span.new-price {
  color: #ff2f2f;
}

.single-product-wrap .product-content-wrap-3 .product-price-4 span.old-price {
  margin: 0 0 0 7px;
  font-size: 14px;
  color: #999999;
  text-decoration: line-through;
}

.single-product-wrap .product-content-wrap-3 .product-price-4.product-price-4-hm10 {
  margin: 7px 0 7px;
}

.single-product-wrap .product-content-wrap-3 .pro-add-to-cart-2 {
  margin: 11px 0 0;
}

.single-product-wrap .product-content-wrap-3 .pro-add-to-cart-2 button {
  border: none;
  font-weight: bold;
  color: #ffffff;
  background-color: #000000;
  font-size: 13px;
  padding: 4px 20px 4px;
  border-radius: 50px;
  transition: all .3s ease 0s;
}

.single-product-wrap .product-content-wrap-3 .pro-add-to-cart-2 button:hover {
  background-color: #ff2f2f;
}

.single-product-wrap .product-content-wrap-3 .product-author {
  margin: 7px 0 0;
}

.single-product-wrap .product-content-wrap-3 .product-author span {
  color: #666666;
  display: block;
  line-height: 1;
}

.single-product-wrap .product-content-wrap-3 .product-author span a {
  color: #000000;
}

.single-product-wrap .product-content-wrap-3 .product-author span a:hover {
  color: #0064e0;
}

.single-product-wrap .product-content-wrap-3 .product-author span a:hover.orange {
  color: #e44311;
}

.single-product-wrap .product-content-wrap-3 .single-product-sold {
  margin-top: 15px;
}

.single-product-wrap .product-content-wrap-3 .single-product-sold .progress {
  background: #eeeeee none repeat scroll 0 0;
  border-radius: 0;
  box-shadow: none;
  height: 8px;
  overflow: visible;
}

.single-product-wrap .product-content-wrap-3 .single-product-sold .progress .progress-bar {
  background: #e44311;
  box-shadow: none;
  position: relative;
}

.single-product-wrap .product-content-wrap-3 .single-product-sold .progress .progress-bar.progress-bar-width70 {
  width: 70%;
}

.single-product-wrap .product-content-wrap-3 .single-product-sold .progress .progress-bar.progress-bar-width50 {
  width: 50%;
}

.single-product-wrap .product-content-wrap-3 .single-product-sold .progress .progress-bar.progress-bar-width30 {
  width: 30%;
}

.single-product-wrap .product-content-wrap-3 .single-product-sold .progress .progress-bar.progress-bar-width90 {
  width: 90%;
}

.single-product-wrap .product-content-wrap-3 .single-product-sold .progress .progress-bar.progress-bar-width60 {
  width: 60%;
}

.single-product-wrap .product-content-wrap-3 .single-product-sold span {
  font-size: 13px;
  color: #000000;
  letter-spacing: .25px;
  display: block;
  margin: 11px 0 0;
  line-height: 1;
}

.single-product-wrap .product-content-position {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  transition: all .3s ease 0s;
  visibility: hidden;
  opacity: 0;
  background-color: #ffffff;
  padding: 17px 10px 5px;
}

.single-product-wrap .product-content-position.product-content-wrap-2-modify {
  padding: 10px 10px 5px;
}

.single-product-wrap .product-content-position.product-content-position-plr-0 {
  padding: 10px 0px 5px;
}

.single-product-wrap .product-content-position h3 a {
  color: #999999;
}

.single-product-wrap .product-content-position h3 a:hover {
  color: #ff2f2f;
}

.single-product-wrap .product-content-position-2 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  transition: all .3s ease 0s;
  visibility: hidden;
  opacity: 0;
  background-color: #ffffff;
  padding: 17px 0px 5px;
}

.single-product-wrap .product-content-position-2.pro-position-2-padding-dec {
  padding: 10px 0px 5px;
}

.single-product-wrap .tooltip-style button span {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -45px;
  color: #ffffff;
  background-color: #000000;
  white-space: pre;
  font-size: 13px;
  display: block;
  line-height: 1;
  padding: 4px 12px 6px;
  opacity: 0;
  visibility: hidden;
}

.single-product-wrap .tooltip-style button span:before {
  position: absolute;
  content: "";
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 12px solid #000;
}

.single-product-wrap .tooltip-style button:first-child span {
  left: -14px;
  transform: none;
}

.single-product-wrap .tooltip-style button:first-child span:before {
  left: 19px;
  transform: none;
}

.single-product-wrap .tooltip-style button:last-child span {
  right: 0px;
  top: -41px;
  transform: none;
  left: auto;
}

.single-product-wrap .tooltip-style button:last-child span:before {
  right: 7px;
  left: auto;
  transform: none;
}

.single-product-wrap .tooltip-style button:hover span {
  opacity: 1;
  visibility: visible;
}

.single-product-wrap .product-content-2 h3 {
  margin: 0;
  font-size: 20px;
  font-weight: 300;
}

.single-product-wrap .product-content-2 h3 a {
  position: relative;
  color: #000000;
}

.single-product-wrap .product-content-2 h3 a span.blod {
  font-weight: bold;
}

.single-product-wrap .product-content-2 h3 a span.available-product {
  position: absolute;
  font-size: 12px;
  top: 3px;
  right: -28px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .single-product-wrap .product-content-2 h3 a span.available-product {
    right: -25px;
  }
}

.single-product-wrap .product-content-2 h3 a:hover {
  text-decoration: underline;
}

.single-product-wrap .product-content-categories-2 h5 {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  letter-spacing: .5px;
  margin: 0;
  text-transform: uppercase;
}

.single-product-wrap .product-content-categories-2 h5.font-width-dec {
  font-weight: 400;
  letter-spacing: 0px;
}

.single-product-wrap .product-content-categories-2 h5 a {
  color: #000000;
}

.single-product-wrap .product-content-categories-2 h5 a:hover {
  color: #6f50a7;
}

.single-product-wrap .product-content-categories-2.product-content-blue h5 a {
  display: inline-block;
  border-bottom: 1px solid transparent;
}

.single-product-wrap .product-content-categories-2.product-content-blue h5 a:hover {
  border-bottom: 1px solid #0064e0;
  color: #0064e0;
}

.single-product-wrap .product-content-categories-2.product-content-orange h5 a {
  display: inline-block;
  border-bottom: 1px solid transparent;
}

.single-product-wrap .product-content-categories-2.product-content-orange h5 a:hover {
  border-bottom: 1px solid #e44311;
  color: #e44311;
}

.single-product-wrap .product-content-categories-2.product-content-green-2 h5 a {
  display: inline-block;
  border-bottom: 1px solid transparent;
}

.single-product-wrap .product-content-categories-2.product-content-green-2 h5 a:hover {
  border-bottom: 1px solid #6aae27;
  color: #6aae27;
}

.single-product-wrap:hover .product-img.product-img-zoom a img {
  transform: scale(1.1);
}

.single-product-wrap:hover .product-img .product-action-wrap {
  bottom: 0px;
  opacity: 1;
  visibility: visible;
}

.single-product-wrap:hover .product-img .product-action-2 button:nth-child(1) {
  transition-delay: 0.1s;
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}

.single-product-wrap:hover .product-img .product-action-2 button:nth-child(2) {
  transition-delay: 0.2s;
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}

.single-product-wrap:hover .product-img .product-action-2 button:nth-child(3) {
  transition-delay: 0.3s;
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}

.single-product-wrap:hover .product-img.product-img-border a img {
  border: 1px solid #6f50a7;
}

.single-product-wrap:hover .product-img.product-img-border.border-blue a img {
  border: 1px solid #0064e0;
}

.single-product-wrap:hover .product-img.product-img-border.border-green-2 a img {
  border: 1px solid #6aae27;
}

.single-product-wrap:hover .product-img.product-img-border-transparent a img {
  border: 1px solid #e44311;
}

.single-product-wrap:hover .product-content-wrap-2 {
  opacity: 0;
  visibility: hidden;
}

.single-product-wrap:hover .product-content-position {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}

.single-product-wrap:hover .product-content-position-2 {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}

@media only screen and (max-width: 767px) {
  .single-product-wrap.mb-35 {
    margin-bottom: 30px;
  }
  .single-product-wrap.mb-60 {
    margin-bottom: 30px;
  }
  .single-product-wrap.mb-50 {
    margin-bottom: 30px;
  }
}

.tab-content.jump .tab-pane {
  display: block;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.tab-content.jump .tab-pane.active {
  display: block;
  height: auto;
  opacity: 1;
  overflow: visible;
  animation-name: moveUp;
  animation-duration: 500ms;
  animation-timing-function: cubic-bezier(0.26, 0.69, 0.37, 0.96);
  animation-play-state: running;
}

.product-slider-active .slick-list,
.product-slider-active-3 .slick-list,
.product-slider-active-4 .slick-list,
.product-slider-active-5 .slick-list,
.product-slider-active-7 .slick-list,
.product-slider-active-8 .slick-list,
.product-slider-active-9 .slick-list,
.related-product-active .slick-list {
  margin: 0 -15px;
}

.product-slider-active .slick-list .product-plr-1,
.product-slider-active-3 .slick-list .product-plr-1,
.product-slider-active-4 .slick-list .product-plr-1,
.product-slider-active-5 .slick-list .product-plr-1,
.product-slider-active-7 .slick-list .product-plr-1,
.product-slider-active-8 .slick-list .product-plr-1,
.product-slider-active-9 .slick-list .product-plr-1,
.related-product-active .slick-list .product-plr-1 {
  padding: 0 15px;
}

.product-categories-slider-1 .slick-list,
.product-categories-slider-2 .slick-list,
.product-categories-slider-3 .slick-list {
  margin: 0 -20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-categories-slider-1 .slick-list,
  .product-categories-slider-2 .slick-list,
  .product-categories-slider-3 .slick-list {
    margin: 0 -15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-categories-slider-1 .slick-list,
  .product-categories-slider-2 .slick-list,
  .product-categories-slider-3 .slick-list {
    margin: 0 -15px;
  }
}

.product-categories-slider-1 .slick-list .product-plr-1,
.product-categories-slider-2 .slick-list .product-plr-1,
.product-categories-slider-3 .slick-list .product-plr-1 {
  padding: 0 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-categories-slider-1 .slick-list .product-plr-1,
  .product-categories-slider-2 .slick-list .product-plr-1,
  .product-categories-slider-3 .slick-list .product-plr-1 {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .product-categories-slider-1 .slick-list .product-plr-1,
  .product-categories-slider-2 .slick-list .product-plr-1,
  .product-categories-slider-3 .slick-list .product-plr-1 {
    padding: 0 15px;
  }
}

.product-slider-active-2 .slick-list {
  margin: 0 -15px;
  padding: 20px 0 20px;
}

.product-slider-active-2 .slick-list .product-plr-2 {
  padding: 0 15px;
}

.product-slider-active-6 .slick-list {
  margin: 0 -15px;
}

.product-slider-active-6 .slick-list .product-plr-2 {
  padding: 0 15px;
}

.tab-btn-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .tab-btn-wrap {
    justify-content: center;
  }
  .tab-btn-wrap.mb-40 {
    margin-bottom: 30px;
  }
}

.tab-style-2 a {
  color: #999999;
  display: inline-block;
  letter-spacing: .1px;
  text-transform: uppercase;
  margin-right: 30px;
}

@media only screen and (max-width: 767px) {
  .tab-style-2 a {
    margin-bottom: 5px;
  }
}

.tab-style-2 a:last-child {
  margin-right: 0;
}

.tab-style-2 a.active {
  color: #181b31;
}

@media only screen and (max-width: 767px) {
  .tab-style-2 {
    margin: 0px 8px 2px;
    justify-content: center;
  }
}

.deal-content-1 > span {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  border-radius: 5px;
  padding: 4px 10px;
  text-transform: uppercase;
  background-color: #6b7b90;
}

.deal-content-1 h2 {
  font-size: 60px;
  font-weight: 300;
  line-height: 60px;
  letter-spacing: -.25px;
  margin: 35px 0 24px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-content-1 h2 {
    font-size: 50px;
    line-height: 55px;
    margin: 25px 0 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content-1 h2 {
    font-size: 50px;
    line-height: 55px;
    margin: 25px 0 14px;
  }
}

@media only screen and (max-width: 767px) {
  .deal-content-1 h2 {
    font-size: 34px;
    line-height: 40px;
    margin: 25px 0 14px;
  }
}

.deal-content-1 h2 span {
  text-transform: uppercase;
  font-weight: bold;
  color: #ff2f2f;
}

.deal-content-1 p {
  width: 30%;
  letter-spacing: .25px;
  margin: 0 0 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-content-1 p {
    width: 37%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content-1 p {
    width: 37%;
  }
}

@media only screen and (max-width: 767px) {
  .deal-content-1 p {
    width: 90%;
  }
}

.deal-content-1 .timer-wrap {
  margin: 45px 0 105px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-content-1 .timer-wrap {
    margin: 35px 0 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content-1 .timer-wrap {
    margin: 35px 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .deal-content-1 .timer-wrap {
    margin: 25px 0 30px;
  }
}

.deal-content-1 .timer-wrap h4 {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 27px;
}

@media only screen and (max-width: 767px) {
  .deal-content-1 .timer-wrap h4 {
    margin: 0 0 10px;
  }
}

.deal-content-1 .timer-wrap .timer-style-1 .syotimer__body {
  display: flex;
  flex-wrap: wrap;
}

.deal-content-1 .timer-wrap .timer-style-1 .syotimer__body .syotimer-cell {
  text-align: center;
  margin-right: 60px;
}

@media only screen and (max-width: 767px) {
  .deal-content-1 .timer-wrap .timer-style-1 .syotimer__body .syotimer-cell {
    margin-right: 35px;
  }
}

.deal-content-1 .timer-wrap .timer-style-1 .syotimer__body .syotimer-cell:last-child {
  margin-right: 0;
}

.deal-content-1 .timer-wrap .timer-style-1 .syotimer__body .syotimer-cell:last-child .syotimer-cell__value:before {
  display: none;
}

.deal-content-1 .timer-wrap .timer-style-1 .syotimer__body .syotimer-cell:last-child .syotimer-cell__value:after {
  display: none;
}

.deal-content-1 .timer-wrap .timer-style-1 .syotimer__body .syotimer-cell .syotimer-cell__value {
  font-size: 48px;
  line-height: 38px;
  font-weight: 300;
  letter-spacing: -.25px;
  margin: 0 0 10px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .deal-content-1 .timer-wrap .timer-style-1 .syotimer__body .syotimer-cell .syotimer-cell__value {
    font-size: 30px;
  }
}

.deal-content-1 .timer-wrap .timer-style-1 .syotimer__body .syotimer-cell .syotimer-cell__value:before {
  position: absolute;
  content: "";
  right: -50%;
  transform: translateX(50%);
  top: 20%;
  width: 4px;
  height: 4px;
  background-color: #000000;
}

.deal-content-1 .timer-wrap .timer-style-1 .syotimer__body .syotimer-cell .syotimer-cell__value:after {
  position: absolute;
  content: "";
  right: -50%;
  transform: translateX(50%);
  bottom: 20%;
  width: 4px;
  height: 4px;
  background-color: #000000;
}

.deal-content-1 .timer-wrap .timer-style-1 .syotimer__body .syotimer-cell .syotimer-cell__unit {
  letter-spacing: -.25px;
  line-height: 1;
  text-transform: capitalize;
}

.deal-content-1 .deal-btn a {
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
  padding: 0 0 3px;
  border-bottom: 1px solid #000000;
  color: #000000;
}

.deal-content-1 .deal-btn a:hover {
  color: #ff2f2f;
  border-bottom: 1px solid #ff2f2f;
}

@media only screen and (max-width: 767px) {
  .deal-content-1 .deal-btn a {
    font-size: 18px;
  }
}

.deal-content-1.deal-content-1-modify {
  padding-left: 170px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .deal-content-1.deal-content-1-modify {
    padding-left: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .deal-content-1.deal-content-1-modify {
    padding-left: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-content-1.deal-content-1-modify {
    padding-left: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content-1.deal-content-1-modify {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .deal-content-1.deal-content-1-modify {
    padding-left: 15px;
  }
}

.deal-content-1.deal-content-1-modify > span {
  font-size: 14px;
  background-color: #508b11;
}

.deal-content-1.deal-content-1-modify h2 {
  font-size: 48px;
  line-height: 48px;
  margin: 23px 0 0px;
}

@media only screen and (max-width: 767px) {
  .deal-content-1.deal-content-1-modify h2 {
    font-size: 28px;
    line-height: 33px;
    margin: 15px 0 10px;
  }
}

.deal-content-1.deal-content-1-modify .timer-wrap {
  margin: 48px 0 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-content-1.deal-content-1-modify .timer-wrap {
    margin: 35px 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content-1.deal-content-1-modify .timer-wrap {
    margin: 35px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .deal-content-1.deal-content-1-modify .timer-wrap {
    margin: 25px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-style-3 {
    margin-top: 10px;
  }
}

.tab-style-3 a {
  display: inline-block;
  color: #999999;
  padding: 3px 16px 5px;
  margin-right: 5px;
  border-radius: 5px;
}

@media only screen and (max-width: 767px) {
  .tab-style-3 a {
    margin-bottom: 5px;
  }
}

.tab-style-3 a:last-child {
  margin-right: 0;
}

.tab-style-3 a:hover {
  color: #4dbdaf;
}

.tab-style-3 a.active {
  font-weight: 600;
  color: #4dbdaf;
  background-color: #edf8f7;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-style-3.tab-style-3-blue a {
    padding: 2px 10px 4px;
    margin-right: 3px;
  }
}

.tab-style-3.tab-style-3-blue a:hover {
  color: #0064e0;
}

.tab-style-3.tab-style-3-blue a.active {
  color: #0064e0;
  background-color: #eef5fd;
}

.tab-style-3.tab-style-3-green-2 a:hover {
  color: #6aae27;
}

.tab-style-3.tab-style-3-green-2 a.active {
  color: #6aae27;
  background-color: #eef8e4;
}

.single-product-wrap-2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}

.single-product-wrap-2:before {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  content: "";
  border: 1px solid #bfbfbf;
  transition: all .4s ease 0s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 9;
}

.single-product-wrap-2 .product-img-2 {
  flex: 0 0 37%;
  max-width: 37%;
  position: relative;
}

.single-product-wrap-2 .product-img-2 a {
  display: block;
}

.single-product-wrap-2 .product-img-2 a img {
  width: 100%;
}

.single-product-wrap-2 .product-img-2 span.pro-badge {
  position: absolute;
  top: 0px;
  font-weight: 600;
  color: #ffffff;
  display: inline-block;
  padding: 4px 6px 5px;
  line-height: 1;
}

.single-product-wrap-2 .product-img-2 span.pro-badge.left {
  left: 0px;
}

.single-product-wrap-2 .product-img-2 span.pro-badge.right {
  right: 0px;
}

.single-product-wrap-2 .product-img-2 span.pro-badge.bg-black {
  background-color: #000000;
}

.single-product-wrap-2 .product-img-2 span.pro-badge.bg-red {
  background-color: #ff2f2f;
}

.single-product-wrap-2 .product-content-3 {
  flex: 0 0 63%;
  max-width: 63%;
  padding: 0 15px 0 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-product-wrap-2 .product-content-3 {
    padding: 0 10px 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .single-product-wrap-2 .product-content-3 {
    padding: 0 10px 0 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-product-wrap-2 .product-content-3 {
    padding: 0 15px 0 25px;
  }
}

.single-product-wrap-2 .product-content-3 span {
  font-size: 12px;
  color: #999999;
  text-transform: uppercase;
  letter-spacing: .25px;
  display: block;
}

.single-product-wrap-2 .product-content-3 h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 4px 0 14px;
}

@media only screen and (max-width: 767px) {
  .single-product-wrap-2 .product-content-3 h4 {
    margin: 4px 0 6px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-product-wrap-2 .product-content-3 h4 {
    margin: 4px 0 14px;
  }
}

.single-product-wrap-2 .product-content-3 h4 a {
  color: #000000;
}

.single-product-wrap-2 .product-content-3 h4 a:hover {
  text-decoration: underline;
}

.single-product-wrap-2 .product-content-3 .product-rating-wrap-2 {
  display: flex;
  align-items: center;
  margin: 0 0 24px;
}

@media only screen and (max-width: 767px) {
  .single-product-wrap-2 .product-content-3 .product-rating-wrap-2 {
    margin: 0 0 8px;
  }
}

.single-product-wrap-2 .product-content-3 .product-rating-wrap-2 .product-rating-2 {
  display: flex;
}

.single-product-wrap-2 .product-content-3 .product-rating-wrap-2 .product-rating-2 i {
  font-size: 14px;
  color: #ff9806;
  margin-right: 2px;
}

.single-product-wrap-2 .product-content-3 .product-rating-wrap-2 .product-rating-2 i.gray {
  color: #cccccc;
}

.single-product-wrap-2 .product-content-3 .product-rating-wrap-2 span {
  font-size: 12px;
  color: #999999;
  font-family: "Heebo", sans-serif;
  margin-left: 5px;
}

.single-product-wrap-2 .product-content-3 .pro-price-action-wrap {
  display: flex;
  justify-content: space-between;
}

.single-product-wrap-2 .product-content-3 .pro-price-action-wrap .product-price-3 {
  display: flex;
  align-items: center;
}

.single-product-wrap-2 .product-content-3 .pro-price-action-wrap .product-price-3 span {
  font-size: 18px;
  font-family: "Heebo", sans-serif;
  color: #000000;
}

.single-product-wrap-2 .product-content-3 .pro-price-action-wrap .product-price-3 span.new-price {
  color: #ff2f2f;
}

.single-product-wrap-2 .product-content-3 .pro-price-action-wrap .product-price-3 span.old-price {
  color: #999999;
  font-size: 14px;
  margin-left: 10px;
  text-decoration: line-through;
}

.single-product-wrap-2 .product-content-3 .pro-price-action-wrap .product-action-3 {
  display: flex;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  transition: all .4s ease 0s;
}

.single-product-wrap-2 .product-content-3 .pro-price-action-wrap .product-action-3 button {
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: 22px;
  color: #222222;
  margin-right: 15px;
}

@media only screen and (max-width: 767px) {
  .single-product-wrap-2 .product-content-3 .pro-price-action-wrap .product-action-3 button {
    font-size: 17px;
    margin-right: 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-product-wrap-2 .product-content-3 .pro-price-action-wrap .product-action-3 button {
    font-size: 22px;
    margin-right: 15px;
  }
}

.single-product-wrap-2 .product-content-3 .pro-price-action-wrap .product-action-3 button:last-child {
  margin-right: 0;
}

.single-product-wrap-2 .product-content-3 .pro-price-action-wrap .product-action-3 button:hover {
  color: #4dbdaf;
}

.single-product-wrap-2 .product-content-3 .pro-price-action-wrap .product-action-3.pro-action-3-blue button:hover {
  color: #0064e0;
}

.single-product-wrap-2 .product-content-3 .pro-price-action-wrap .product-action-3.pro-action-3-green-2 button:hover {
  color: #6aae27;
}

.single-product-wrap-2:hover:before {
  left: -13px;
  top: -13px;
  right: -13px;
  bottom: -13px;
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 767px) {
  .single-product-wrap-2:hover:before {
    left: -10px;
    top: -10px;
    right: -10px;
    bottom: -10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-product-wrap-2:hover:before {
    left: -13px;
    top: -13px;
    right: -13px;
    bottom: -13px;
  }
}

.single-product-wrap-2:hover .product-content-3 .pro-price-action-wrap .product-action-3 {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.product-slider-active-6 .single-product-wrap-2 {
  padding: 10px 0;
}

.product-slider-active-6 .single-product-wrap-2:before {
  left: 10px;
  top: 10px;
  right: 10px;
  bottom: 10px;
}

.product-slider-active-6 .single-product-wrap-2:hover:before {
  left: 1px;
  top: 0px;
  right: 1px;
  bottom: 0px;
}

.product-categories-wrap {
  display: flex;
  flex-wrap: wrap;
}

.product-categories-wrap .product-categories-img {
  flex: 0 0 37%;
  max-width: 37%;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .product-categories-wrap .product-categories-img {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.product-categories-wrap .product-categories-img a {
  display: block;
}

.product-categories-wrap .product-categories-img a img {
  width: 100%;
  transform: scale(1);
  transition: all .3s ease 0s;
}

.product-categories-wrap .product-categories-content {
  flex: 0 0 63%;
  max-width: 63%;
  padding: 0 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .product-categories-wrap .product-categories-content {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 0 0 15px;
  }
}

.product-categories-wrap .product-categories-content h3 {
  font-size: 18px;
  color: #222222;
  font-weight: 600;
  margin: 0;
}

.product-categories-wrap .product-categories-content ul {
  margin: 16px 0 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-categories-wrap .product-categories-content ul {
    margin: 6px 0 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-categories-wrap .product-categories-content ul {
    margin: 6px 0 8px;
  }
}

@media only screen and (max-width: 767px) {
  .product-categories-wrap .product-categories-content ul {
    margin: 8px 0 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-categories-wrap .product-categories-content ul {
    margin: 6px 0 5px;
  }
}

.product-categories-wrap .product-categories-content ul li {
  display: block;
  margin: 0 0 4px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-categories-wrap .product-categories-content ul li {
    margin: 0 0 1px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-categories-wrap .product-categories-content ul li {
    margin: 0 0 2px;
  }
}

@media only screen and (max-width: 767px) {
  .product-categories-wrap .product-categories-content ul li {
    margin: 0 0 2px;
  }
}

.product-categories-wrap .product-categories-content ul li:last-child {
  margin-bottom: 0;
}

.product-categories-wrap .product-categories-content ul li a {
  display: block;
  font-size: 14px;
  color: #666666;
}

.product-categories-wrap .product-categories-content ul li a:hover {
  color: #222;
  text-decoration: underline;
}

.product-categories-wrap .product-categories-content .product-categories-btn a {
  display: inline-block;
  font-size: 12px;
  color: #333;
  font-weight: 600;
  text-transform: uppercase;
}

.product-categories-wrap .product-categories-content .product-categories-btn a i {
  margin: 0 0 0 2px;
  font-size: 11px;
  position: relative;
  top: 1px;
}

.product-categories-wrap .product-categories-content .product-categories-btn a:hover {
  color: #4dbdaf;
}

.product-categories-wrap.product-categories-border {
  border-right: 1px solid #e1e1e1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-categories-wrap.product-categories-border.categories-border-none-md {
    border: none;
  }
}

@media only screen and (max-width: 767px) {
  .product-categories-wrap.product-categories-border {
    border: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-categories-wrap.categories-border-add-md {
    border-right: 1px solid #e1e1e1;
  }
}

@media only screen and (max-width: 767px) {
  .product-categories-wrap.mb-45 {
    margin-bottom: 30px;
  }
}

.product-categories-wrap:hover .product-categories-img a img {
  transform: scale(1.1);
}

.btn-style-5 a {
  display: inline-block;
  color: #666666;
  font-size: 14px;
}

.btn-style-5 a i {
  margin-left: 2px;
  font-size: 12px;
  position: relative;
  top: 1px;
}

.btn-style-5 a:hover {
  color: #4dbdaf;
}

@media only screen and (max-width: 767px) {
  .tab-style-4 {
    margin: 10px 0 0;
  }
}

.tab-style-4 a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #999999;
  margin-right: 50px;
  padding: 0 0 22px;
  border-bottom: 1px solid transparent;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-style-4 a {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-style-4 a {
    margin-right: 20px;
    padding: 0 0 9px;
    margin-bottom: 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tab-style-4 a {
    margin-bottom: 0px;
  }
}

.tab-style-4 a:last-child {
  margin-right: 0;
}

.tab-style-4 a.active {
  color: #222;
  border-bottom: 1px solid #222;
}

.nav-style-3 > span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5%;
  text-align: center;
  color: #222222;
  font-size: 24px;
  cursor: pointer;
  transition: all .6s ease 0s;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nav-style-3 > span {
    left: -0%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-3 > span {
    left: 2%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-3 > span {
    left: 0%;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-3 > span {
    left: 3%;
  }
}

.nav-style-3 > span.pro-slider-icon-1-next {
  left: auto;
  right: -5%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nav-style-3 > span.pro-slider-icon-1-next {
    right: -0%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-3 > span.pro-slider-icon-1-next {
    right: 2%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-3 > span.pro-slider-icon-1-next {
    right: 0%;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-3 > span.pro-slider-icon-1-next {
    right: 3%;
  }
}

.nav-style-3 > span:hover {
  color: #ff2f2f;
}

.nav-style-3:hover span {
  left: -6%;
  opacity: 1;
  visibility: visible;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nav-style-3:hover span {
    left: -1%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-3:hover span {
    left: -1%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-3:hover span {
    left: -1%;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-3:hover span {
    left: 0%;
  }
}

.nav-style-3:hover span.pro-slider-icon-1-next {
  left: auto;
  right: -6%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nav-style-3:hover span.pro-slider-icon-1-next {
    right: -1%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-3:hover span.pro-slider-icon-1-next {
    right: -1%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-3:hover span.pro-slider-icon-1-next {
    right: -1%;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-3:hover span.pro-slider-icon-1-next {
    right: 0%;
  }
}

.nav-style-4 > span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5%;
  text-align: center;
  color: #cccccc;
  font-size: 36px;
  cursor: pointer;
  transition: all .6s ease 0s;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nav-style-4 > span {
    left: -0%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-4 > span {
    left: -1%;
  }
}

@media only screen and (max-width: 767px) {
  .nav-style-4 > span {
    font-size: 22px;
  }
}

.nav-style-4 > span.pro-slider-icon-1-next {
  left: auto;
  right: -5%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nav-style-4 > span.pro-slider-icon-1-next {
    right: -0%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-4 > span.pro-slider-icon-1-next {
    right: -1%;
  }
}

.nav-style-4 > span:hover {
  color: #000000;
}

.nav-style-4:hover span {
  left: -6%;
  opacity: 1;
  visibility: visible;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nav-style-4:hover span {
    left: -1%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-4:hover span {
    left: -3%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-4:hover span {
    left: -3%;
  }
}

.nav-style-4:hover span.pro-slider-icon-1-next {
  left: auto;
  right: -6%;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .nav-style-4:hover span.pro-slider-icon-1-next {
    right: -1%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .nav-style-4:hover span.pro-slider-icon-1-next {
    right: -3%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-style-4:hover span.pro-slider-icon-1-next {
    right: -3%;
  }
}

.product-categories-wrap-2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-categories-wrap-2 {
    justify-content: inherit;
  }
}

.product-categories-wrap-2 .single-product-categories-2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-categories-wrap-2 .single-product-categories-2 {
    width: 33.333%;
    flex: 0 0 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .product-categories-wrap-2 .single-product-categories-2 {
    width: 100%;
    flex: 0 0 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-categories-wrap-2 .single-product-categories-2 {
    width: 50%;
    flex: 0 0 50%;
  }
}

.product-categories-wrap-2 .single-product-categories-2 .product-categories-2-icon {
  margin-right: 24px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-categories-wrap-2 .single-product-categories-2 .product-categories-2-icon {
    margin-right: 15px;
  }
}

.product-categories-wrap-2 .single-product-categories-2 .product-categories-2-icon i {
  font-size: 40px;
  color: #cccccc;
  transition: all .3s ease 0s;
}

.product-categories-wrap-2 .single-product-categories-2 .product-categories-2-content h4 {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.product-categories-wrap-2 .single-product-categories-2 .product-categories-2-content h4 a {
  color: #000000;
}

.product-categories-wrap-2 .single-product-categories-2:hover .product-categories-2-icon i {
  color: #000000;
}

.product-categories-wrap-2 .single-product-categories-2:hover .product-categories-2-content h4 {
  font-weight: bold;
}

.tab-btn-wrap-2 {
  display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-btn-wrap-2 {
    margin: 10px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .tab-btn-wrap-2 {
    display: block;
    margin: 7px 0 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tab-btn-wrap-2 {
    display: flex;
  }
}

@media only screen and (max-width: 767px) {
  .tab-style-5 {
    margin-bottom: 5px;
  }
}

.tab-style-5 a {
  color: #999999;
  font-size: 16px;
  margin-right: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tab-style-5 a {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-style-5 a {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-style-5 a {
    margin-right: 20px;
  }
}

.tab-style-5 a:last-child {
  margin-right: 0;
}

.tab-style-5 a.active {
  color: #000000;
  font-weight: 600;
}

.tab-style-5 a:hover {
  color: #000000;
}

.btn-style-6 a {
  display: inline-block;
  text-transform: uppercase;
  font-size: 16px;
  color: #000000;
}

.btn-style-6 a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 767px) {
  .btn-style-6.ml-60 {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .btn-style-6.ml-60 {
    margin-left: 20px;
  }
}

.hm4-pb-100 {
  padding-bottom: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hm4-pb-100 {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .hm4-pb-100 {
    padding-bottom: 25px;
  }
}

.section-title-btn-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .section-title-btn-wrap.pb-20 {
    padding-bottom: 15px;
  }
  .section-title-btn-wrap.mb-50 {
    margin-bottom: 30px;
  }
}

.section-title-deal-wrap {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-deal-wrap {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-deal-wrap {
    display: block;
    margin: 0 0 5px;
  }
}

.section-title-deal-wrap .timer-wrap-2 {
  display: flex;
  align-items: center;
  margin-left: 75px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-deal-wrap .timer-wrap-2 {
    margin-left: 0;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-deal-wrap .timer-wrap-2 {
    margin-left: 0px;
    display: block;
  }
}

.section-title-deal-wrap .timer-wrap-2 h4 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: .25px;
}

@media only screen and (max-width: 767px) {
  .section-title-deal-wrap .timer-wrap-2 h4 {
    margin: 10px 0 10px;
  }
}

.section-title-deal-wrap .timer-wrap-2 h4 i {
  font-size: 16px;
  margin-right: 7px;
  position: relative;
  top: 2px;
}

.timer-style-2 {
  margin-left: 15px;
}

@media only screen and (max-width: 767px) {
  .timer-style-2 {
    margin-left: 0px;
  }
}

.timer-style-2 .syotimer__body {
  display: flex;
  flex-wrap: wrap;
}

.timer-style-2 .syotimer__body .syotimer-cell {
  margin-right: 15px;
  position: relative;
  text-align: center;
  display: flex;
  color: #f33a0f;
  font-weight: 600;
  font-size: 14px;
}

.timer-style-2 .syotimer__body .syotimer-cell::before {
  position: absolute;
  content: "";
  right: -8px;
  top: 42%;
  width: 2px;
  height: 2px;
  background-color: #f33a0f;
}

.timer-style-2 .syotimer__body .syotimer-cell::after {
  position: absolute;
  content: "";
  right: -8px;
  bottom: 32%;
  width: 2px;
  height: 2px;
  background-color: #f33a0f;
}

.timer-style-2 .syotimer__body .syotimer-cell:last-child {
  margin-right: 0px;
}

.timer-style-2 .syotimer__body .syotimer-cell:last-child::before {
  display: none;
}

.timer-style-2 .syotimer__body .syotimer-cell:last-child::after {
  display: none;
}

.timer-style-2 .syotimer__body .syotimer-cell .syotimer-cell__value {
  margin-right: 2px;
}

.btn-style-7 a {
  display: inline-block;
  text-transform: uppercase;
  border-bottom: 1px solid #666666;
  padding: 0 0 2px;
  color: #000000;
  line-height: 1;
}

.btn-style-7 a:hover {
  color: #6f50a7;
  border-bottom: 1px solid #6f50a7;
}

.btn-style-7.btn-style-7-blue a:hover {
  color: #0064e0;
  border-bottom: 1px solid #0064e0;
}

.product-list-style-wrap {
  border: 1px solid #e0e0e0;
  padding: 18px 30px 33px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-list-style-wrap {
    padding: 34px 30px 33px;
  }
}

@media only screen and (max-width: 767px) {
  .product-list-style-wrap {
    padding: 30px 20px 30px;
  }
}

.product-list-style {
  margin: 0 0 115px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-list-style {
    margin: 0 0 97px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-list-style {
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .product-list-style {
    margin: 0 0 35px;
  }
}

.product-list-style a {
  display: block;
  color: #000000;
  width: 100%;
  margin: 0 0 14px;
  letter-spacing: .25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-list-style a {
    margin: 0 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .product-list-style a {
    margin: 0 0 10px;
  }
}

.product-list-style a:last-child {
  margin: 0 0 0;
}

.product-list-style a.active {
  color: #999999;
}

.product-list-style a:hover {
  color: #ff2f2f;
}

.btn-style-8 a {
  display: block;
  color: #999999;
  font-style: italic;
}

.btn-style-8 a:hover {
  color: #ff2f2f;
}

.tab-style-7 {
  justify-content: flex-end;
  margin-top: -47px;
  margin-right: 24px;
}

@media only screen and (max-width: 767px) {
  .tab-style-7 {
    margin-top: 0px;
    margin-right: 0px;
    justify-content: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tab-style-7 {
    justify-content: flex-end;
    margin-top: -47px;
    margin-right: 24px;
  }
}

.tab-style-7 a {
  color: #000000;
  margin-right: 40px;
}

@media only screen and (max-width: 767px) {
  .tab-style-7 a {
    margin-right: 20px;
  }
}

.tab-style-7 a:last-child {
  margin-right: 0;
}

.tab-style-7 a.active {
  font-weight: bold;
}

.tab-content-mrg-top {
  margin-top: 33px;
}

@media only screen and (max-width: 767px) {
  .tab-content-mrg-top {
    margin-top: 20px;
  }
}

.single-deal-wrap {
  padding: 75px 0 75px;
}

@media only screen and (max-width: 767px) {
  .single-deal-wrap {
    padding: 60px 0 60px;
  }
}

.tab-style-8 a {
  color: #000000;
  letter-spacing: .25px;
  margin-right: 30px;
}

@media only screen and (max-width: 767px) {
  .tab-style-8 a {
    margin-right: 16px;
  }
}

.tab-style-8 a:last-child {
  margin-right: 0;
}

.tab-style-8 a.active {
  color: #999999;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-style-8.tab-res-mrg {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-style-8.tab-res-mrg {
    margin-top: 12px;
  }
  .tab-style-8.tab-res-mrg-2 {
    margin-top: 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tab-style-8.tab-res-mrg-2 {
    margin-top: 0px;
  }
}

.product-pb-80 {
  padding-bottom: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-pb-80 {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .product-pb-80 {
    padding-bottom: 20px;
  }
}

.single-sidebar-product {
  display: flex !important;
  align-items: center;
  margin-bottom: 32px;
}

.single-sidebar-product .sidebar-product-img {
  margin-right: 28px;
  flex: 0 0 30%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-sidebar-product .sidebar-product-img {
    flex: 0 0 20%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-sidebar-product .sidebar-product-img {
    flex: 0 0 20%;
  }
}

.single-sidebar-product .sidebar-product-img a {
  display: block;
}

.single-sidebar-product .sidebar-product-img a img {
  width: 100%;
}

.single-sidebar-product .sidebar-product-content h5 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 0 0 17px;
}

.single-sidebar-product .sidebar-product-content h5 a {
  color: #000000;
}

.single-sidebar-product .sidebar-product-content h5 a:hover {
  text-decoration: underline;
}

.single-sidebar-product .sidebar-product-content .sidebar-product-price span {
  font-size: 18px;
  color: #000000;
  font-family: "Heebo", sans-serif;
  display: inline-block;
  line-height: 1;
}

.single-sidebar-product .sidebar-product-content .sidebar-product-price span.new-price {
  color: #ff2f2f;
}

.single-sidebar-product .sidebar-product-content .sidebar-product-price span.old-price {
  margin-left: 10px;
  font-size: 14px;
  color: #999999;
  text-decoration: line-through;
}

.nav-style-5 > span {
  position: absolute;
  top: -62px;
  right: 0;
  font-size: 14px;
  color: #000000;
  z-index: 9;
  cursor: pointer;
  transition: all .3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .nav-style-5 > span {
    top: -63px;
  }
}

.nav-style-5 > span.sidebar-icon-prev {
  margin-right: 20px;
}

.nav-style-5 > span:hover {
  color: #6aae27;
}

.nav-style-5.nav-style-5-modify > span {
  top: -50px;
  font-size: 18px;
  color: #999;
}

.nav-style-5.nav-style-5-modify > span.sidebar-icon-prev {
  margin-right: 30px;
}

.nav-style-5.nav-style-5-modify > span:hover {
  color: #e44311;
}

@media only screen and (max-width: 767px) {
  .nav-style-5.nav-style-5-modify > span {
    display: none !important;
  }
}

.tab-style-9 {
  justify-content: center;
}

.tab-style-9 a {
  font-size: 16px;
  color: #666666;
  margin-right: 40px;
}

@media only screen and (max-width: 767px) {
  .tab-style-9 a {
    margin-right: 20px;
    margin-bottom: 5px;
  }
}

.tab-style-9 a:last-child {
  margin-right: 0;
}

.tab-style-9 a.active {
  color: #0064e0;
}

@media only screen and (max-width: 767px) {
  .tab-style-9.mb-60 {
    margin-bottom: 25px;
  }
}

.deal-bg-color {
  background-color: #eaeff2;
}

.deal-content-2 > span {
  display: inline-block;
  line-height: 1;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  border-radius: 5px;
  letter-spacing: -.25px;
  text-transform: uppercase;
  background-color: #669900;
  padding: 5px 9px 7px;
}

.deal-content-2 > h2 {
  line-height: 40px;
  font-size: 36px;
  font-weight: 300;
  color: #000000;
  letter-spacing: -.25px;
  margin: 23px 0 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-content-2 > h2 {
    line-height: 30px;
    font-size: 26px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content-2 > h2 {
    line-height: 34px;
    font-size: 27px;
    margin: 20px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .deal-content-2 > h2 {
    line-height: 33px;
    font-size: 27px;
    margin: 15px 0 0px;
  }
}

.deal-content-2 > h2 span {
  font-weight: bold;
  color: #ff2f2f;
  text-transform: uppercase;
}

.deal-content-2 p {
  width: 86%;
  margin: 0 0 31px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-content-2 p {
    width: 100%;
    margin: 0 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content-2 p {
    margin: 0 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .deal-content-2 p {
    margin: 0 0 10px;
  }
}

.deal-content-2 .deal-btn-2 a {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  text-transform: uppercase;
  border-bottom: 1px solid #000000;
}

.deal-content-2 .deal-btn-2 a:hover {
  color: #0064e0;
  border-bottom: 1px solid #0064e0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-content-2.pl-50 {
    padding-left: 30px;
  }
  .deal-content-2.pl-35 {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content-2 {
    padding-top: 50px;
  }
  .deal-content-2.pl-50 {
    padding-left: 75px;
  }
  .deal-content-2.pl-35 {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .deal-content-2.pl-50 {
    padding-left: 20px;
    padding-top: 30px;
    padding-right: 10px;
  }
  .deal-content-2.pl-35 {
    padding-left: 20px;
    padding-top: 10px;
  }
}

.deal-img {
  margin: 35px 70px 0 60px;
}

@media only screen and (max-width: 767px) {
  .deal-img {
    margin: 30px 20px 0 20px;
  }
}

.deal-img a {
  display: block;
}

.deal-img a img {
  width: 100%;
}

.more-product-btn a {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 57px;
  color: #0064e0;
  border: 1px solid #0064e0;
}

.more-product-btn a:hover {
  background-color: #0064e0;
  color: #ffffff;
}

.section-wrap-1 {
  background-color: #ffffff;
  padding: 36px 30px 34px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-wrap-1 {
    padding: 36px 20px 34px;
  }
}

@media only screen and (max-width: 767px) {
  .section-wrap-1 {
    padding: 36px 20px 34px;
  }
}

.section-wrap-3 {
  background-color: #ffffff;
}

.section-wrap-4 {
  padding: 30px;
  background-color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .section-wrap-4 {
    padding: 15px;
  }
}

.product-area-padding {
  padding: 35px 30px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-area-padding {
    padding: 20px 30px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .product-area-padding {
    padding: 30px 15px 30px 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-area-padding {
    padding: 30px 30px 30px 30px;
  }
}

.btn-style-9 a {
  display: inline-block;
  text-transform: uppercase;
  color: #666666;
}

.btn-style-9 a:hover {
  color: #e44311;
}

.tab-style-10 a {
  display: inline-block;
  margin-right: 35px;
  color: #000000;
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  .tab-style-10 a {
    margin-right: 15px;
  }
}

.tab-style-10 a:last-child {
  margin-right: 0;
}

.tab-style-10 a.active {
  font-weight: bold;
  color: #e44311;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-style-10 {
    margin: 5px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-area.pb-100 {
    padding-bottom: 40px;
  }
  .product-area.pb-75 {
    padding-bottom: 35px;
  }
  .product-area.pb-80 {
    padding-bottom: 40px;
  }
  .product-categories-area.pb-70 {
    padding-bottom: 30px;
  }
  .product-categories-area.pb-60 {
    padding-bottom: 20px;
  }
  .deal-area.pt-150 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .product-area.pb-100 {
    padding-bottom: 25px;
  }
  .product-area.pb-95 {
    padding-bottom: 15px;
  }
  .product-area.pb-75 {
    padding-bottom: 20px;
  }
  .product-area.pb-80 {
    padding-bottom: 25px;
  }
  .product-area.pb-85 {
    padding-bottom: 60px;
  }
  .product-categories-area.pb-70 {
    padding-bottom: 30px;
  }
  .product-categories-area.pb-60 {
    padding-bottom: 20px;
  }
  .deal-area.pt-150 {
    padding-top: 60px;
  }
}

.nav-style-6 > span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0%;
  transition: all 0.3s ease 0s;
  border: 1px solid #e1e2e2;
  background-color: #ffffff;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 100%;
  cursor: pointer;
  color: #000000;
  font-size: 13px;
  text-align: center;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}

.nav-style-6 > span.icon-next {
  left: auto;
  right: 0%;
}

.nav-style-6 > span:hover {
  color: #ffffff;
  border: 1px solid #ff2f2f;
  background-color: #ff2f2f;
}

.nav-style-6:hover span {
  opacity: 1;
  visibility: visible;
}

/*-------- 9. Banner style ---------*/
.banner-wrap {
  position: relative;
}

.banner-wrap.banner-mr-1 {
  margin-right: -30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap.banner-mr-1 {
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap.banner-mr-1 {
    margin-right: 0px;
  }
}

.banner-wrap.banner-ml-1 {
  margin-left: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap.banner-ml-1 {
    margin-left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap.banner-ml-1 {
    margin-left: 0px;
  }
}

.banner-wrap .banner-img {
  position: relative;
  overflow: hidden;
}

.banner-wrap .banner-img a {
  display: block;
}

.banner-wrap .banner-img a img {
  width: 100%;
}

.banner-wrap .banner-img.banner-img-border {
  border: 1px solid #d9d9d9;
}

.banner-wrap .banner-img-zoom a {
  display: block;
}

.banner-wrap .banner-img-zoom a img {
  width: 100%;
  transform: scale(1);
  transition: all .5s ease 0s;
}

.banner-wrap .banner-content-1 {
  position: absolute;
  left: 70px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-1 {
    left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-1 {
    left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-1 {
    left: 20px;
  }
}

.banner-wrap .banner-content-1 h2 {
  font-size: 36px;
  font-weight: bold;
  font-family: "Heebo", sans-serif;
  line-height: 40px;
  letter-spacing: -.25px;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-1 h2 {
    font-size: 34px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-1 h2 {
    font-size: 27px;
    line-height: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-1 h2 {
    font-size: 22px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-1 h2 {
    font-size: 25px;
    line-height: 32px;
  }
}

.banner-wrap .banner-content-1 p {
  width: 75%;
  margin: 28px 0 200px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-1 p {
    margin: 28px 0 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-1 p {
    margin: 12px 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-1 p {
    margin: 8px 0 21px;
  }
}

.banner-wrap .banner-content-2 {
  position: absolute;
  left: 70px;
  top: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-2 {
    left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-2 {
    left: 20px;
    top: 62px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 {
    left: 20px;
    top: 62px;
  }
}

.banner-wrap .banner-content-2 h2 {
  font-size: 36px;
  font-weight: bold;
  font-family: "Heebo", sans-serif;
  line-height: 40px;
  letter-spacing: -.25px;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-2 h2 {
    font-size: 34px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-2 h2 {
    font-size: 27px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 h2 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-2 h2 {
    font-size: 25px;
  }
}

.banner-wrap .banner-content-2 p {
  width: 75%;
  margin: 28px 0 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-2 p {
    width: 80%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-2 p {
    width: 95%;
    margin: 10px 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 p {
    width: 95%;
    margin: 10px 0 0px;
  }
}

.banner-wrap .banner-content-3 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3 {
    left: 15px;
  }
}

.banner-wrap .banner-content-3 h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
  letter-spacing: -.25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-3 h2 {
    font-size: 20px;
    line-height: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3 h2 {
    font-size: 20px;
    line-height: 26px;
  }
}

.banner-wrap .banner-content-3 span {
  font-size: 12px;
  color: #666666;
  display: block;
  margin: 17px 0 2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-3 span {
    margin: 10px 0 2px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-3 span {
    margin: 10px 0 2px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3 span {
    margin: 6px 0 2px;
  }
}

.banner-wrap .banner-content-3 h3 {
  font-size: 18px;
  color: #df3434;
  font-weight: 600;
  margin: 0 0 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-3 h3 {
    margin: 0 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-3 h3 {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3 h3 {
    margin: 0 0 12px;
  }
}

.banner-wrap .banner-content-4 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-4 {
    left: 15px;
  }
}

.banner-wrap .banner-content-4 h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
  letter-spacing: -.25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-4 h2 {
    font-size: 20px;
    line-height: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-4 h2 {
    font-size: 20px;
    line-height: 26px;
  }
}

.banner-wrap .banner-content-4 h2 span {
  color: #df3434;
}

.banner-wrap .banner-content-4 > span {
  font-size: 12px;
  color: #666666;
  display: block;
  text-transform: uppercase;
  margin: 17px 0 2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-4 > span {
    margin: 10px 0 2px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-4 > span {
    margin: 10px 0 2px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-4 > span {
    margin: 6px 0 2px;
  }
}

.banner-wrap .banner-content-4 h3 {
  font-size: 18px;
  color: #669900;
  font-weight: 600;
  margin: 0 0 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-4 h3 {
    margin: 0 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-4 h3 {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-4 h3 {
    margin: 0 0 12px;
  }
}

.banner-wrap .banner-content-5 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-5 {
    left: 15px;
  }
}

.banner-wrap .banner-content-5 span {
  font-size: 12px;
  font-weight: 600;
  color: #fea802;
  text-transform: uppercase;
  margin: 0 0 11px;
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-5 span {
    margin: 0 0 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-5 span {
    margin: 0 0 5px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-5 span {
    margin: 0 0 5px;
  }
}

.banner-wrap .banner-content-5 h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
  letter-spacing: -.25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-5 h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-5 h2 {
    font-size: 20px;
  }
}

.banner-wrap .banner-content-5 h2 span {
  color: #df3434;
}

.banner-wrap .banner-content-5 p {
  color: #999999;
  width: 70%;
  margin: 13px 0 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-5 p {
    margin: 5px 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-5 p {
    margin: 5px 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-5 p {
    margin: 2px 0 4px;
  }
}

.banner-wrap .banner-content-6 {
  position: absolute;
  left: 56%;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-6 {
    left: 50%;
  }
}

.banner-wrap .banner-content-6 h3 {
  font-size: 30px;
  color: #222222;
  line-height: 36px;
  margin: 0 0 22px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-6 h3 {
    font-size: 25px;
    line-height: 32px;
    margin: 0 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-6 h3 {
    font-size: 17px;
    line-height: 24px;
    margin: 0 0 3px;
  }
}

.banner-wrap .banner-content-6 .banner-btn-1 a {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #4dbdaf;
}

.banner-wrap .banner-content-6 .banner-btn-1 a i {
  font-size: 12px;
  margin: 0 0 0 3px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-6 .banner-btn-1 a {
    font-size: 13px;
  }
}

.banner-wrap .banner-content-6 .banner-btn-1 a:hover {
  color: #000000;
}

.banner-wrap .banner-content-7-8-wrap {
  position: absolute;
  left: 37px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-7-8-wrap {
    left: 15px;
  }
}

.banner-wrap .banner-content-7-8-wrap .banner-content-7 {
  margin-right: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-7-8-wrap .banner-content-7 {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-7-8-wrap .banner-content-7 {
    margin-right: 15px;
  }
}

.banner-wrap .banner-content-7-8-wrap .banner-content-7 span {
  font-size: 12px;
  color: #222222;
  font-family: "Heebo", sans-serif;
}

.banner-wrap .banner-content-7-8-wrap .banner-content-7 h2 {
  font-size: 36px;
  color: #222222;
  font-weight: 600;
  margin: 3px 0 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-7-8-wrap .banner-content-7 h2 {
    font-size: 27px;
    margin: 3px 0 8px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-7-8-wrap .banner-content-7 h2 {
    font-size: 16px;
    margin: 1px 0 3px;
    line-height: 1;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-7-8-wrap .banner-content-7 h2 {
    font-size: 20px;
    margin: 5px 0 10px;
  }
}

.banner-wrap .banner-content-7-8-wrap .banner-content-7 p {
  font-size: 14px;
  color: #999999;
  font-family: "Heebo", sans-serif;
  line-height: 22px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-7-8-wrap .banner-content-7 p {
    line-height: 19px;
  }
}

.banner-wrap .banner-content-7-8-wrap .banner-content-8 > span {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-7-8-wrap .banner-content-8 > span {
    font-size: 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-7-8-wrap .banner-content-8 > span {
    font-size: 12px;
  }
}

.banner-wrap .banner-content-7-8-wrap .banner-content-8 h3 {
  font-weight: bold;
  font-size: 36px;
  color: #df3434;
  font-family: "Heebo", sans-serif;
  margin: 4px 0 15px;
  text-transform: uppercase;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-7-8-wrap .banner-content-8 h3 {
    font-size: 32px;
    margin: 4px 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-7-8-wrap .banner-content-8 h3 {
    font-size: 22px;
    margin: 2px 0 4px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-7-8-wrap .banner-content-8 h3 {
    font-size: 26px;
    margin: 5px 0 10px;
  }
}

.banner-wrap .banner-content-7-8-wrap .banner-content-8 h3 span {
  font-size: 18px;
}

.banner-wrap .banner-content-7-8-wrap .banner-content-8 .banner-btn-2 a {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  padding: 6px 15px 6px 17px;
  text-transform: uppercase;
  background-color: #4dbdaf;
}

.banner-wrap .banner-content-7-8-wrap .banner-content-8 .banner-btn-2 a i {
  font-size: 10px;
  margin: 0 0 0 3px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-7-8-wrap .banner-content-8 .banner-btn-2 a {
    padding: 2px 10px 3px 12px;
  }
}

.banner-wrap .banner-content-7-8-wrap .banner-content-8 .banner-btn-2 a:hover {
  background-color: #000000;
}

.banner-wrap .banner-content-9 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 75px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-9 {
    left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-9 {
    left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-9 {
    left: 20px;
  }
}

.banner-wrap .banner-content-9 span {
  font-size: 14px;
  color: #999999;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
  margin: 0 0 220px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-9 span {
    margin: 0 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-9 span {
    margin: 0 0 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-9 span {
    margin: 0 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-9 span {
    margin: 0 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-9 span {
    margin: 0 0 6px;
    line-height: 16px;
  }
}

.banner-wrap .banner-content-9 h2 {
  text-transform: uppercase;
  font-size: 48px;
  line-height: 48px;
  letter-spacing: -.25px;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-9 h2 {
    font-size: 34px;
    line-height: 38px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-9 h2 {
    font-size: 22px;
    line-height: 27px;
  }
}

.banner-wrap .banner-content-9 p {
  width: 68%;
  margin: 20px 0 48px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-9 p {
    margin: 10px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-9 p {
    margin: 5px 0 6px;
    line-height: 20px;
    width: 85%;
  }
}

.banner-wrap .banner-content-10 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 75px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-10 {
    left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-10 {
    left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-10 {
    left: 20px;
  }
}

.banner-wrap .banner-content-10 > span {
  font-size: 14px;
  color: #999999;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
  margin: 0 0 220px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-10 > span {
    margin: 0 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-10 > span {
    margin: 0 0 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-10 > span {
    margin: 0 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-10 > span {
    margin: 0 0 40px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-10 > span {
    margin: 0 0 20px;
  }
}

.banner-wrap .banner-content-10 h2 {
  font-size: 48px;
  line-height: 48px;
  letter-spacing: -.25px;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-10 h2 {
    font-size: 34px;
    line-height: 38px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-10 h2 {
    font-size: 21px;
    line-height: 25px;
  }
}

.banner-wrap .banner-content-10 h2 span {
  font-weight: 600;
  color: #ff2f2f;
}

.banner-wrap .banner-content-10 p {
  width: 68%;
  margin: 25px 0 53px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-10 p {
    margin: 10px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-10 p {
    margin: 7px 0 10px;
  }
}

.banner-wrap .banner-content-11 {
  position: absolute;
  left: 66px;
  top: 50%;
  transform: translateY(-50%);
}

.banner-wrap .banner-content-11.banner-content-11-modify {
  left: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-11.banner-content-11-modify {
    left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-11.banner-content-11-modify {
    left: 20px;
  }
}

.banner-wrap .banner-content-11.banner-content-11-modify-2 h2 {
  font-size: 36px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-11.banner-content-11-modify-2 h2 {
    font-size: 25px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-11.banner-content-11-modify-2 h2 {
    font-size: 16px;
    line-height: 17px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-11.banner-content-11-modify-2 h2 {
    font-size: 21px;
    line-height: 25px;
  }
}

.banner-wrap .banner-content-11.banner-content-11-modify-2 p {
  margin: 15px 0 0px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-11.banner-content-11-modify-2 p {
    margin: 7px 0 0px;
    line-height: 1;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-11.banner-content-11-modify-2 p {
    margin: 15px 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-11 {
    left: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-11 {
    left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-11 {
    left: 20px;
  }
}

.banner-wrap .banner-content-11 h2 {
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -.25px;
  color: #000000;
  font-weight: 300;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-11 h2 {
    font-size: 22px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-11 h2 {
    font-size: 22px;
    line-height: 25px;
  }
}

.banner-wrap .banner-content-11 h2 span {
  font-weight: 600;
}

.banner-wrap .banner-content-11 p {
  margin: 15px 0 50px;
}

.banner-wrap .banner-content-11 p.mrg-inc {
  margin: 15px 0 90px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-11 p.mrg-inc {
    margin: 15px 0 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-11 p.mrg-inc {
    margin: 10px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-11 p.mrg-inc {
    margin: 4px 0 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-11 p.mrg-inc {
    margin: 14px 0 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-11 p {
    margin: 10px 0 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-11 p {
    margin: 6px 0 7px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-11 p {
    margin: 6px 0 7px;
  }
}

.banner-wrap .banner-content-12 {
  position: absolute;
  left: 70px;
  top: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-12 {
    left: 30px;
    top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-12 {
    left: 30px;
    top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-12 {
    left: 15px;
    top: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-12 {
    left: 30px;
    top: 30px;
  }
}

.banner-wrap .banner-content-12 h2 {
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -.25px;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-12 h2 {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-12 h2 {
    font-size: 25px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-12 h2 {
    font-size: 18px;
    line-height: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-12 h2 {
    font-size: 22px;
    line-height: 27px;
  }
}

.banner-wrap .banner-content-12 p {
  margin: 25px 0 48px;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-12 p {
    margin: 25px 0 35px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-12 p {
    margin: 25px 0 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-12 p {
    margin: 15px 0 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-12 p {
    margin: 8px 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-12 p {
    margin: 5px 0 5px;
    line-height: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-12 p {
    margin: 10px 0 12px;
    line-height: 24px;
  }
}

.banner-wrap .banner-content-12 span {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .5px;
}

.banner-wrap .banner-content-12 h3 {
  font-size: 48px;
  color: #669900;
  letter-spacing: -.25px;
  margin: 6px 0 81px;
  line-height: 1;
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-wrap .banner-content-12 h3 {
    margin: 6px 0 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-12 h3 {
    margin: 6px 0 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-12 h3 {
    margin: 6px 0 20px;
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-12 h3 {
    margin: 6px 0 14px;
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-12 h3 {
    margin: 3px 0 7px;
    font-size: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-12 h3 {
    margin: 14px 0 20px;
    font-size: 25px;
  }
}

.banner-wrap .banner-content-13 {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-13 {
    left: 15px;
  }
}

.banner-wrap .banner-content-13 > span {
  font-size: 14px;
  color: #8d75ba;
  text-transform: uppercase;
  display: block;
  line-height: 1;
}

.banner-wrap .banner-content-13 h2 {
  font-size: 36px;
  color: #6f50a7;
  line-height: 32px;
  font-weight: 800;
  text-transform: uppercase;
  margin: 24px 0 27px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-13 h2 {
    font-size: 28px;
    margin: 10px 0 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-13 h2 {
    font-size: 28px;
    margin: 15px 0 18px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-13 h2 {
    font-size: 25px;
    line-height: 28px;
    margin: 10px 0 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-13 h2 {
    font-size: 32px;
    line-height: 35px;
    margin: 20px 0 23px;
  }
}

.banner-wrap .banner-content-13 .product-available-wrap {
  display: flex;
  flex-wrap: wrap;
}

.banner-wrap .banner-content-13 .product-available-wrap .single-product-available {
  text-align: center;
  margin-right: 25px;
}

.banner-wrap .banner-content-13 .product-available-wrap .single-product-available:Last-child {
  margin-right: 0;
}

.banner-wrap .banner-content-13 .product-available-wrap .single-product-available h3 {
  font-size: 30px;
  color: #58a980;
  line-height: 23px;
  margin: 0 0 6px;
}

.banner-wrap .banner-content-13 .product-available-wrap .single-product-available span {
  display: block;
  line-height: 1;
  color: #666;
}

.banner-wrap .banner-content-14 {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-14 {
    left: 20px;
  }
}

.banner-wrap .banner-content-14 span {
  display: inline-block;
  font-weight: 800;
  color: #ffffff;
  text-transform: uppercase;
  background-color: #94b84c;
  line-height: 1;
  padding: 5px 8px;
}

.banner-wrap .banner-content-14 h2 {
  font-size: 30px;
  color: #010101;
  line-height: 24px;
  margin: 19px 0 47px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-14 h2 {
    font-size: 27px;
    line-height: 28px;
    margin: 10px 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-14 h2 {
    margin: 19px 0 27px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-14 h2 {
    font-size: 25px;
    line-height: 24px;
    margin: 12px 0 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-14 h2 {
    font-size: 30px;
    line-height: 30px;
    margin: 20px 0 25px;
  }
}

.banner-wrap .banner-content-14 p {
  line-height: 18px;
  width: 60%;
}

.banner-wrap .banner-content-15 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
}

.banner-wrap .banner-content-15 .banner-content-15-top {
  display: flex;
  align-items: center;
  margin: 0 0 16px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-15 .banner-content-15-top {
    margin: 0 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-15 .banner-content-15-top {
    margin: 0 0 10px;
  }
}

.banner-wrap .banner-content-15 .banner-content-15-top .banner-content-15-brand {
  margin-right: 60px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-15 .banner-content-15-top .banner-content-15-brand {
    margin-right: 30px;
  }
}

.banner-wrap .banner-content-15 .banner-content-15-top .banner-content-15-brand span {
  color: #ffffff;
  font-weight: 800;
  font-size: 14px;
  font-style: italic;
  text-transform: uppercase;
  display: inline-block;
  line-height: 1;
  letter-spacing: -.25px;
  background-color: #4c96b8;
  padding: 5px 6px 5px;
}

.banner-wrap .banner-content-15 .banner-content-15-top p {
  line-height: 18px;
  letter-spacing: -.25px;
}

.banner-wrap .banner-content-15 h2 {
  font-size: 30px;
  color: #010101;
  line-height: 30px;
  margin: 0;
  width: 80%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-15 h2 {
    font-size: 25px;
    line-height: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-15 h2 {
    font-size: 24px;
    line-height: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-15 h2 {
    font-size: 23px;
    line-height: 26px;
  }
}

.banner-wrap .banner-content-16 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-16 {
    left: 20px;
  }
}

.banner-wrap .banner-content-16 h2 {
  font-size: 24px;
  color: #000000;
  font-weight: 300;
  line-height: 30px;
  margin: 0 0 20px;
  width: 64%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-16 h2 {
    font-size: 19px;
    line-height: 24px;
    margin: 0 0 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-16 h2 {
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-16 h2 {
    font-size: 17px;
    line-height: 22px;
    margin: 0 0 8px;
  }
}

.banner-wrap .banner-content-16 .banner-price {
  width: 22%;
}

.banner-wrap .banner-content-16 .banner-price span {
  font-size: 16px;
  font-weight: bold;
  color: #ff2f2f;
}

.banner-wrap .banner-content-16 .banner-price span.old-price {
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  margin-right: 5px;
  text-decoration: line-through;
}

.banner-wrap .banner-content-17 {
  position: absolute;
  top: 30px;
  left: 30px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-17 {
    left: 15px;
  }
}

.banner-wrap .banner-content-17 h2 {
  font-size: 24px;
  color: #000000;
  font-weight: 300;
  line-height: 30px;
  margin: 0 0 20px;
  width: 67%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-17 h2 {
    font-size: 20px;
    width: 73%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-17 h2 {
    font-size: 22px;
    width: 73%;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-17 h2 {
    font-size: 20px;
    margin: 0 0 10px;
    width: 70%;
  }
}

.banner-wrap .banner-content-17 p {
  font-size: 12px;
  margin: 0 0 2px;
}

.banner-wrap .banner-content-17 .banner-price span {
  line-height: 1;
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: #ff2f2f;
}

.banner-wrap .banner-content-17 .banner-price span.old-price {
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  margin-right: 5px;
  text-decoration: line-through;
}

.banner-wrap .banner-content-18 {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0 20px;
}

.banner-wrap .banner-content-18 > span {
  color: #999999;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.banner-wrap .banner-content-18 h2 {
  font-size: 30px;
  line-height: 30px;
  letter-spacing: -.25px;
  margin: 16px 0 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-18 h2 {
    font-size: 25px;
    line-height: 28px;
    margin: 10px 0 10px;
  }
}

.banner-wrap .banner-content-18 h2 span {
  font-weight: 600;
  color: #ff2f2f;
}

.banner-wrap .banner-content-19 {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-19 {
    left: 20px;
  }
}

.banner-wrap .banner-content-19 h2 {
  font-size: 24px;
  line-height: 30px;
  margin: 0;
  text-transform: uppercase;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-19 h2 {
    font-size: 20px;
    line-height: 24px;
  }
}

.banner-wrap .banner-content-19 h2.capitalize {
  font-weight: 300;
  text-transform: capitalize;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-19 h2 {
    font-size: 20px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-19 h2 {
    font-size: 20px;
    line-height: 22px;
  }
}

.banner-wrap .banner-content-19 span {
  font-size: 13px;
  color: #999999;
  display: block;
  margin: 17px 0 3px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-19 span {
    margin: 10px 0 3px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-19 span {
    margin: 7px 0 3px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-19 span {
    margin: 5px 0 1px;
  }
}

.banner-wrap .banner-content-19 h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #ff2f2f;
}

.banner-wrap .banner-content-20 {
  position: absolute;
  left: 0;
  right: 0;
  top: 40px;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-20 {
    top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-20 {
    top: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-20 {
    top: 40px;
  }
}

.banner-wrap .banner-content-20 .banner-content-20-top {
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-wrap .banner-content-20 .banner-content-20-top .banner-content-20-brand {
  margin-right: 18px;
}

.banner-wrap .banner-content-20 .banner-content-20-top .banner-content-20-brand > span {
  color: #ffffff;
  font-weight: 800;
  font-size: 14px;
  font-style: italic;
  text-transform: uppercase;
  display: inline-block;
  line-height: 1;
  letter-spacing: -.25px;
  background-color: #4c96b8;
  padding: 5px 6px 5px;
}

.banner-wrap .banner-content-20 .banner-content-20-top p {
  line-height: 18px;
  letter-spacing: -.25px;
  text-align: left;
}

.banner-wrap .banner-content-20 h2 {
  color: #010101;
  letter-spacing: -.25px;
  margin: 14px 0 0;
  font-size: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-20 h2 {
    font-size: 24px;
    margin: 10px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-20 h2 {
    font-size: 24px;
    margin: 10px 0 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-20 h2 {
    font-size: 28px;
    margin: 10px 0 0;
  }
}

.banner-wrap .banner-content-21 {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-21 {
    left: 20px;
  }
}

.banner-wrap .banner-content-21 > span {
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  line-height: 1;
  display: inline-block;
  text-transform: uppercase;
  background-color: #000000;
  padding: 6px 9px 7px;
  border-radius: 5px;
}

.banner-wrap .banner-content-21 h2 {
  font-size: 30px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: -.25px;
  margin: 21px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-21 h2 {
    font-size: 25px;
    line-height: 30px;
    margin: 14px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-21 h2 {
    font-size: 22px;
    line-height: 25px;
    margin: 12px 0 0;
  }
}

.banner-wrap .banner-content-21 h2 span {
  font-weight: bold;
  color: #ff2f2f;
}

.banner-wrap .banner-content-22 {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-22 {
    left: 20px;
  }
}

.banner-wrap .banner-content-22 > span {
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 1;
  display: inline-block;
  text-transform: uppercase;
  background-color: #b8a04c;
  padding: 4px 7px 5px;
  font-style: italic;
}

.banner-wrap .banner-content-22 h2 {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -.5px;
  margin: 5px 0 13px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-22 h2 {
    font-size: 18px;
    line-height: 25px;
    margin: 3px 0 6px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-22 h2 {
    font-size: 20px;
    line-height: 27px;
    margin: 3px 0 6px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-22 h2 {
    font-size: 18px;
    line-height: 25px;
    margin: 2px 0 3px;
  }
}

.banner-wrap .banner-content-22 p {
  color: #999;
}

.banner-wrap .banner-content-23 {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
}

.banner-wrap .banner-content-23 .banner-content-23-top {
  display: flex;
  justify-content: center;
  text-align: left;
}

.banner-wrap .banner-content-23 .banner-content-23-top > p {
  color: #999;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -.25px;
  line-height: 18px;
  margin: 0 30px 0 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-23 .banner-content-23-top > p {
    margin: 0 20px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-23 .banner-content-23-top > p {
    margin: 0 20px 0 0;
  }
}

.banner-wrap .banner-content-23 .banner-content-23-top h2 {
  font-size: 30px;
  line-height: 30px;
  letter-spacing: -.25px;
  margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-23 .banner-content-23-top h2 {
    font-size: 24px;
    line-height: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-23 .banner-content-23-top h2 {
    font-size: 22px;
    line-height: 25px;
  }
}

.banner-wrap .banner-content-23 .banner-content-23-top h2 span {
  color: #ff2f2f;
  font-weight: 600;
}

.banner-wrap .banner-content-23 .banner-btn-3 {
  margin: 30px 0 0;
}

.banner-wrap .banner-content-23 .banner-btn-3 a {
  display: inline-block;
  font-weight: 600;
  color: #ffffff;
  line-height: 1;
  background-color: #e44311;
  padding: 14px 86px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-23 .banner-btn-3 a {
    padding: 14px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-23 .banner-btn-3 a {
    padding: 14px 50px;
  }
}

.banner-wrap .banner-content-23 .banner-btn-3 a:hover {
  background-color: #000000;
}

.banner-wrap .banner-content-24 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}

.banner-wrap .banner-content-24 span {
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.banner-wrap .banner-content-24 h2 {
  font-weight: bold;
  color: #ffffff;
  font-family: "Crimson Text", serif;
  font-size: 40px;
  line-height: 36px;
  letter-spacing: -.25px;
  margin: 17px 0 14px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-24 h2 {
    font-size: 33px;
    line-height: 33px;
    margin: 10px 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-24 h2 {
    font-size: 33px;
    line-height: 33px;
    margin: 10px 0 10px;
  }
}

.banner-wrap .banner-content-24 p {
  color: #ffffff;
  margin: 0 0 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-24 p {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-24 p {
    margin: 0 0 20px;
  }
}

.banner-wrap .banner-content-24 .banner-btn-4 a {
  font-weight: 600;
  color: #e44311;
  display: inline-block;
  background-color: #ffffff;
  line-height: 1;
  padding: 13px 30px 17px;
}

.banner-wrap .banner-content-24 .banner-btn-4 a:hover {
  color: #ffffff;
  background-color: #e44311;
}

.banner-wrap .banner-content-25 {
  position: absolute;
  bottom: 55px;
  left: 0;
  right: 0;
}

.banner-wrap .banner-content-25 span {
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.banner-wrap .banner-content-25 h2 {
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -.25px;
  margin: 5px 0 25px;
}

@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-25 h2 {
    font-size: 20px;
    line-height: 25px;
    margin: 1px 0 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-25 h2 {
    font-size: 30px;
    line-height: 40px;
    margin: 5px 0 25px;
  }
}

.banner-wrap .banner-content-25 .banner-btn-5 a {
  font-weight: 600;
  color: #ffffff;
  display: inline-block;
  background-color: #e44311;
  line-height: 1;
  padding: 13px 30px 17px;
}

.banner-wrap .banner-content-25 .banner-btn-5 a:hover {
  color: #e44311;
  background-color: #ffffff;
}

.banner-wrap.banner-border-1 {
  border: 1px solid #d9d9d9;
}

.banner-wrap:hover .banner-img-zoom a img {
  transform: scale(1.1);
}

@media only screen and (max-width: 767px) {
  .banner-wrap.banner-mt-none-xs.mt-40 {
    margin-top: 0px;
  }
}

.btn-style-4 a {
  font-weight: bold;
  display: inline-block;
  text-transform: uppercase;
  color: #000000;
}

.btn-style-4 a i {
  font-size: 12px;
  margin: 0 0 0 5px;
}

.btn-style-4 a.hover-red:hover {
  color: #ff2f2f;
}

.btn-style-4 a:hover {
  color: #4dbdaf;
}

.banner-wrap-mrg-1 {
  margin-right: 34px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap-mrg-1 {
    margin-right: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap-mrg-1 {
    margin-right: 19px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap-mrg-1 {
    margin-right: 0px;
  }
}

.banner-wrap-mrg-2 {
  margin-left: -34px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap-mrg-2 {
    margin-left: -25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap-mrg-2 {
    margin-left: -19px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-wrap-mrg-2 {
    margin-left: 0px;
  }
}

.section-wrap-2 {
  background-color: #ffffff;
  padding: 30px 30px 0px 30px;
}

@media only screen and (max-width: 767px) {
  .section-wrap-2 {
    padding: 30px 10px 0px 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-wrap-2 {
    padding: 30px 30px 0px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area.pb-85 {
    padding-bottom: 45px;
  }
  .banner-area.pb-90 {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-area.pb-85 {
    padding-bottom: 25px;
  }
  .banner-area.pb-90 {
    padding-bottom: 30px;
  }
}

/*-------- 10. Brand logo style ---------*/
.brand-logo-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-logo-wrap {
    justify-content: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .brand-logo-wrap {
    justify-content: inherit;
  }
}

.brand-logo-wrap.brand-logo-mrg {
  margin: 0 -40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .brand-logo-wrap.brand-logo-mrg {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .brand-logo-wrap.brand-logo-mrg {
    margin: 0 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-logo-wrap.brand-logo-mrg {
    margin: 0 0px;
  }
}

@media only screen and (max-width: 767px) {
  .brand-logo-wrap.brand-logo-mrg {
    margin: 0 0px;
  }
}

.brand-logo-wrap .single-brand-logo {
  flex: 0 0 20%;
  max-width: 20%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-logo-wrap .single-brand-logo {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .brand-logo-wrap .single-brand-logo {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .brand-logo-wrap .single-brand-logo {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

.brand-logo-wrap .single-brand-logo img {
  width: 100%;
  opacity: .45;
  transform: scale(1);
  transition: all .5s ease 0s;
}

.brand-logo-wrap .single-brand-logo:hover img {
  opacity: 1;
  transform: scale(1.1);
}

.brand-logo-wrap-2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.brand-logo-wrap-2 .single-brand-logo-2 {
  flex: 0 0 16.63%;
  max-width: 16.63%;
  text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-logo-wrap-2 .single-brand-logo-2 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

@media only screen and (max-width: 767px) {
  .brand-logo-wrap-2 .single-brand-logo-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .brand-logo-wrap-2 .single-brand-logo-2 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}

.brand-logo-wrap-2 .single-brand-logo-2:first-child {
  text-align: left;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-logo-wrap-2 .single-brand-logo-2:first-child {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .brand-logo-wrap-2 .single-brand-logo-2:first-child {
    text-align: center;
  }
}

.brand-logo-wrap-2 .single-brand-logo-2:last-child {
  text-align: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-logo-wrap-2 .single-brand-logo-2:last-child {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .brand-logo-wrap-2 .single-brand-logo-2:last-child {
    text-align: center;
  }
}

.brand-logo-wrap-2 .single-brand-logo-2 img {
  max-width: 100%;
  opacity: .45;
  transform: scale(1);
  transition: all .5s ease 0s;
}

.brand-logo-wrap-2 .single-brand-logo-2:hover img {
  opacity: 1;
  transform: scale(1.1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-logo-area.pt-100 {
    padding-top: 50px;
  }
  .brand-logo-area.pb-100 {
    padding-bottom: 45px;
  }
  .brand-logo-area.pb-80 {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .brand-logo-area.pt-100 {
    padding-top: 40px;
  }
  .brand-logo-area.pb-95 {
    padding-bottom: 25px;
  }
  .brand-logo-area.pb-100 {
    padding-bottom: 35px;
  }
  .brand-logo-area.pb-80 {
    padding-bottom: 30px;
  }
}

/*-------- 11. Subscribe style ---------*/
.subscribe-form .subscribe-form-style {
  position: relative;
}

.subscribe-form .subscribe-form-style .mc-form {
  position: relative;
}

.subscribe-form .subscribe-form-style .mc-form::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  font-family: 'simple-line-icons';
  content: "\e01e";
  z-index: 999;
  color: #000000;
}

.subscribe-form .subscribe-form-style .mc-form input {
  color: #999999;
  padding: 2px 115px 2px 30px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #999999;
  height: 55px;
}

.subscribe-form .subscribe-form-style .mc-form input::-moz-input-placeholder {
  color: #999999;
  opacity: 1;
}

.subscribe-form .subscribe-form-style .mc-form input::-webkit-input-placeholder {
  color: #999999;
  opacity: 1;
}

.subscribe-form .subscribe-form-style .mc-form .mc-news {
  display: none;
}

.subscribe-form .subscribe-form-style .mc-form .clear {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.subscribe-form .subscribe-form-style .mc-form .clear input {
  width: auto;
  border: none;
  font-weight: bold;
  color: #000000;
  font-size: 16px;
  font-family: "Heebo", sans-serif;
  text-transform: uppercase;
  letter-spacing: .75px;
  padding: 0;
}

.subscribe-form .subscribe-form-style .mc-form .clear input:hover {
  color: #ff2f2f;
}

@media only screen and (max-width: 767px) {
  .subscribe-form {
    margin-top: 15px;
  }
}

.subscribe-form-2 .subscribe-form-style-2 {
  position: relative;
}

.subscribe-form-2 .subscribe-form-style-2 .mc-form-2 {
  position: relative;
}

.subscribe-form-2 .subscribe-form-style-2 .mc-form-2::before {
  position: absolute;
  left: 22px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  font-family: 'simple-line-icons';
  content: "\e01e";
  z-index: 999;
  color: #000000;
}

.subscribe-form-2 .subscribe-form-style-2 .mc-form-2 input {
  color: #bbbbbb;
  padding: 2px 165px 2px 60px;
  background-color: #ffffff;
  border: none;
  height: 55px;
}

@media only screen and (max-width: 767px) {
  .subscribe-form-2 .subscribe-form-style-2 .mc-form-2 input {
    padding: 2px 113px 2px 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .subscribe-form-2 .subscribe-form-style-2 .mc-form-2 input {
    padding: 2px 135px 2px 60px;
  }
}

.subscribe-form-2 .subscribe-form-style-2 .mc-form-2 input::-moz-input-placeholder {
  color: #bbbbbb;
  opacity: 1;
}

.subscribe-form-2 .subscribe-form-style-2 .mc-form-2 input::-webkit-input-placeholder {
  color: #bbbbbb;
  opacity: 1;
}

.subscribe-form-2 .subscribe-form-style-2 .mc-form-2.mc-form-2-bg-gray input {
  background-color: #f5f5f5;
}

.subscribe-form-2 .subscribe-form-style-2 .mc-form-2 .mc-news-2 {
  display: none;
}

.subscribe-form-2 .subscribe-form-style-2 .mc-form-2 .clear-2 {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.subscribe-form-2 .subscribe-form-style-2 .mc-form-2 .clear-2 input {
  width: auto;
  border: none;
  font-weight: bold;
  color: #000000;
  font-size: 16px;
  font-family: "Heebo", sans-serif;
  text-transform: uppercase;
  letter-spacing: .75px;
  padding: 0 30px;
  color: #ffffff;
  background-color: #4dbdaf;
}

@media only screen and (max-width: 767px) {
  .subscribe-form-2 .subscribe-form-style-2 .mc-form-2 .clear-2 input {
    padding: 0 10px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .subscribe-form-2 .subscribe-form-style-2 .mc-form-2 .clear-2 input {
    padding: 0 20px;
  }
}

.subscribe-form-2 .subscribe-form-style-2 .mc-form-2 .clear-2 input:hover {
  background-color: #000000;
}

.subscribe-form-2 .subscribe-form-style-2 .mc-form-2 .clear-2.clear-2-purple input {
  background-color: #6f50a7;
}

.subscribe-form-2 .subscribe-form-style-2 .mc-form-2 .clear-2.clear-2-purple input:hover {
  background-color: #000000;
}

.subscribe-form-2 .subscribe-form-style-2 .mc-form-2 .clear-2.clear-2-blue input {
  background-color: #0064e0;
}

.subscribe-form-2 .subscribe-form-style-2 .mc-form-2 .clear-2.clear-2-blue input:hover {
  background-color: #000000;
}

.subscribe-form-2 .subscribe-form-style-2 .mc-form-2 .clear-2.clear-2-orange input {
  background-color: #e44311;
}

.subscribe-form-2 .subscribe-form-style-2 .mc-form-2 .clear-2.clear-2-orange input:hover {
  background-color: #000000;
}

.subscribe-form-2 .subscribe-form-style-2 .mc-form-2 .clear-2.clear-2-green-2 {
  right: -1px;
}

.subscribe-form-2 .subscribe-form-style-2 .mc-form-2 .clear-2.clear-2-green-2 input {
  background-color: #468707;
  min-height: 56px;
}

.subscribe-form-2 .subscribe-form-style-2 .mc-form-2 .clear-2.clear-2-green-2 input:hover {
  background-color: #000000;
}

@media only screen and (max-width: 767px) {
  .subscribe-form-2 {
    margin-top: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-area.pt-95 {
    padding-top: 75px;
  }
  .subscribe-area.pb-95 {
    padding-bottom: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-area.pt-95 {
    padding-top: 55px;
  }
  .subscribe-area.pb-95 {
    padding-bottom: 55px;
  }
}

/*-------- 12. Footer style ---------*/
.contact-info-wrap .footer-logo {
  margin: 0 0 52px;
}

@media only screen and (max-width: 767px) {
  .contact-info-wrap .footer-logo {
    margin: 0 0 30px;
  }
}

.contact-info-wrap .footer-logo a {
  display: block;
}

.contact-info-wrap .footer-logo a img {
  max-width: 100%;
}

.contact-info-wrap .single-contact-info {
  margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .contact-info-wrap .single-contact-info {
    margin: 0 0 20px;
  }
}

.contact-info-wrap .single-contact-info span {
  font-size: 13px;
  color: #999999;
  font-family: "Jost", sans-serif;
  text-transform: uppercase;
  letter-spacing: .5px;
  display: block;
}

.contact-info-wrap .single-contact-info p {
  font-size: 18px;
  color: #000000;
  font-family: "Cerebri Sans";
  margin: 6px 0 0;
}

.footer-right-wrap {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .footer-right-wrap {
    text-align: left;
  }
}

.footer-right-wrap .social-style-2.social-style-2-mrg {
  margin: 37px 0 86px;
}

@media only screen and (max-width: 767px) {
  .footer-right-wrap .social-style-2.social-style-2-mrg {
    margin: 23px 0 25px;
  }
}

.footer-right-wrap .social-style-2 a {
  display: inline-block;
  font-size: 16px;
  color: #999999;
  border: 1px solid #cfcfcf;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;
  margin-right: 8px;
}

.footer-right-wrap .social-style-2 a i {
  line-height: 40px;
}

.footer-right-wrap .social-style-2 a:last-child {
  margin-right: 0;
}

.footer-right-wrap .social-style-2 a:hover {
  color: #ffffff;
  border: 1px solid #ff2f2f;
  background-color: #ff2f2f;
}

.footer-right-wrap .social-style-2.social-style-2-hover-black a:hover {
  color: #ffffff;
  border: 1px solid #000000;
  background-color: #000000;
}

.footer-menu nav ul li {
  display: inline-block;
  margin-right: 47px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-menu nav ul li {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-menu nav ul li {
    margin-right: 6px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-menu nav ul li {
    margin-right: 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-menu nav ul li {
    margin-right: 15px;
  }
}

.footer-menu nav ul li:last-child {
  margin-right: 0;
}

.footer-menu nav ul li a {
  font-size: 16px;
  letter-spacing: .25px;
  color: #000000;
  display: inline-block;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .footer-menu nav ul li a {
    font-size: 14px;
  }
}

.footer-menu nav ul li a:hover {
  color: #ff2f2f;
}

.footer-menu.footer-menu-mrg nav ul li {
  margin-right: 47px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-menu.footer-menu-mrg nav ul li {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-menu.footer-menu-mrg nav ul li {
    margin-right: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-menu.footer-menu-mrg nav ul li {
    margin-right: 5px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-menu.footer-menu-mrg nav ul li {
    margin-right: 15px;
  }
}

.footer-menu.footer-menu-mrg nav ul li:last-child {
  margin-right: 0;
}

.footer-menu.footer-menu-hover-border nav ul li {
  position: relative;
}

.footer-menu.footer-menu-hover-border nav ul li a {
  position: relative;
}

.footer-menu.footer-menu-hover-border nav ul li a::after {
  content: "";
  height: 1px;
  right: 0px;
  left: auto;
  width: 0;
  bottom: 0;
  position: absolute;
  transition: width 300ms ease-out;
  background-color: #000000;
}

.footer-menu.footer-menu-hover-border nav ul li a.active::after {
  left: 0px;
  right: auto;
  width: 100%;
  transition: width 300ms ease-in;
}

.footer-menu.footer-menu-hover-border nav ul li:hover > a {
  color: #000000;
}

.footer-menu.footer-menu-hover-border nav ul li:hover > a::after {
  left: 0px;
  right: auto;
  width: 100%;
  transition: width 300ms ease-in;
}

.copyright p {
  color: #999;
  letter-spacing: .25px;
}

.copyright p a {
  color: #999;
}

.copyright p a span {
  color: #ff2f2f;
}

.copyright p a:hover {
  color: #ff2f2f;
}

@media only screen and (max-width: 767px) {
  .copyright.copyright-center {
    text-align: center;
  }
}

.social-style-3-wrap span {
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  margin: 0 0 20px;
}

@media only screen and (max-width: 767px) {
  .social-style-3-wrap span {
    margin: 0 0 7px;
  }
}

@media only screen and (max-width: 767px) {
  .social-style-3-wrap.xs-center {
    text-align: center;
  }
  .social-style-3-wrap.xs-center .social-style-1 {
    justify-content: center;
  }
}

.footer-logo-mrg {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .footer-logo-mrg {
    margin-bottom: 10px;
  }
}

.contact-info-wrap-2 p {
  margin-bottom: 7px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-info-wrap-2 p {
    margin: 0 auto 7px;
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info-wrap-2 {
    margin: 30px 0 30px;
  }
}

.language-style-2-wrap.language-style-2-right {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .language-style-2-wrap.language-style-2-right {
    text-align: center;
  }
}

.language-style-2-wrap span {
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  margin: 0 0 20px;
}

@media only screen and (max-width: 767px) {
  .language-style-2-wrap span {
    margin: 0 0 10px;
  }
}

.language-style-2-wrap .language-style-2 a {
  display: inline-block;
  margin-right: 15px;
  font-size: 16px;
  color: #999999;
}

.language-style-2-wrap .language-style-2 a:hover {
  color: #000000;
}

.language-style-2-wrap .language-style-2 a:last-child {
  margin-right: 0;
}

.footer-middle-mrg {
  margin: 80px 0 75px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-middle-mrg {
    margin: 40px 0 35px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-middle-mrg {
    margin: 30px 0 25px;
  }
}

.footer-widget {
  overflow: hidden;
}

.footer-widget h3.footer-title {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -.25px;
  margin: 0 0 40px;
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget h3.footer-title {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget h3.footer-title {
    margin: 0 0 20px;
  }
}

.footer-widget .footer-info-list ul li {
  display: block;
  margin-bottom: 8px;
}

.footer-widget .footer-info-list ul li:last-child {
  margin-bottom: 0;
}

.footer-widget .footer-info-list ul li a {
  color: #666666;
  display: block;
}

.footer-widget .footer-info-list ul li a:hover {
  color: #000000;
  text-decoration: underline;
}

.footer-widget .footer-info-list.info-list-50-parcent ul li {
  width: 50%;
  float: left;
}

.footer-widget .contact-info-2 .single-contact-info-2 {
  display: flex;
  margin: 0 0 12px;
}

.footer-widget .contact-info-2 .single-contact-info-2 .contact-info-2-icon i {
  font-size: 16px;
  color: #000000;
}

.footer-widget .contact-info-2 .single-contact-info-2 .contact-info-2-content {
  margin-left: 13px;
}

.footer-widget .contact-info-2 .single-contact-info-2 .contact-info-2-content p {
  color: #000000;
  margin: 0 0 0px;
}

.footer-widget .contact-info-2 .single-contact-info-2 .contact-info-2-content h3 {
  font-size: 30px;
  font-weight: 600;
  color: #4dbdaf;
  margin: 19px 0 28px;
}

.footer-widget .contact-info-2 .single-contact-info-2 .contact-info-2-content h3.purple {
  color: #6f50a7;
}

.footer-widget .contact-info-2 .single-contact-info-2 .contact-info-2-content h3.blue {
  color: #0064e0;
}

.footer-widget .contact-info-2 .single-contact-info-2 .contact-info-2-content h3.green-2 {
  color: #6aae27;
}

.footer-widget .contact-info-2 .single-contact-info-2 .contact-info-2-content h3.orange {
  color: #e44311;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget .contact-info-2 .single-contact-info-2 .contact-info-2-content h3 {
    font-size: 23px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .contact-info-2 .single-contact-info-2 .contact-info-2-content h3 {
    font-size: 16px;
    margin: 12px 0 4px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget .contact-info-2 .single-contact-info-2 .contact-info-2-content h3 {
    font-size: 20px;
    margin: 12px 0 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget.ml-70 {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-widget.ml-70 {
    margin-left: 0px;
  }
}

.payment-img a {
  display: block;
}

.payment-img a img {
  max-width: 100%;
}

.payment-img.payment-img-right {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .payment-img.payment-img-right {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .payment-img {
    margin-bottom: 15px;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-area.pb-65 {
    padding-bottom: 45px;
  }
  .footer-top.pb-55 {
    padding-bottom: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-area.pb-65 {
    padding-bottom: 55px;
  }
  .footer-area.pb-90 {
    padding-bottom: 55px;
  }
  .footer-area.pt-85 {
    padding-top: 55px;
  }
  .footer-area.pt-95 {
    padding-top: 55px;
  }
  .footer-top.pb-55 {
    padding-bottom: 15px;
  }
}

/*-------- 13. Instagram style ---------*/
.instagram-tag span {
  font-size: 16px;
  color: #000000;
}

@media only screen and (max-width: 767px) {
  .instagram-tag {
    margin-top: 7px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .instagram-tag {
    margin-top: 0px;
  }
}

.instagram-wrap-1 {
  display: flex;
}

.instagram-wrap-1 .single-instafeed a {
  display: block;
  position: relative;
  margin: 0 2.5px;
}

.instagram-wrap-1 .single-instafeed a:before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: #000000;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.instagram-wrap-1 .single-instafeed a::after {
  content: "\e609";
  font-family: 'simple-line-icons';
  position: absolute;
  font-size: 36px;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  color: #ffffff;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.5);
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instagram-wrap-1 .single-instafeed a::after {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .instagram-wrap-1 .single-instafeed a::after {
    font-size: 25px;
  }
}

.instagram-wrap-1 .single-instafeed a img {
  width: 100%;
}

.instagram-wrap-1 .single-instafeed a:hover:before {
  opacity: .4;
  visibility: visible;
}

.instagram-wrap-1 .single-instafeed a:hover::after {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transform: translateY(-50%);
}

.instagram-style-2 {
  display: flex;
  flex-wrap: wrap;
}

.instagram-style-2 .single-instafeed {
  flex: 0 0 12.5%;
}

@media only screen and (max-width: 767px) {
  .instagram-style-2 .single-instafeed {
    flex: 0 0 50%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .instagram-style-2 .single-instafeed {
    flex: 0 0 25%;
  }
}

.instagram-style-2 .single-instafeed a {
  display: block;
  overflow: hidden;
}

.instagram-style-2 .single-instafeed a img {
  width: 100%;
  transform: scale(1);
}

.instagram-style-2 .single-instafeed a:hover img {
  transform: scale(1.1);
}

.instagram-style-1 .single-instafeed a {
  display: block;
  position: relative;
  margin: 0 2.5px;
}

.instagram-style-1 .single-instafeed a:before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: #000000;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.instagram-style-1 .single-instafeed a::after {
  content: "\e609";
  font-family: 'simple-line-icons';
  position: absolute;
  font-size: 36px;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  color: #ffffff;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.5);
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instagram-style-1 .single-instafeed a::after {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .instagram-style-1 .single-instafeed a::after {
    font-size: 25px;
  }
}

.instagram-style-1 .single-instafeed a img {
  width: 100%;
}

.instagram-style-1 .single-instafeed a:hover:before {
  opacity: .4;
  visibility: visible;
}

.instagram-style-1 .single-instafeed a:hover::after {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transform: translateY(-50%);
}

/*-------- 14. Blog style ---------*/
.blog-area-pb {
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-area-pb {
    padding-bottom: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-area-pb {
    padding-bottom: 20px;
  }
}

.blog-wrap .blog-img {
  overflow: hidden;
}

.blog-wrap .blog-img a {
  display: block;
}

.blog-wrap .blog-img a img {
  width: 100%;
  transform: scale(1);
  transition: all .5s ease 0s;
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-img.mb-25 {
    margin-bottom: 15px;
  }
  .blog-wrap .blog-img.mb-20 {
    margin-bottom: 15px;
  }
}

.blog-wrap .blog-content .blog-meta ul {
  display: flex;
  flex-wrap: wrap;
}

.blog-wrap .blog-content .blog-meta ul li {
  margin-right: 30px;
  position: relative;
}

.blog-wrap .blog-content .blog-meta ul li:before {
  position: absolute;
  content: "";
  right: -16px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 12px;
  background-color: #000;
}

.blog-wrap .blog-content .blog-meta ul li:last-child {
  margin-right: 0;
}

.blog-wrap .blog-content .blog-meta ul li:last-child:before {
  display: none;
}

.blog-wrap .blog-content .blog-meta ul li a {
  color: #000000;
  font-weight: 600;
}

.blog-wrap .blog-content .blog-meta ul li a:hover {
  color: #ff2f2f;
}

.blog-wrap .blog-content h3, .blog-wrap .blog-content h1 {
  font-size: 20px;
  font-weight: 600;
  margin: 12px 0 0;
  line-height: 30px;
  letter-spacing: -.25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap .blog-content h3, .blog-wrap .blog-content h1 {
    font-size: 17px;
    margin: 8px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-content h3, .blog-wrap .blog-content h1 {
    font-size: 18px;
    margin: 8px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content h3, .blog-wrap .blog-content h1 {
    font-size: 17px;
    margin: 5px 0 0;
    line-height: 28px;
  }
}

.blog-wrap .blog-content h3 a, .blog-wrap .blog-content h1 a {
  color: #222222;
}

.blog-wrap .blog-content h3 a:hover, .blog-wrap .blog-content h1 a:hover {
  text-decoration: underline;
}

.blog-wrap:hover .blog-img a img {
  transform: scale(1.1);
}

@media only screen and (max-width: 767px) {
  .blog-wrap.mb-40 {
    margin-bottom: 30px;
  }
}

.single-sidebar-blog {
  display: flex !important;
  align-items: center;
  margin-bottom: 28px;
}

.single-sidebar-blog .sidebar-blog-img {
  margin-right: 20px;
  flex: 0 0 30%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-sidebar-blog .sidebar-blog-img {
    flex: 0 0 20%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-sidebar-blog .sidebar-blog-img {
    flex: 0 0 20%;
  }
}

.single-sidebar-blog .sidebar-blog-img a {
  display: block;
}

.single-sidebar-blog .sidebar-blog-img a img {
  width: 100%;
}

.single-sidebar-blog .sidebar-blog-content h5 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 0 0 3px;
}

.single-sidebar-blog .sidebar-blog-content h5 a {
  color: #000000;
}

.single-sidebar-blog .sidebar-blog-content h5 a:hover {
  text-decoration: underline;
}

.single-sidebar-blog .sidebar-blog-content span {
  font-size: 13px;
  color: #999999;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-area.pb-80 {
    padding-bottom: 45px;
  }
  .blog-area.pb-75 {
    padding-bottom: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-area.pb-80 {
    padding-bottom: 25px;
  }
  .blog-area.pb-75 {
    padding-bottom: 15px;
  }
}

/*-------- 15. Others style ---------*/
.download-app-content {
  margin-left: 100px;
  padding: 60px 0 65px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .download-app-content {
    margin-left: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .download-app-content {
    margin-left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .download-app-content {
    margin-left: 15px;
  }
}

.download-app-content h2 {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -.25px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .download-app-content h2 {
    font-size: 20px;
    line-height: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .download-app-content h2 {
    font-size: 23px;
  }
}

.download-app-content p {
  font-size: 16px;
  margin: 15px 0 36px;
}

@media only screen and (max-width: 767px) {
  .download-app-content p {
    margin: 12px 0 20px;
  }
}

.download-app-content .app-img {
  display: flex;
}

.download-app-content .app-img a {
  display: block;
}

.download-app-content .app-img a img {
  max-width: 100%;
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .download-app-content .app-img a img {
    width: 110px;
    margin-right: 10px;
  }
}

.testimonial-border-1 {
  border: 1px solid #d9d9d9;
}

.testimonial-padding-1 {
  padding: 35px 30px 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-padding-1 {
    padding: 35px 20px 30px;
  }
}

.single-testimonial img {
  max-width: 100%;
}

.single-testimonial p {
  color: #555555;
  width: 100%;
  margin: 17px 0 23px;
}

.single-testimonial .client-info {
  display: flex;
  margin: 0 0 23px;
}

.single-testimonial .client-info h5 {
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  margin: 0 6px 0 0;
}

.single-testimonial .client-info span {
  color: #777;
  font-size: 13px;
  display: inline-block;
  line-height: 1;
}

.tag-wrap a {
  display: inline-block;
  color: #555555;
  line-height: 1;
  background-color: #f5f5f5;
  padding: 8px 13px 10px;
  margin: 0 1px 7px;
  border-radius: 3px;
  border: 1px solid transparent;
}

.tag-wrap a:hover {
  background-color: transparent;
  border: 1px solid #6aae27;
  color: #6aae27;
}

/*-------- 16. Product details style ---------*/
.modal-dialog {
  margin: 2% auto 8%;
  max-width: 960px;
  width: 960px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal-dialog {
    width: 720px;
    max-width: 720px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-dialog {
    width: 100%;
    max-width: 100%;
    padding: 35px 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .modal-dialog {
    width: 480px;
    max-width: 480px;
    padding: 35px 0;
  }
}

.modal-dialog .modal-header .close {
  color: #333;
  cursor: pointer;
  opacity: 1;
}

.modal-dialog .modal-body {
  padding: 35px 15px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .modal-dialog .modal-body {
    padding: 35px 30px;
  }
}

.modal-dialog .quickview-big-img img {
  width: 100%;
}

.quickview-img img {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .product-details-content {
    margin: 25px 0 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content {
    margin: 30px 0 0;
  }
}

.product-details-content h2 {
  font-size: 25px;
  font-weight: bold;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .product-details-content h2 {
    font-size: 22px;
  }
}

.product-details-content .product-ratting-review-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0 10px;
}

.product-details-content .product-ratting-review-wrap .product-ratting-digit-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-right: 35px;
  position: relative;
}

.product-details-content .product-ratting-review-wrap .product-ratting-digit-wrap:before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  right: -18px;
  background-color: #BCBCBC;
  height: 15px;
  width: 1px;
}

.product-details-content .product-ratting-review-wrap .product-ratting-digit-wrap .product-ratting {
  display: flex;
  align-items: center;
}

.product-details-content .product-ratting-review-wrap .product-ratting-digit-wrap .product-ratting i {
  font-size: 12px;
  color: #ff9806;
  margin-right: 2px;
}

.product-details-content .product-ratting-review-wrap .product-ratting-digit-wrap .product-digit {
  margin-left: 18px;
}

.product-details-content .product-ratting-review-wrap .product-ratting-digit-wrap .product-digit span {
  font-size: 16px;
  color: #484848;
}

.product-details-content .product-ratting-review-wrap .product-review-order span {
  font-size: 15px;
  margin-right: 10px;
}

.product-details-content .product-ratting-review-wrap .product-review-order span:last-child {
  margin-right: 0;
}

.product-details-content .product-ratting-review-wrap.product-ratting-mrg-none {
  margin: 33px 0 14px;
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-ratting-review-wrap.product-ratting-mrg-none {
    margin: 15px 0 14px;
  }
}

.product-details-content p {
  font-size: 15px;
  line-height: 28px;
  margin: 0;
}

.product-details-content .pro-details-price {
  display: flex;
  margin: 22px 0 22px;
}

.product-details-content .pro-details-price span {
  font-size: 28px;
  font-weight: 500;
  font-family: "Heebo", sans-serif;
}

.product-details-content .pro-details-price span.new-price {
  color: #ff2f2f;
}

.product-details-content .pro-details-price span.old-price {
  text-decoration: line-through;
  font-size: 20px;
  color: #000000;
  margin-left: 15px;
}

.product-details-content .pro-details-price.pro-details-price-4 {
  margin: 0px 0 25px;
}

.product-details-content .pro-details-color-wrap {
  margin-bottom: 25px;
}

.product-details-content .pro-details-color-wrap span {
  display: block;
  font-weight: 600;
  margin: 0 0 7px;
  line-height: 1;
  letter-spacing: .5px;
}

.product-details-content .pro-details-size {
  margin-bottom: 20px;
}

.product-details-content .pro-details-size span {
  display: block;
  font-weight: 600;
  margin: 0 0 7px;
  line-height: 1;
  letter-spacing: .5px;
}

.product-details-content .pro-details-size .pro-details-size-content ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.product-details-content .pro-details-size .pro-details-size-content ul li {
  margin-right: 5px;
}

.product-details-content .pro-details-size .pro-details-size-content ul li:last-child {
  margin-right: 0;
}

.product-details-content .pro-details-size .pro-details-size-content ul li a {
  display: inline-block;
  border: 1px solid #E3E4E9;
  line-height: 1;
  width: 40px;
  height: 31px;
  text-align: center;
  line-height: 28px;
}

.product-details-content .pro-details-size .pro-details-size-content ul li a:hover {
  background-color: #ff2f2f;
  color: #ffffff;
  border: 1px solid #ff2f2f;
}

.product-details-content .pro-details-group {
  margin: 35px 0 25px;
}

.product-details-content .pro-details-group .single-group {
  display: flex;
  margin-bottom: 28px;
}

.product-details-content .pro-details-group .single-group:last-child {
  margin-bottom: 0px;
}

.product-details-content .pro-details-group .single-group .pro-dec-title-price {
  margin-left: 10px;
}

.product-details-content .pro-details-group .single-group .pro-dec-title-price .pro-dec-title h5 {
  font-size: 15px;
  font-weight: 600;
  margin: 0 0 5px;
}

.product-details-content .pro-details-group .single-group .pro-dec-title-price .pro-dec-title h5 a {
  color: #000000;
}

.product-details-content .pro-details-group .single-group .pro-dec-title-price .pro-dec-price span {
  font-size: 16px;
  font-weight: 500;
  font-family: "Heebo", sans-serif;
}

.product-details-content .pro-details-group .single-group .pro-dec-title-price .pro-dec-price span.new-price {
  color: #ff2f2f;
}

.product-details-content .pro-details-group .single-group .pro-dec-title-price .pro-dec-price span.old-price {
  text-decoration: line-through;
  font-size: 14px;
  color: #000000;
  margin-left: 7px;
}

.product-details-content .product-details-meta {
  margin: 11px 0 19px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content .product-details-meta {
    margin: 20px 0 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .product-details-meta {
    margin: 20px 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .product-details-meta {
    margin: 20px 0 20px;
  }
}

.product-details-content .product-details-meta ul li {
  margin: 0 0 5px;
}

.product-details-content .product-details-meta ul li:last-child {
  margin: 0 0 0px;
}

.product-details-content .product-details-meta ul li span {
  display: inline-block;
  width: 105px;
  font-weight: 600;
  letter-spacing: .5px;
}

.product-details-content .product-details-meta ul li a {
  color: #1C1C1C;
}

.product-details-content .product-details-meta ul li a:hover {
  color: #ff2f2f;
}

.product-details-content .pro-details-action-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-action-wrap {
    display: block;
  }
}

.product-details-content .pro-details-action-wrap.pro-details-action-center {
  justify-content: center;
}

.product-details-content .pro-details-action-wrap .pro-details-add-to-cart {
  margin-right: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .pro-details-action-wrap .pro-details-add-to-cart {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-action-wrap .pro-details-add-to-cart {
    margin-bottom: 20px;
  }
}

.product-details-content .pro-details-action-wrap .pro-details-add-to-cart a {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  line-height: 1;
  background-color: #000000;
  padding: 18px 50px 17px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content .pro-details-action-wrap .pro-details-add-to-cart a {
    padding: 18px 30px 17px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .pro-details-action-wrap .pro-details-add-to-cart a {
    padding: 14px 18px 15px;
  }
}

.product-details-content .pro-details-action-wrap .pro-details-add-to-cart a:hover {
  background-color: #ff2f2f;
}

.product-details-content .pro-details-action-wrap .pro-details-action {
  position: relative;
}

.product-details-content .pro-details-action-wrap .pro-details-action > a {
  display: inline-block;
  font-size: 17px;
  line-height: 1;
  padding: 16px 18px 17px;
  color: #000000;
  border: 1px solid #EDEDED;
  margin-right: 15px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content .pro-details-action-wrap .pro-details-action > a {
    padding: 16px 15px 17px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .pro-details-action-wrap .pro-details-action > a {
    padding: 12px 14px 13px;
    margin-right: 5px;
  }
}

.product-details-content .pro-details-action-wrap .pro-details-action > a:last-child {
  margin-right: 0px;
}

.product-details-content .pro-details-action-wrap .pro-details-action > a:hover {
  background-color: #ff2f2f;
  color: #ffffff;
}

.product-details-content.pro-details-content-mrg .pro-details-price {
  margin: 22px 0 32px;
}

.product-details-content.pro-details-content-mrg .pro-details-color-wrap {
  margin-bottom: 35px;
}

.product-details-content.pro-details-content-mrg .pro-details-color-wrap span {
  font-size: 15px;
}

.product-details-content.pro-details-content-mrg .pro-details-size {
  margin-bottom: 30px;
}

.product-details-content.pro-details-content-mrg .pro-details-size span {
  font-size: 15px;
}

.product-details-content.pro-details-content-mrg .pro-details-quality span {
  font-size: 15px;
}

.product-details-content.pro-details-content-mrg .product-details-meta {
  margin: 21px 0 29px;
}

.product-details-content.pro-details-content-mrg .product-details-meta ul li span {
  font-size: 15px;
}

.pro-details-quality span {
  display: block;
  font-weight: 600;
  margin: 0 0 7px;
  letter-spacing: .5px;
}

.pro-details-quality .cart-plus-minus {
  display: inline-block;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.pro-details-quality .cart-plus-minus .qtybutton {
  color: #000000;
  cursor: pointer;
  float: inherit;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
  position: absolute;
  text-align: center;
  transition: all 0.3s ease 0s;
  top: 0;
}

.pro-details-quality .cart-plus-minus .qtybutton.dec {
  height: 31px;
  width: 31px;
  line-height: 31px;
  border: 1px solid #E3E4E9;
}

.pro-details-quality .cart-plus-minus .qtybutton.inc {
  height: 31px;
  width: 31px;
  line-height: 31px;
  border: 1px solid #E3E4E9;
  right: 0px;
}

.pro-details-quality .cart-plus-minus input.cart-plus-minus-box {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #3A3A3A;
  float: left;
  font-size: 14px;
  font-weight: 500;
  height: 31px;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 92px;
}

.pro-details-quality .cart-plus-minus input.cart-plus-minus-box.plus-minus-width-inc {
  width: 120px;
}

.pro-details-color-content ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.pro-details-color-content ul li {
  margin-right: 5px;
  display: inline-block;
}

.pro-details-color-content ul li:last-child {
  margin-right: 0;
}

.pro-details-color-content ul li a {
  width: 31px;
  height: 31px;
  font-size: 0;
  display: inline-block;
  border: 1px solid #E3E4E9;
  position: relative;
}

.pro-details-color-content ul li a.active::before {
  color: #fff;
  content: "\5a";
  font-family: 'ElegantIcons';
  font-size: 20px;
  position: absolute;
  z-index: 9;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.pro-details-color-content ul li a.white {
  background-color: #fff;
}

.pro-details-color-content ul li a.azalea {
  background-color: #FBBBBB;
}

.pro-details-color-content ul li a.dolly {
  background-color: #F3E66D;
}

.pro-details-color-content ul li a.peach-orange {
  background-color: #FFCC8A;
}

.pro-details-color-content ul li a.mona-lisa {
  background-color: #FB9696;
}

.pro-details-color-content ul li a.cupid {
  background-color: #F5A8DE;
}

.pro-details-color-content ul li a.one {
  background-color: #D0CCF8;
}

.pro-details-color-content ul li a.two {
  background-color: #B6E3F6;
}

.pro-details-color-content ul li a.three {
  background-color: #D1D0AE;
}

.pro-details-color-content ul li a.four {
  background-color: #BED4A5;
}

.pro-details-color-content ul li a.five {
  background-color: #C4BB6C;
}

.pro-details-color-content ul li a.six {
  background-color: #A0D1B7;
}

.pro-details-color-content ul li a.seven {
  background-color: #E1A8A8;
}

.pro-details-color-content ul li a.eight {
  background-color: #B8BBD4;
}

.pro-details-color-content ul li a.nine {
  background-color: #A8A6B4;
}

.pro-details-color-content ul li a.ten {
  background-color: #5D6061;
}

@media only screen and (max-width: 767px) {
  .pro-details-color-content.mt-50 {
    margin-top: 20px;
  }
}

.product-dec-social {
  position: absolute;
  bottom: -33px;
  right: -7px;
  display: flex;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .product-dec-social {
    left: 0;
    right: auto;
  }
}

.product-dec-social a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  text-align: center;
  font-size: 12px;
}

.product-dec-social a.facebook {
  background-color: #5678bf;
}

.product-dec-social a.twitter {
  background-color: #73c2fd;
}

.product-dec-social a.instagram {
  background-color: #e4405f;
}

.product-dec-social a.pinterest {
  background-color: #c32026;
}

.product-dec-social.show {
  visibility: visible;
  opacity: 1;
  right: 15px;
}

.quickview-slide-active a {
  display: block;
  margin: 0 5px;
}

.quickview-slide-active a img {
  width: 100%;
}

.easyzoom-style {
  position: relative;
}

.easyzoom-style a.easyzoom-pop-up {
  position: absolute;
  right: 25px;
  bottom: 20px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  background-color: #fff;
  color: #000;
  font-size: 16px;
  text-align: center;
  border-radius: 100%;
  z-index: 999;
}

@media only screen and (max-width: 767px) {
  .easyzoom-style a.easyzoom-pop-up {
    width: 40px;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
  }
}

.easyzoom-style a.easyzoom-pop-up i {
  line-height: 50px;
}

@media only screen and (max-width: 767px) {
  .easyzoom-style a.easyzoom-pop-up i {
    line-height: 40px;
  }
}

.easyzoom-style a.easyzoom-pop-up:hover {
  color: #ff2f2f;
}

.easyzoom-style .easyzoom {
  display: block;
}

.easyzoom-style .easyzoom > a {
  transition: none;
}

.easyzoom-style .easyzoom > a img {
  width: 100%;
  transition: none;
}

.easyzoom-style .easyzoom-popup > a img {
  width: 100%;
}

.product-dec-small-style1 {
  width: 420px;
  margin: 13px auto 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .product-dec-small-style1 {
    width: 400px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-small-style1 {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-small-style1 {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-small-style1 {
    width: 100%;
  }
}

.product-dec-small-style1 .product-dec-small {
  margin: 0 5px 0px 5px;
  cursor: pointer;
}

.product-dec-small-style1 .product-dec-small img {
  width: 100%;
}

.product-dec-small-style1 .product-dec-small.slick-active {
  border: 1px solid transparent;
}

.product-dec-small-style1 .product-dec-small.slick-active.slick-current {
  border: 1px solid #ff2f2f;
}

.product-dec-small-style1 .product-dec-small:last-child {
  margin: 0 0 0px;
}

.product-dec-small-style1 span {
  color: #262626;
  font-size: 10px;
  position: absolute;
  line-height: 1;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 9;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border: 1px solid #979797;
  color: #1E1E1E;
  text-align: center;
  border-radius: 100%;
}

.product-dec-small-style1 span:hover {
  color: #ffffff;
  border: 1px solid #ff2f2f;
  background-color: #ff2f2f;
}

.product-dec-small-style1 span.pro-dec-prev {
  left: -5%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-small-style1 span.pro-dec-prev {
    left: -4%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-small-style1 span.pro-dec-prev {
    left: -4%;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-small-style1 span.pro-dec-prev {
    left: -2%;
  }
}

.product-dec-small-style1 span.pro-dec-next {
  left: auto;
  right: -4.7%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-small-style1 span.pro-dec-next {
    right: -4%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-small-style1 span.pro-dec-next {
    right: -4%;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-small-style1 span.pro-dec-next {
    right: -2%;
  }
}

.product-dec-small-style1 .slick-slide,
.product-dec-small-style2 .slick-slide {
  border: 1px solid transparent;
}

.dec-review-topbar {
  border-bottom: 1px solid #ebebeb;
  justify-content: center;
}

.dec-review-topbar a {
  display: inline-block;
  font-size: 18px;
  color: #000000;
  padding: 0 30px 22px;
  margin: 0 14px 0 0;
  position: relative;
}

.dec-review-topbar a:last-child {
  margin: 0 0px 0 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dec-review-topbar a {
    padding: 0 15px 22px;
    margin: 0 7px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .dec-review-topbar a {
    padding: 0 10px 10px;
    margin: 0 0px 10px 0;
    font-size: 16px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .dec-review-topbar a {
    padding: 0 7px 10px;
  }
}

.dec-review-topbar a:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #ff2f2f;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
}

.dec-review-topbar a.active {
  color: #ff2f2f;
  font-weight: 600;
}

.dec-review-topbar a.active:before {
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 767px) {
  .dec-review-topbar.mb-45 {
    margin-bottom: 25px;
  }
}

.dec-review-topbar.dec-review-topbar-center {
  display: flex;
  justify-content: center;
  border-bottom: none;
}

.dec-review-bottom .description-wrap p {
  font-size: 15px;
  line-height: 30px;
  margin: 0 0 20px;
}

@media only screen and (max-width: 767px) {
  .dec-review-bottom .description-wrap p {
    font-size: 14px;
    margin: 0 0 18x;
    line-height: 27px;
  }
}

.dec-review-bottom .description-wrap p:last-child {
  margin: 0 0 0px;
}

.dec-review-bottom .specification-wrap table {
  width: 100%;
}

.dec-review-bottom .specification-wrap table tbody {
  border: 1px solid #f0f0f0;
  width: 100%;
}

.dec-review-bottom .specification-wrap table tbody tr {
  border-bottom: 1px solid #f0f0f0;
}

.dec-review-bottom .specification-wrap table tbody tr td {
  border-right: 1px solid #f0f0f0;
  font-size: 14px;
  color: #000000;
  padding: 17px 30px;
}

.dec-review-bottom .specification-wrap table tbody tr td.title {
  font-weight: 500;
  font-size: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dec-review-bottom .specification-wrap table tbody tr td {
    padding: 17px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dec-review-bottom .specification-wrap table tbody tr td {
    padding: 17px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .dec-review-bottom .specification-wrap table tbody tr td {
    padding: 17px 15px;
  }
}

.dec-review-bottom .specification-wrap table tbody tr td.width1 {
  width: 300px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dec-review-bottom .specification-wrap table tbody tr td.width1 {
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .dec-review-bottom .specification-wrap table tbody tr td.width1 {
    width: 100px;
  }
}

.dec-review-bottom .review-wrapper h2 {
  font-size: 22px;
  margin: 0 0 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dec-review-bottom .review-wrapper h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .dec-review-bottom .review-wrapper h2 {
    font-size: 18px;
    margin: 0 0 18px;
  }
}

.dec-review-bottom .single-review {
  margin: 0 0 60px;
  padding: 50px 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .dec-review-bottom .single-review {
    padding: 50px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .dec-review-bottom .single-review {
    padding: 30px;
    display: block;
    margin: 0 0 30px;
  }
}

.dec-review-bottom .single-review .review-img {
  flex: 0 0 60px;
}

.dec-review-bottom .single-review .review-content {
  margin-left: 20px;
}

@media only screen and (max-width: 767px) {
  .dec-review-bottom .single-review .review-content {
    margin-left: 0px;
  }
}

.dec-review-bottom .single-review .review-content .review-top-wrap {
  display: flex;
  justify-content: space-between;
  margin: 0 0 15px;
}

@media only screen and (max-width: 767px) {
  .dec-review-bottom .single-review .review-content .review-top-wrap {
    display: block;
    margin: 10px 0 10px;
  }
}

.dec-review-bottom .single-review .review-content .review-top-wrap .review-name h5 {
  font-size: 13px;
  margin: 0;
  color: #535353;
}

.dec-review-bottom .single-review .review-content .review-top-wrap .review-name h5 span {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.dec-review-bottom .single-review .review-content .review-top-wrap .review-rating {
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .dec-review-bottom .single-review .review-content .review-top-wrap .review-rating {
    margin: 10px 0 0px;
  }
}

.dec-review-bottom .single-review .review-content .review-top-wrap .review-rating i {
  font-size: 14px;
  color: #d5d6d8;
}

.dec-review-bottom .single-review .review-content .review-top-wrap .review-rating i.yellow {
  color: #f5b223;
}

.dec-review-bottom .single-review .review-content p {
  font-size: 15px;
  line-height: 28px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .dec-review-bottom .single-review .review-content p {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .dec-review-bottom .single-review.pro-details-9-single-review {
    padding: 50px 12px;
  }
}

.dec-review-bottom .ratting-form-wrapper > span {
  display: block;
  line-height: 1;
  font-size: 16px;
}

.dec-review-bottom .ratting-form-wrapper p {
  font-size: 14px;
  margin: 9px 0 0;
  color: #535353;
}

.dec-review-bottom .ratting-form-wrapper p span {
  color: red;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form {
  margin: 30px 0 0;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form .star-box-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0 29px;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form .star-box-wrap .single-ratting-star {
  margin-right: 17px;
  display: flex;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form .star-box-wrap .single-ratting-star a {
  color: #535353;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form .star-box-wrap .single-ratting-star:hover i {
  color: #f5b223;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form .rating-form-style label {
  margin: 0 0 5px;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form .rating-form-style label span {
  color: red;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form .rating-form-style input, .dec-review-bottom .ratting-form-wrapper .ratting-form .rating-form-style textarea {
  background: transparent;
  border: 1px solid #CDCDCD;
  height: 50px;
  padding: 5px 10px;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form .rating-form-style input:focus, .dec-review-bottom .ratting-form-wrapper .ratting-form .rating-form-style textarea:focus {
  border: 1px solid #262626;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form .rating-form-style textarea {
  height: 150px;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form .form-submit {
  margin-top: 20px;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form .form-submit input {
  padding: 8px 35px;
  line-height: 30px;
  color: #fff;
  border-width: 0;
  font-weight: 600;
  display: inline-block;
  width: auto;
  height: auto;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: #000000;
}

.dec-review-bottom .ratting-form-wrapper .ratting-form .form-submit input:hover {
  background: #ff2f2f;
}

.product-dec-left {
  position: relative;
  float: left;
  width: 115px;
  padding-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-left {
    width: 110px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-left {
    width: 135px;
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-left {
    width: 90px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-dec-left {
    width: 100px;
  }
}

.product-dec-right {
  float: right;
  position: relative;
  width: calc(100% - 115px);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-dec-right {
    width: calc(100% - 110px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-dec-right {
    width: calc(100% - 135px);
  }
}

@media only screen and (max-width: 767px) {
  .product-dec-right {
    width: calc(100% - 90px);
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-dec-right {
    width: calc(100% - 100px);
  }
}

.product-dec-small-style2 {
  text-align: center;
}

.product-dec-small-style2 .product-dec-small {
  margin: 5px 0px 5px 0px;
  cursor: pointer;
}

.product-dec-small-style2 .product-dec-small img {
  width: 100%;
}

.product-dec-small-style2 .product-dec-small.slick-active {
  border: 1px solid transparent;
}

.product-dec-small-style2 .product-dec-small.slick-active.slick-current {
  border: 1px solid #ff2f2f;
}

.product-dec-small-style2 .product-dec-small:last-child {
  margin: 0 0 0px;
}

.product-dec-small-style2 span {
  color: #262626;
  font-size: 10px;
  line-height: 1;
  cursor: pointer;
  z-index: 9;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border: 1px solid #979797;
  color: #1E1E1E;
  text-align: center;
  border-radius: 100%;
  display: inline-block;
}

.product-dec-small-style2 span:hover {
  color: #ffffff;
  border: 1px solid #ff2f2f;
  background-color: #ff2f2f;
}

.sidebar-active.col-lg-6 {
  padding-left: 15px;
  padding-right: 15px;
}

.sidebar-active.col-lg-6.is-affixed {
  padding-left: 0px;
  padding-right: 0px;
}

.sidebar-active.col-lg-6.is-affixed .inner-wrapper-sticky {
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pro-details-content-mt-md {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-header {
    padding: 10px 1rem;
  }
}

/*------ 17. Breadcrumb style  --------*/
.breadcrumb-area {
  padding: 45px 0;
}

.breadcrumb-content ul li {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  color: #ff2f2f;
  margin-right: 18px;
  text-transform: capitalize;
  position: relative;
}

.breadcrumb-content ul li::before {
  position: absolute;
  width: 18px;
  height: 1px;
  background-color: #5b5858;
  content: "";
  right: -21px;
  top: 12px;
  z-index: 9;
  transform: rotate(115deg);
}

.breadcrumb-content ul li:last-child::before {
  display: none;
}

.breadcrumb-content ul li a {
  color: #000000;
  font-weight: 600;
}

.breadcrumb-content ul li a:hover {
  color: #ff2f2f;
}

/*-------- 18. Sidebar style ---------*/
.sidebar-wrapper.sidebar-wrapper-mrg-left {
  margin-left: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-wrapper.sidebar-wrapper-mrg-left {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-wrapper.sidebar-wrapper-mrg-left {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-wrapper.sidebar-wrapper-mrg-left {
    margin-left: 0px;
  }
}

.sidebar-wrapper.sidebar-wrapper-mrg-right {
  margin-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-wrapper.sidebar-wrapper-mrg-right {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-wrapper.sidebar-wrapper-mrg-right {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-wrapper.sidebar-wrapper-mrg-right {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-wrapper {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-wrapper {
    margin-top: 30px;
  }
}

.sidebar-widget h4.sidebar-widget-title {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  line-height: 1;
}

.sidebar-widget .sidebar-search {
  margin-top: 20px;
}

.sidebar-widget .sidebar-search .sidebar-search-form {
  position: relative;
}

.sidebar-widget .sidebar-search .sidebar-search-form input {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #e6e6e6;
  font-size: 14px;
  height: 43px;
  padding: 2px 55px 2px 18px;
  color: #000;
}

.sidebar-widget .sidebar-search .sidebar-search-form input::-moz-input-placeholder {
  color: #000;
  opacity: 1;
}

.sidebar-widget .sidebar-search .sidebar-search-form input::-webkit-input-placeholder {
  color: #000;
  opacity: 1;
}

.sidebar-widget .sidebar-search .sidebar-search-form button {
  background: transparent none repeat scroll 0 0;
  border-color: #a1a5aa;
  border-image: none;
  border-style: none none none solid;
  border-width: medium medium medium 1px;
  padding: 1px 15px 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #000;
  transition: all .3s ease 0s;
  font-size: 17px;
}

.sidebar-widget .sidebar-search .sidebar-search-form button:hover {
  color: #ff2f2f;
}

.sidebar-widget .shop-catigory {
  margin-top: 18px;
}

.sidebar-widget .shop-catigory ul li {
  margin-bottom: 10px;
}

.sidebar-widget .shop-catigory ul li:last-child {
  margin-bottom: 0;
}

.sidebar-widget .shop-catigory ul li a {
  color: #000000;
  display: block;
}

.sidebar-widget .shop-catigory ul li a:hover {
  color: #ff2f2f;
}

.sidebar-widget .price-filter {
  margin-top: 15px;
}

.sidebar-widget .price-filter span {
  display: block;
  margin: 0 0 16px;
}

.sidebar-widget .price-filter .price-slider-amount input {
  background: transparent;
  padding: 0;
  border: none;
  font-size: 15px;
  font-weight: 500;
}

.sidebar-widget .price-filter #slider-range {
  background: #dbdbdb none repeat scroll 0 0;
  border: medium none;
  border-radius: 0px;
  height: 5px;
  margin-bottom: 12px;
  margin-left: auto;
  margin-top: 5px;
}

.sidebar-widget .price-filter #slider-range span {
  transition: all .0s ease 0s;
}

.sidebar-widget .price-filter #slider-range .ui-slider-range {
  position: absolute;
  display: block;
  border: 0;
  background: #ff2f2f none repeat scroll 0 0;
  border: none;
  transition: all .0s ease 0s;
}

.sidebar-widget .price-filter #slider-range .ui-slider-handle {
  background: #fff none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  height: 15px;
  margin-left: 0;
  width: 15px;
  border: 4px solid #ff2f2f;
}

.sidebar-widget .price-filter .price-slider-amount .label-input input {
  color: #000000;
  height: 37px;
}

.sidebar-widget .price-filter .price-slider-amount button {
  background-color: #000000;
  border: medium none;
  color: #fff;
  line-height: 1;
  padding: 8px 16px;
  cursor: pointer;
  transition: all .3s ease 0s;
}

.sidebar-widget .price-filter .price-slider-amount button:hover {
  background-color: #ff2f2f;
}

.sidebar-widget .sidebar-widget-list {
  margin-top: 20px;
}

.sidebar-widget .sidebar-widget-list ul li {
  align-items: center;
  position: relative;
  padding: 0px 0 10px;
}

.sidebar-widget .sidebar-widget-list ul li:last-child {
  padding: 0px 0 0px;
}

.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left {
  position: relative;
}

.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 999;
  height: 15px;
  width: 15px;
  top: 5px;
  left: 0;
}

.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left input:checked ~ .checkmark {
  background-color: #ff2f2f;
  border: 2px solid #ff2f2f;
}

.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left input:checked ~ .checkmark::after {
  display: block;
}

.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left .checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 1px solid #888888;
}

.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left .checkmark::after {
  left: 3px;
  top: 0px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-top-width: medium;
  border-right-width: medium;
  border-bottom-width: medium;
  border-left-width: medium;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.sidebar-widget .sidebar-widget-list ul li a {
  margin-left: 25px;
  display: block;
  color: #000000;
}

.sidebar-widget .sidebar-widget-list ul li a:hover {
  color: #ff2f2f;
}

.sidebar-widget .sidebar-widget-list ul li a span {
  background-color: #eeeeee;
  width: 25px;
  height: 25px;
  display: inline-block;
  text-align: center;
  line-height: 25px;
  border-radius: 50px;
  float: right;
  transition: all .3s ease 0s;
  font-size: 13px;
}

.sidebar-widget .sidebar-widget-list ul li a:hover span {
  color: #fff;
  background-color: #ff2f2f;
}

.sidebar-widget .sidebar-widget-tag {
  margin-top: 26px;
}

.sidebar-widget .sidebar-widget-tag a {
  padding: 8px 12px 10px;
}

.sidebar-widget .sidebar-widget-tag a:hover {
  border: 1px solid #ff2f2f;
  color: #ff2f2f;
}

.sidebar-widget .recent-post {
  margin-top: 22px;
}

.sidebar-widget .recent-post .single-sidebar-blog {
  margin-bottom: 18px;
}

.sidebar-widget .archives-wrap {
  margin-top: 20px;
}

.sidebar-widget .archives-wrap select {
  height: 40px;
  border: 1px solid #ebebeb;
  color: #000000;
  cursor: pointer;
  padding: 2px 35px 2px 15px;
  -moz-appearance: none;
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/select.png") no-repeat scroll right 18px center;
}

.shop-sidebar-border {
  border-top: 1px solid #ebebeb;
}

/*-------- 19. Shop style ---------*/
.shop-topbar-wrapper {
  border: 1px solid #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 15px 24px;
}

@media only screen and (max-width: 767px) {
  .shop-topbar-wrapper {
    display: block;
    padding: 15px 15px;
    justify-content: inherit;
  }
}

.shop-topbar-wrapper .shop-topbar-left {
  display: flex;
  align-items: center;
}

.shop-topbar-wrapper .shop-topbar-left .view-mode {
  margin-right: 20px;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-topbar-wrapper .shop-topbar-left .view-mode {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-topbar-wrapper .shop-topbar-left .view-mode {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-topbar-wrapper .shop-topbar-left .view-mode {
    margin-right: 7px;
  }
}

.shop-topbar-wrapper .shop-topbar-left .view-mode a {
  color: #000000;
  font-size: 20px;
  display: inline-block;
  margin: 0 10px 0 0;
}

.shop-topbar-wrapper .shop-topbar-left .view-mode a.active {
  color: #ff2f2f;
}

.shop-topbar-wrapper .shop-topbar-left p {
  display: inline-block;
  margin: 0px 0 0;
  color: #000000;
}

.shop-topbar-wrapper .product-sorting-wrapper {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .shop-topbar-wrapper .product-sorting-wrapper {
    display: block;
    flex-wrap: inherit;
    overflow: hidden;
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-topbar-wrapper .product-sorting-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
}

.shop-topbar-wrapper .product-sorting-wrapper .shorting-style {
  margin-left: 20px;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .shop-topbar-wrapper .product-sorting-wrapper .shorting-style {
    margin-left: 0px;
    display: block;
    margin-bottom: 15px;
    margin-top: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-topbar-wrapper .product-sorting-wrapper .shorting-style {
    margin-left: 15px;
    margin-bottom: 0px;
  }
}

.shop-topbar-wrapper .product-sorting-wrapper .shorting-style label {
  color: #000000;
  cursor: pointer;
  margin: 3px 11px 0 0;
  font-size: 14px;
  font-weight: 500;
}

.shop-topbar-wrapper .product-sorting-wrapper .shorting-style select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff url("../../assets/images/icon-img/shop.png") no-repeat scroll right 10px center;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  box-shadow: none;
  color: #000000;
  cursor: pointer;
  font-size: 14px;
  height: 30px;
  padding-left: 10px;
  width: 170px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-topbar-wrapper .product-sorting-wrapper .shorting-style select {
    width: 130px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-topbar-wrapper .product-sorting-wrapper .shorting-style select {
    width: 130px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-topbar-wrapper .product-sorting-wrapper .shorting-style select {
    width: 150px;
  }
}

.shop-topbar-wrapper .product-sorting-wrapper .shorting-style select option {
  background: #fff;
  border: 0px solid #626262;
  padding-left: 10px;
  font-size: 14px;
}

.shop-topbar-wrapper .product-sorting-wrapper .shorting-style.product-shorting {
  margin-left: 0;
}

.shop-topbar-wrapper .product-sorting-wrapper .shorting-style.product-shorting select {
  width: 110px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-topbar-wrapper .product-sorting-wrapper .shorting-style.product-shorting select {
    width: 75px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-topbar-wrapper .product-sorting-wrapper .shorting-style.product-shorting select {
    width: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-topbar-wrapper .product-sorting-wrapper .shorting-style.product-shorting select {
    width: 163px;
  }
}

.shop-list-wrap .product-list-img {
  overflow: hidden;
  position: relative;
}

.shop-list-wrap .product-list-img a {
  display: block;
}

.shop-list-wrap .product-list-img a img {
  width: 100%;
}

.shop-list-wrap .product-list-img .product-list-quickview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease 0s;
  margin-top: 20px;
}

.shop-list-wrap .product-list-img .product-list-quickview button {
  font-size: 16px;
  color: #000000;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100%;
  text-align: center;
}

.shop-list-wrap .product-list-img .product-list-quickview button:hover {
  background-color: #ff2f2f;
  color: #ffffff;
  border: 1px solid #ff2f2f;
}

.shop-list-wrap .shop-list-content h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.shop-list-wrap .shop-list-content h3 a {
  color: #000000;
}

.shop-list-wrap .shop-list-content h3 a:hover {
  color: #ff2f2f;
}

.shop-list-wrap .shop-list-content .pro-list-price {
  margin: 15px 0 8px;
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap .shop-list-content .pro-list-price {
    margin: 10px 0 5px;
  }
}

.shop-list-wrap .shop-list-content .pro-list-price span {
  font-size: 20px;
  color: #000000;
  font-family: "Heebo", sans-serif;
  display: inline-block;
  margin: 0 4px;
}

.shop-list-wrap .shop-list-content .pro-list-price span.new-price {
  color: #ff2f2f;
}

.shop-list-wrap .shop-list-content .pro-list-price span.old-price {
  font-size: 16px;
  color: #999999;
  text-decoration: line-through;
}

.shop-list-wrap .shop-list-content .product-list-rating-wrap {
  display: flex;
}

.shop-list-wrap .shop-list-content .product-list-rating-wrap .product-list-rating i {
  color: #ff9806;
}

.shop-list-wrap .shop-list-content .product-list-rating-wrap .product-list-rating i.gray {
  color: #cccccc;
}

.shop-list-wrap .shop-list-content .product-list-rating-wrap span {
  margin-left: 6px;
  color: #999999;
  font-family: "Heebo", sans-serif;
}

.shop-list-wrap .shop-list-content p {
  margin: 7px 0 20px;
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap .shop-list-content p {
    margin: 5px 0 15px;
  }
}

.shop-list-wrap .shop-list-content .product-list-action button {
  font-size: 16px;
  color: #000000;
  border: 1px solid #e6e6e6;
  background-color: #F7F8FA;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100%;
  text-align: center;
  margin-right: 10px;
}

.shop-list-wrap .shop-list-content .product-list-action button:hover {
  background-color: #ff2f2f;
  color: #ffffff;
  border: 1px solid #ff2f2f;
}

@media only screen and (max-width: 767px) {
  .shop-list-wrap .shop-list-content {
    margin-top: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-list-wrap .shop-list-content {
    margin-top: 0px;
  }
}

.shop-list-wrap:hover .product-list-img .product-list-quickview {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}

.pro-pagination-style ul li {
  display: inline-block;
  margin: 0 4px;
}

.pro-pagination-style ul li a {
  display: inline-block;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  font-size: 16px;
  border-radius: 100%;
  color: #000000;
  box-shadow: 0 0px 12px 0.8px rgba(0, 0, 0, 0.1);
}

.pro-pagination-style ul li a:hover {
  background-color: #ff2f2f;
  color: #fff;
}

.pro-pagination-style ul li a.active {
  background-color: #ff2f2f;
  color: #fff;
  box-shadow: none;
}

.pro-pagination-style ul li a.active:hover {
  background-color: #333;
}

.pro-pagination-style ul li a.prev,
.pro-pagination-style ul li a.next {
  background-color: #f0f4f6;
  color: #000000;
  font-size: 14px;
  box-shadow: none;
}

.pro-pagination-style ul li a.prev:hover,
.pro-pagination-style ul li a.next:hover {
  background-color: #ff2f2f;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .pro-pagination-style.mt-10 {
    margin-top: 0px;
  }
}

.single-store h3 {
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 28px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
  .single-store h3 {
    font-size: 20px;
    margin-bottom: 8px;
  }
}

.single-store ul li {
  margin-bottom: 3px;
  color: #000000;
}

.single-store ul li:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-store.mb-50 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .single-store.mb-50 {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-location.pb-65 {
    padding-bottom: 37px;
  }
}

@media only screen and (max-width: 767px) {
  .shop-location.pb-65 {
    padding-bottom: 27px;
  }
}

/*-------- 20. Cart style ---------*/
h3.cart-page-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 15px;
}

.cart-table-content table {
  border: 1px solid #ebebeb;
}

@media only screen and (max-width: 767px) {
  .cart-table-content table {
    width: 100%;
  }
}

.cart-table-content table thead > tr {
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
}

@media only screen and (max-width: 767px) {
  .cart-table-content table thead > tr {
    width: 100%;
    display: block;
    padding: 10px 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cart-table-content table thead > tr {
    width: inherit;
    display: inline-block;
    padding: 10px 0;
  }
}

.cart-table-content table thead > tr th {
  border-top: medium none;
  font-size: 16px;
  font-weight: 600;
  padding: 21px 45px 22px;
  text-align: center;
  text-transform: capitalize;
  vertical-align: middle;
  white-space: nowrap;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-table-content table thead > tr th {
    padding: 21px 35px 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content table thead > tr th {
    padding: 21px 15px 22px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content table thead > tr th {
    padding: 0px 20px 5px;
    width: 100%;
    display: block;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cart-table-content table thead > tr th {
    width: inherit;
    display: inline-block;
  }
}

.cart-table-content table tbody > tr {
  border-bottom: 1px solid #ebebeb;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cart-table-content table tbody > tr {
    width: 50%;
    float: left;
  }
}

.cart-table-content table tbody > tr td.product-thumbnail {
  width: 150px;
}

@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td.product-thumbnail {
    width: 100%;
    display: block;
    overflow: hidden;
    padding-top: 30px;
    padding-bottom: 5px;
  }
}

.cart-table-content table tbody > tr td.product-name {
  width: 435px;
}

@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td.product-name {
    width: 100%;
    display: block;
    overflow: hidden;
  }
}

.cart-table-content table tbody > tr td.product-name a {
  font-size: 15px;
}

.cart-table-content table tbody > tr td.product-name a:hover {
  color: #ff2f2f;
}

.cart-table-content table tbody > tr td.product-price-cart {
  width: 435px;
}

@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td.product-price-cart {
    width: 100%;
    display: block;
    overflow: hidden;
  }
}

.cart-table-content table tbody > tr td.product-price-cart span {
  font-weight: 500;
}

.cart-table-content table tbody > tr td.product-quantity {
  width: 435px;
}

@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td.product-quantity {
    width: 100%;
    display: block;
    overflow: hidden;
  }
}

.cart-table-content table tbody > tr td.product-remove {
  width: 100px;
}

@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td.product-remove {
    width: 100%;
    display: block;
    overflow: hidden;
    padding-bottom: 21px;
  }
}

.cart-table-content table tbody > tr td.product-remove a {
  font-size: 20px;
}

.cart-table-content table tbody > tr td.product-remove a:hover {
  color: #ff2f2f;
}

@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td.product-wishlist-cart {
    padding-bottom: 30px;
  }
}

.cart-table-content table tbody > tr td.product-wishlist-cart > a {
  background-color: #000000;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  padding: 10px 12px 11px;
  display: inline-block;
  text-transform: capitalize;
}

.cart-table-content table tbody > tr td.product-wishlist-cart > a:hover {
  background-color: #ff2f2f;
}

.cart-table-content table tbody > tr td {
  font-size: 15px;
  padding: 30px 0;
  text-align: center;
  font-weight: 500;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content table tbody > tr td {
    padding: 30px 0 30px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td {
    padding: 6px 0 6px 0px;
    width: 100%;
    display: block;
    overflow: hidden;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cart-table-content table tbody > tr td {
    text-align: left;
    padding: 6px 20px 6px 20px;
  }
}

.cart-shiping-update-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 30px 0px 55px;
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper {
    display: block;
    padding: 30px 0px 15px;
  }
}

.cart-shiping-update-wrapper .cart-shiping-update > a,
.cart-shiping-update-wrapper .cart-clear > button,
.cart-shiping-update-wrapper .cart-clear > a {
  background-color: #f2f2f2;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  padding: 18px 40px 17px;
  text-transform: capitalize;
  letter-spacing: .3px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-shiping-update-wrapper .cart-shiping-update > a,
  .cart-shiping-update-wrapper .cart-clear > button,
  .cart-shiping-update-wrapper .cart-clear > a {
    padding: 18px 25px 17px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper .cart-shiping-update > a,
  .cart-shiping-update-wrapper .cart-clear > button,
  .cart-shiping-update-wrapper .cart-clear > a {
    padding: 18px 40px 17px;
    margin: 0 0 15px;
  }
}

.cart-shiping-update-wrapper .cart-shiping-update > a:hover,
.cart-shiping-update-wrapper .cart-clear > button:hover,
.cart-shiping-update-wrapper .cart-clear > a:hover {
  background-color: #ff2f2f;
  color: #fff;
}

.cart-shiping-update-wrapper .cart-clear > button {
  border: medium none;
  cursor: pointer;
  margin-right: 27px;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-shiping-update-wrapper .cart-clear > button {
    margin-right: 15px;
  }
}

.cart-tax,
.discount-code-wrapper {
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 45px 30px 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-tax,
  .discount-code-wrapper {
    padding: 45px 18px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-tax,
  .discount-code-wrapper {
    padding: 45px 20px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-tax,
  .discount-code-wrapper {
    padding: 45px 18px 50px;
  }
}

.cart-tax .title-wrap,
.discount-code-wrapper .title-wrap {
  position: relative;
}

.cart-tax .title-wrap::before,
.discount-code-wrapper .title-wrap::before {
  background-color: #e3e1e1;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 10px;
  transition: all 0.4s ease 0s;
  width: 100%;
  z-index: 1;
}

.cart-tax .title-wrap h4.cart-bottom-title,
.discount-code-wrapper .title-wrap h4.cart-bottom-title {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  padding-right: 18px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-tax .title-wrap h4.cart-bottom-title,
  .discount-code-wrapper .title-wrap h4.cart-bottom-title {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-tax .title-wrap h4.cart-bottom-title,
  .discount-code-wrapper .title-wrap h4.cart-bottom-title {
    font-size: 17px;
  }
}

.cart-tax .title-wrap .section-bg-gray,
.discount-code-wrapper .title-wrap .section-bg-gray {
  background-color: #f8f9f9;
  position: relative;
  z-index: 99;
}

.cart-tax .tax-wrapper,
.discount-code-wrapper .tax-wrapper {
  margin-top: 22px;
}

.cart-tax .tax-wrapper p,
.discount-code-wrapper .tax-wrapper p {
  margin: 0;
}

.cart-tax .tax-wrapper .tax-select-wrapper,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper {
  margin: 5px 0 0;
}

.cart-tax .tax-wrapper .tax-select-wrapper .tax-select,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper .tax-select {
  margin: 0 0 26px;
}

.cart-tax .tax-wrapper .tax-select-wrapper .tax-select label,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper .tax-select label {
  font-size: 14px;
  margin: 0 0 5px;
  font-weight: 400;
}

.cart-tax .tax-wrapper .tax-select-wrapper .tax-select select,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper .tax-select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff url("../../assets/images/icon-img/cart.png") no-repeat scroll right 18px center;
  border: 1px solid #ebebeb;
  box-shadow: none;
  color: #242424;
  font-size: 13px;
  height: 40px;
  padding: 0 50px 0 15px;
  width: 100%;
  cursor: pointer;
}

.cart-tax .tax-wrapper .tax-select-wrapper .tax-select input,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper .tax-select input {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #ebebeb;
  height: 40px;
}

.cart-tax .tax-wrapper .tax-select-wrapper button.cart-btn-2,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper button.cart-btn-2 {
  background-color: #000000;
  border: medium none;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: .3px;
  padding: 12px 40px 13px;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
}

.cart-tax .tax-wrapper .tax-select-wrapper button.cart-btn-2:hover,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper button.cart-btn-2:hover {
  background-color: #ff2f2f;
}

.cart-tax .discount-code,
.discount-code-wrapper .discount-code {
  margin: 21px 0 0;
}

.cart-tax .discount-code p,
.discount-code-wrapper .discount-code p {
  margin: 0 0 15px;
}

.cart-tax .discount-code form input,
.discount-code-wrapper .discount-code form input {
  background: #fff;
  border: 1px solid #ebebeb;
  height: 40px;
  margin-bottom: 30px;
  padding-left: 10px;
}

.cart-tax .discount-code form button.cart-btn-2,
.discount-code-wrapper .discount-code form button.cart-btn-2 {
  background-color: #000000;
  border: medium none;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 40px 13px;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
}

.cart-tax .discount-code form button.cart-btn-2:hover,
.discount-code-wrapper .discount-code form button.cart-btn-2:hover {
  background-color: #ff2f2f;
}

.grand-totall {
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 45px 30px 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .grand-totall {
    padding: 45px 18px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grand-totall {
    padding: 45px 20px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grand-totall {
    margin-top: 30px;
  }
}

.grand-totall .title-wrap {
  position: relative;
}

.grand-totall .title-wrap::before {
  background-color: #e3e1e1;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 10px;
  transition: all 0.4s ease 0s;
  width: 100%;
  z-index: 1;
}

.grand-totall .title-wrap h4.cart-bottom-title {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  padding-right: 18px;
}

.grand-totall .title-wrap .section-bg-gary-cart {
  background-color: #f9f9f9;
  position: relative;
  z-index: 9;
}

.grand-totall h5 {
  font-size: 14px;
  margin: 36px 0 27px;
  font-weight: 600;
}

.grand-totall h5 span {
  float: right;
  font-size: 18px;
  font-weight: 500;
}

.grand-totall .total-shipping {
  border-bottom: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
  margin: 0 0 27px;
  padding: 28px 0;
}

.grand-totall .total-shipping h5 {
  font-size: 14px;
  margin: 0;
}

.grand-totall .total-shipping ul {
  padding: 19px 0 0 0px;
}

.grand-totall .total-shipping ul li {
  color: #242424;
  list-style: outside none none;
  margin: 0 0 6px;
}

.grand-totall .total-shipping ul li:last-child {
  margin: 0 0 0px;
}

.grand-totall .total-shipping ul li input {
  background: #e9e9e9 none repeat scroll 0 0;
  border: 1px solid #d7d7d7;
  border-radius: 5px !important;
  color: #626262;
  cursor: pointer;
  height: 13px;
  margin-right: 10px;
  padding: 0;
  position: relative;
  top: 2px;
  width: 13px;
}

.grand-totall .total-shipping ul li span {
  float: right;
}

.grand-totall h4.grand-totall-title {
  color: #ff2f2f;
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 25px;
}

.grand-totall h4.grand-totall-title span {
  float: right;
}

.grand-totall a {
  background-color: #000000;
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  padding: 18px 10px 17px;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: .3px;
}

.grand-totall a:hover {
  background-color: #ff2f2f;
}

@media only screen and (max-width: 767px) {
  .discount-code-wrapper {
    margin: 30px 0;
  }
}

/*-------- 21. Compare style ---------*/
.compare-table .table tbody tr {
  margin-bottom: 20px;
}

.compare-table .table tbody tr td {
  padding: 20px 20px;
  vertical-align: middle;
  border-color: #ccc;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr td {
    padding: 20px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td {
    padding: 10px 20px;
    display: block;
    width: 100%;
    overflow: hidden;
    margin-bottom: 5px;
  }
}

.compare-table .table tbody tr td.first-column {
  min-width: 175px;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr td.first-column {
    min-width: 110px;
  }
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td.first-column {
    min-width: 100%;
    display: block;
    width: 100%;
    overflow: hidden;
    margin-bottom: 5px;
    margin-top: 35px;
  }
}

.compare-table .table tbody tr td.product-image-title {
  min-width: 310px;
  vertical-align: bottom;
  text-align: left;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .compare-table .table tbody tr td.product-image-title {
    min-width: 210px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr td.product-image-title {
    min-width: 110px;
  }
}

@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td.product-image-title {
    min-width: 100%;
    display: block;
    width: 100%;
    overflow: hidden;
    padding: 20px;
  }
}

.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin-bottom: 15px;
  display: block;
}

.compare-table .table tbody tr td.product-image-title .image img {
  width: 100%;
}

.compare-table .table tbody tr td.product-image-title .category {
  float: left;
  clear: both;
  color: #ff2f2f;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.compare-table .table tbody tr td.product-image-title .title {
  float: left;
  clear: both;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
  text-transform: capitalize;
  margin-top: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr td.product-image-title .title {
    font-size: 16px;
  }
}

.compare-table .table tbody tr td.product-image-title .title:hover {
  color: #ff2f2f;
}

.compare-table .table tbody tr td.pro-desc p {
  text-align: left;
  margin: 0;
}

.compare-table .table tbody tr td a.check-btn {
  color: #000000;
  font-size: 15px;
}

.compare-table .table tbody tr td a.check-btn:hover {
  color: #ff2f2f;
}

.compare-table .table tbody tr td.pro-price, .compare-table .table tbody tr td.pro-color, .compare-table .table tbody tr td.pro-stock {
  font-size: 15px;
  font-weight: 400;
  color: #000000;
}

.compare-table .table tbody tr td.pro-remove button {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}

.compare-table .table tbody tr td.pro-remove button:hover {
  color: #ff2f2f;
}

.compare-table .table tbody tr td.pro-remove button i {
  font-size: 20px;
}

.compare-table .table tbody tr td.pro-ratting i {
  color: #e7a415;
}

@media only screen and (max-width: 767px) {
  .compare-page-wrapper.pt-90 {
    padding-top: 20px;
  }
}

/*------ end Compare Page Wrapper -----*/
/*-------- 22. Checkout style ---------*/
.customer-zone > p {
  font-size: 15px;
  position: relative;
  padding: 14px 30px 14px 60px;
  background-color: #eeeff2;
  margin: 0;
  position: relative;
  color: #000000;
}

.customer-zone > p a {
  color: #000000;
}

.customer-zone > p a:hover {
  color: #ff2f2f;
}

@media only screen and (max-width: 767px) {
  .customer-zone > p {
    padding: 14px 30px 14px 40px;
  }
}

.customer-zone > p:before {
  content: "\e080";
  color: #6dde6d;
  display: inline-block;
  position: absolute;
  font-family: 'simple-line-icons';
  left: 15px;
  top: 15px;
  font-size: 18px;
}

.checkout-login-info {
  margin: 20px 0 0;
  display: none;
}

.checkout-login-info p {
  font-size: 14px;
  margin: 0 0 12px;
}

.checkout-login-info form {
  display: block;
  overflow: hidden;
}

.checkout-login-info form .sin-checkout-login {
  margin-bottom: 30px;
}

.checkout-login-info form .sin-checkout-login label {
  font-size: 14px;
  text-transform: capitalize;
  margin: 0 0 5px;
}

.checkout-login-info form .sin-checkout-login label span {
  color: red;
}

.checkout-login-info form .sin-checkout-login input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
}

.checkout-login-info form .sin-checkout-login input:focus {
  border: 1px solid #343538;
}

.checkout-login-info form .button-remember-wrap {
  display: block;
  overflow: hidden;
}

.checkout-login-info form .button-remember-wrap button {
  border: 1px solid #aaa;
  font-size: 13px;
  padding: 10px 30px 9px;
  display: inline-block;
  float: left;
  margin-right: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.checkout-login-info form .button-remember-wrap button:hover {
  background-color: #ff2f2f;
  color: #fff;
  border: 1px solid #ff2f2f;
}

.checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn {
  display: flex;
  margin: 8px 0 0;
}

.checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn input {
  height: 15px;
  margin: 0;
  position: relative;
  top: 5px;
  width: 17px;
}

.checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn label {
  font-size: 14px;
  margin: 0 0 0 5px;
}

.checkout-login-info form .lost-password {
  margin: 20px 0 11px;
}

.checkout-login-info form .lost-password a {
  font-size: 14px;
}

.checkout-login-info .checkout-login-social span {
  font-size: 14px;
  margin: 0 0 13px;
  display: block;
}

.checkout-login-info .checkout-login-social ul li {
  display: inline-block;
  margin: 0 14px 0 0;
}

.checkout-login-info .checkout-login-social ul li:last-child {
  margin: 0 0px 0 0;
}

@media only screen and (max-width: 767px) {
  .checkout-login-info .checkout-login-social ul li {
    margin: 0 5px 0 0;
  }
}

.checkout-login-info .checkout-login-social ul li a {
  background-color: #f5f5f5;
  text-transform: uppercase;
  font-weight: 500;
  display: block;
  float: left;
  font-size: 13px;
  padding: 20px;
  padding: 24px 106px 25px;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .checkout-login-info .checkout-login-social ul li a {
    padding: 24px 70px 25px;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-login-info .checkout-login-social ul li a {
    padding: 15px 13px 16px;
  }
}

.checkout-login-info .checkout-login-social ul li a:hover {
  background-color: #ff2f2f;
  color: #fff;
}

.checkout-login-info2 {
  display: none;
  margin: 20px 0 0;
}

.checkout-login-info2 ul li {
  display: inline-block;
}

.checkout-login-info2 ul li a {
  color: #8a8a8a;
  font-size: 12px;
}

.checkout-login-info2 ul li a:hover {
  color: #ff2f2f;
}

.checkout-login-info3 {
  display: none;
  margin-top: 20px;
}

.checkout-login-info3 form input[type="text"] {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  width: 48%;
  font-size: 14px;
  padding: 0 20px;
}

.checkout-login-info3 form input[type="text"]:focus {
  border: 1px solid #1f2226;
}

.checkout-login-info3 form input[type="submit"] {
  background: #1f2226 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  height: 50px;
  margin-left: 6px;
  padding: 5px 30px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  width: inherit;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .checkout-login-info3 form input[type="submit"] {
    padding: 5px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-login-info3 form input[type="submit"] {
    padding: 5px 16px;
  }
}

.checkout-login-info3 form input[type="submit"]:hover {
  background-color: #ff2f2f;
}

.billing-info-wrap h3 {
  font-size: 20px;
  font-weight: 600;
  position: relative;
  margin: 0 0 55px;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .billing-info-wrap h3 {
    margin-bottom: 40px;
  }
}

.billing-info-wrap h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -12px;
  background-color: #ff2f2f;
  width: 50px;
  height: 2px;
}

.billing-info-wrap .billing-info label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.billing-info-wrap .billing-info label abbr {
  color: red;
}

.billing-info-wrap .billing-info input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #1f2226;
  font-size: 14px;
}

.billing-info-wrap .billing-info input:focus {
  border: 1px solid #343538;
}

.billing-info-wrap .billing-info input.billing-address {
  margin-bottom: 20px;
}

.billing-info-wrap .billing-select label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.billing-info-wrap .billing-select label abbr {
  color: red;
}

.billing-info-wrap .billing-select select {
  border: 1px solid #e6e6e6;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: #333;
  font-size: 14px;
  height: 50px;
  padding: 2px 20px;
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/select.png") no-repeat scroll right 18px center;
  cursor: pointer;
}

.billing-info-wrap .billing-select select option {
  background: #fff;
  border: 0px solid #626262;
  padding-left: 10px;
  color: #333;
  font-size: 12px;
}

.billing-info-wrap .checkout-account input {
  border: 1px solid #9fa0a2;
  display: inline-block;
  float: left;
  height: 10px;
  width: 10px;
  position: relative;
  top: 6px;
  color: #333;
}

.billing-info-wrap .checkout-account span {
  color: #333;
  font-weight: 400;
  margin: 0 0 0 12px;
  font-size: 14px;
  text-transform: uppercase;
}

.billing-info-wrap .checkout-account-toggle label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.billing-info-wrap .checkout-account-toggle label abbr {
  color: red;
}

.billing-info-wrap .checkout-account-toggle input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  color: #333;
  font-size: 14px;
  padding: 2px 20px;
}

.billing-info-wrap .checkout-account-toggle input:focus {
  border: 1px solid #343538;
}

.billing-info-wrap .different-address {
  display: none;
}

.billing-info-wrap .additional-info-wrap {
  margin: 22px 0 0;
}

.billing-info-wrap .additional-info-wrap label {
  color: #010225;
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.billing-info-wrap .additional-info-wrap textarea {
  min-height: 120px;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
  padding: 20px;
  color: #333;
  font-size: 14px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .billing-info-wrap.mr-50 {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .billing-info-wrap.mr-50 {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .billing-info-wrap.mr-50 {
    margin-right: 0px;
  }
}

.your-order-area {
  padding: 40px 50px 50px;
  border-width: 2px;
  border-style: solid;
  position: relative;
  border-color: rgba(52, 53, 56, 0.1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .your-order-area {
    padding: 40px 25px 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .your-order-area {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .your-order-area {
    padding: 40px 12px 50px;
    margin-top: 30px;
  }
}

.your-order-area h3 {
  font-size: 20px;
  font-weight: 600;
  position: relative;
  margin: 0 0 45px;
  text-transform: uppercase;
}

.your-order-area h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -12px;
  background-color: #ff2f2f;
  width: 50px;
  height: 2px;
}

.your-order-area .your-order-wrap .your-order-info {
  border-bottom: 1px solid #e8e8e8;
}

.your-order-area .your-order-wrap .your-order-info ul li {
  font-size: 14px;
  padding: 0 0 7px;
  text-transform: uppercase;
  font-weight: 500;
}

.your-order-area .your-order-wrap .your-order-info ul li span {
  float: right;
  color: #ff2f2f;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-subtotal ul li span {
  font-size: 16px;
}

.your-order-area .your-order-wrap .your-order-info.order-shipping {
  padding: 15px 0 13px;
  display: block;
  overflow: hidden;
}

.your-order-area .your-order-wrap .your-order-info.order-shipping ul li > p {
  float: right;
  font-size: 14px;
  text-align: right;
  color: #333;
  text-transform: capitalize;
}

.your-order-area .your-order-wrap .your-order-info.order-total {
  padding: 15px 0 10px;
}

.your-order-area .your-order-wrap .your-order-info.order-total ul li span {
  font-size: 16px;
}

.your-order-area .your-order-wrap .your-order-middle {
  border-bottom: 1px solid #e8e8e8;
  padding: 24px 0 23px;
}

.your-order-area .your-order-wrap .your-order-middle ul li {
  font-size: 14px;
  margin: 0 0 11px;
}

.your-order-area .your-order-wrap .your-order-middle ul li:last-child {
  margin-bottom: 0;
}

.your-order-area .your-order-wrap .your-order-middle ul li span {
  float: right;
  font-size: 16px;
}

.your-order-area .your-order-wrap .payment-method {
  margin: 40px 0 0;
}

.your-order-area .your-order-wrap .payment-method .sin-payment {
  margin-bottom: 20px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment input {
  width: auto;
  display: inline-block;
  float: left;
  height: auto;
  margin-top: 5px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment label {
  font-size: 14px;
  text-transform: uppercase;
  margin: 0px 0 0 21px;
  display: flex;
  align-items: center;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .your-order-area .your-order-wrap .payment-method .sin-payment label {
    display: block;
  }
}

.your-order-area .your-order-wrap .payment-method .sin-payment label img {
  max-width: 140px;
  display: inline-block;
  margin: 0 5px;
}

.your-order-area .your-order-wrap .payment-method .sin-payment label a {
  text-decoration: underline;
}

.your-order-area .your-order-wrap .payment-method .sin-payment .payment-box {
  margin: 6px 0 0;
  display: none;
}

.your-order-area .your-order-wrap .payment-method .sin-payment .payment-box p {
  font-size: 14px;
  margin: 0;
}

.your-order-area .Place-order {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .your-order-area .Place-order {
    margin-top: 20px;
  }
}

.your-order-area .Place-order a {
  background-color: #ff2f2f;
  display: block;
  cursor: pointer;
  padding: 16px 50px 17px;
  text-transform: uppercase;
  font-weight: 700;
  width: 100%;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.your-order-area .Place-order a:hover {
  background-color: #333;
}

@media only screen and (max-width: 767px) {
  .checkout-main-area.pb-70 {
    padding-bottom: 50px;
  }
}

/*-------- 23. My account style ---------*/
.myaccount-tab-menu {
  flex-direction: column;
}

.myaccount-tab-menu a {
  border: 1px solid #ccc;
  border-bottom: none;
  font-weight: 600;
  font-size: 13px;
  display: block;
  padding: 10px 15px;
  text-transform: uppercase;
}

.myaccount-tab-menu a:last-child {
  border-bottom: 1px solid #ccc;
}

.myaccount-tab-menu a:hover, .myaccount-tab-menu a.active {
  background-color: #ff2f2f;
  border-color: #ff2f2f;
  color: #ffffff;
}

.myaccount-tab-menu a i.fa {
  font-size: 14px;
  text-align: center;
  width: 25px;
}

@media only screen and (max-width: 767px) {
  #myaccountContent {
    margin-top: 30px;
  }
}

.myaccount-content {
  border: 1px solid #eeeeee;
  padding: 30px;
}

@media only screen and (max-width: 767px) {
  .myaccount-content {
    padding: 20px 15px;
  }
}

.myaccount-content form {
  margin-top: -20px;
}

.myaccount-content h3 {
  font-size: 20px;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 10px;
  margin-bottom: 25px;
  font-weight: 600;
}

.myaccount-content .welcome a:hover {
  color: #ff2f2f;
}

.myaccount-content .welcome strong {
  font-weight: 600;
  color: #ff2f2f;
}

.myaccount-content fieldset {
  margin-top: 20px;
}

.myaccount-content fieldset legend {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.myaccount-content .account-details-form {
  margin-top: 50px;
}

.myaccount-content .account-details-form .single-input-item {
  margin-bottom: 20px;
}

.myaccount-content .account-details-form .single-input-item label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}

.myaccount-content .account-details-form .single-input-item input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #1f2226;
  font-size: 13px;
}

.myaccount-content .account-details-form .single-input-item input:focus {
  border: 1px solid #343538;
}

.myaccount-content .account-details-form .single-input-item button {
  border: none;
  background-color: #ff2f2f;
  text-transform: uppercase;
  font-weight: 600;
  padding: 9px 25px;
  color: #fff;
  font-size: 13px;
}

.myaccount-content .account-details-form .single-input-item button:hover {
  background-color: #1f2226;
}

.myaccount-table {
  white-space: nowrap;
  font-size: 14px;
}

.myaccount-table table th,
.myaccount-table .table th {
  padding: 10px;
  font-weight: 600;
  background-color: #f8f8f8;
  border-color: #ccc;
  border-bottom: 0;
  color: #1f2226;
}

.myaccount-table table td,
.myaccount-table .table td {
  padding: 10px;
  vertical-align: middle;
  border-color: #ccc;
}

.saved-message {
  background-color: #fff;
  border-top: 3px solid #ff2f2f;
  font-size: 14px;
  padding: 20px 0;
  color: #333;
}

/*-------- Start My Account Page Wrapper --------*/
/*-------- 24. Login register style ---------*/
.login-register-wrapper .login-register-tab-list {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .login-register-wrapper .login-register-tab-list {
    margin-bottom: 20px;
  }
}

.login-register-wrapper .login-register-tab-list a {
  position: relative;
}

.login-register-wrapper .login-register-tab-list a::before {
  background-color: #454545;
  bottom: 5px;
  content: "";
  height: 18px;
  margin: 0 auto;
  position: absolute;
  right: -2px;
  transition: all 0.4s ease 0s;
  width: 1px;
}

.login-register-wrapper .login-register-tab-list a h4 {
  font-size: 25px;
  font-weight: 700;
  margin: 0 20px;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
}

.login-register-wrapper .login-register-tab-list a:last-child::before {
  display: none;
}

.login-register-wrapper .login-register-tab-list a.active h4 {
  color: #ff2f2f;
}

.login-register-wrapper .login-form-container {
  background: transparent none repeat scroll 0 0;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  padding: 80px;
  text-align: left;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .login-register-wrapper .login-form-container {
    padding: 80px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .login-register-wrapper .login-form-container {
    padding: 40px 15px;
  }
}

.login-register-wrapper .login-form-container .login-register-form form input {
  background-color: transparent;
  border: 1px solid #ebebeb;
  font-size: 14px;
  height: 50px;
  margin-bottom: 30px;
  padding: 0 15px;
  color: #010225;
}

.login-register-wrapper .login-form-container .login-register-form form input[type="checkbox"] {
  height: 15px;
  margin: 0;
  position: relative;
  top: 2px;
  width: 17px;
  color: #010225;
}

.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn {
  padding: 10px 0 19px;
}

.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn label {
  font-size: 15px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn label {
    font-size: 14px;
  }
}

.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn a {
  float: right;
  font-size: 15px;
  color: #010225;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn a {
    font-size: 14px;
  }
}

.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn a:hover {
  color: #ff2f2f;
}

.login-register-wrapper .login-form-container .login-register-form form .button-box button {
  background-color: #ff2f2f;
  color: #fff;
  border: medium none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  padding: 13px 30px 13px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}

.login-register-wrapper .login-form-container .login-register-form form .button-box button:hover {
  background-color: #010225;
}

@media only screen and (max-width: 767px) {
  .login-register-area.pb-100 {
    padding-bottom: 60px;
  }
}

/*------- 25. Contect us style  -------*/
.contact-info-wrap-3 h3, .get-in-touch-wrap h3 {
  text-transform: capitalize;
  font-size: 25px;
  font-weight: 600;
  margin: 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .contact-info-wrap-3 h3, .get-in-touch-wrap h3 {
    margin: 0 0 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-info-wrap-3.pb-85, .get-in-touch-wrap.pb-85 {
    padding-bottom: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info-wrap-3.pb-85, .get-in-touch-wrap.pb-85 {
    padding-bottom: 25px;
  }
}

.single-contact-info-3 {
  background-color: #f7f7f7;
  padding: 48px 10px 49px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-contact-info-3 {
    padding: 28px 20px 29px;
  }
}

.single-contact-info-3 > i {
  font-size: 24px;
  color: #ff2f2f;
  transition: all .3s ease 0s;
  display: block;
}

.single-contact-info-3 h4 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  margin: 19px 0 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-contact-info-3 h4 {
    font-size: 18px;
    margin: 12px 0 10px;
  }
}

.single-contact-info-3 p {
  letter-spacing: .4px;
  font-size: 15px;
  color: #000000;
}

.single-contact-info-3 ul li {
  border-bottom: 2px solid #fff;
  letter-spacing: 0.4px;
  margin-bottom: 23px;
  padding-bottom: 26px;
  font-size: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-contact-info-3 ul li {
    display: block;
    margin-bottom: 11px;
    padding-bottom: 11px;
  }
}

.single-contact-info-3 ul li:last-child {
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom: none;
}

.single-contact-info-3 ul li i {
  font-size: 24px;
  margin-right: 7px;
  color: #ff2f2f;
  transition: all .3s ease 0s;
  display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-contact-info-3 ul li i {
    display: block;
    margin-right: 0px;
    margin-bottom: 3px;
  }
}

.single-contact-info-3 ul li a {
  color: #000000;
}

.single-contact-info-3 ul li a:hover {
  color: #ff2f2f;
}

.single-contact-info-3 ul li:hover i {
  animation: 500ms ease-in-out 0s normal none 1 running tada;
}

.single-contact-info-3:hover > i {
  animation: 500ms ease-in-out 0s normal none 1 running tada;
}

.contact-from input, .contact-from textarea {
  background: #f7f7f7 none repeat scroll 0 0;
  border: none;
  color: #000;
  height: 60px;
  padding: 2px 20px;
  margin-bottom: 30px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .contact-from input, .contact-from textarea {
    margin-bottom: 20px;
  }
}

.contact-from textarea {
  height: 200px;
  padding: 20px 20px;
}

.contact-from button {
  border: none;
  background-color: transparent;
  text-transform: capitalize;
  background-color: #ff2f2f;
  color: #fff;
  padding: 12px 30px;
  font-size: 15px;
  font-weight: 600;
}

.contact-from button:hover {
  background-color: #000000;
}

#map {
  height: 400px;
}

@media only screen and (max-width: 767px) {
  #map {
    height: 300px;
  }
}

/*------ 26. Blog details style  -------*/
.blog-details-top .blog-details-img {
  position: relative;
}

.blog-details-top .blog-details-img img {
  width: 100%;
}

.blog-details-top .blog-details-img .video-icon {
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}

.blog-details-top .blog-details-img .video-icon a {
  background-color: #ff0000;
  border-radius: 50px;
  color: #fff;
  display: inline-block;
  font-size: 25px;
  height: 60px;
  line-height: 61px;
  width: 60px;
}

.blog-details-top .blog-details-img .video-icon a:hover {
  background-color: #ff2f2f;
}

.blog-details-top .blog-details-content {
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .blog-details-top .blog-details-content {
    margin-top: 15px;
  }
}

.blog-details-top .blog-details-content .blog-meta-2 ul {
  display: flex;
  flex-wrap: wrap;
}

.blog-details-top .blog-details-content .blog-meta-2 ul li {
  margin-right: 30px;
  position: relative;
}

.blog-details-top .blog-details-content .blog-meta-2 ul li:before {
  position: absolute;
  content: "";
  right: -16px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 12px;
  background-color: #000;
}

.blog-details-top .blog-details-content .blog-meta-2 ul li:last-child {
  margin-right: 0;
}

.blog-details-top .blog-details-content .blog-meta-2 ul li:last-child:before {
  display: none;
}

.blog-details-top .blog-details-content .blog-meta-2 ul li a {
  color: #000000;
  font-weight: 600;
}

.blog-details-top .blog-details-content .blog-meta-2 ul li a:hover {
  color: #ff2f2f;
}

.blog-details-top .blog-details-content h1 {
  font-size: 24px;
  font-weight: 600;
  margin: 8px 0 20px;
  color: #222;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-top .blog-details-content h1 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-top .blog-details-content h1 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-top .blog-details-content h1 {
    font-size: 18px;
    line-height: 28px;
    margin: 8px 0 12px;
  }
}

.blog-details-top .blog-details-content p {
  margin: 0;
  font-size: 15px;
  line-height: 28px;
}

@media only screen and (max-width: 767px) {
  .blog-details-top .blog-details-content p {
    font-size: 14px;
    line-height: 26px;
  }
}

.blog-details-top .blog-details-content blockquote {
  color: #222;
  font-size: 18px;
  margin: 25px 0 26px;
  line-height: 34px;
  font-weight: 500;
  font-style: italic;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-top .blog-details-content blockquote {
    font-size: 17px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-top .blog-details-content blockquote {
    font-size: 17px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-top .blog-details-content blockquote {
    font-size: 16px;
    line-height: 28px;
    margin: 15px 0 15px;
  }
}

.dec-img-wrapper {
  margin-top: 50px;
}

@media only screen and (max-width: 767px) {
  .dec-img-wrapper {
    margin-top: 25px;
  }
}

.dec-img-wrapper p {
  font-size: 15px;
  line-height: 28px;
}

.dec-img-wrapper .dec-img img {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .dec-img-wrapper .dec-img.mb-50 {
    margin-bottom: 25px;
  }
}

.tag-share {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 30px;
}

@media only screen and (max-width: 767px) {
  .tag-share {
    display: block;
    margin: 20px 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .tag-share .dec-tag {
    margin-bottom: 10px;
  }
}

.tag-share .dec-tag ul {
  display: flex;
}

.tag-share .dec-tag ul li {
  margin-right: 10px;
  position: relative;
}

.tag-share .dec-tag ul li a {
  text-transform: capitalize;
  font-size: 15px;
  color: #000000;
}

.tag-share .dec-tag ul li a:hover {
  color: #ff2f2f;
}

.tag-share .blog-share {
  display: flex;
  align-items: center;
}

.tag-share .blog-share span {
  font-size: 15px;
  text-transform: capitalize;
}

.tag-share .blog-share ul {
  display: flex;
}

.tag-share .blog-share ul li {
  margin-left: 10px;
}

.tag-share .blog-share ul li a {
  font-size: 16px;
  color: #000000;
}

.tag-share .blog-share ul li a:hover.facebook {
  color: #5678bf;
}

.tag-share .blog-share ul li a:hover.twitter {
  color: #73c2fd;
}

.tag-share .blog-share ul li a:hover.instagram {
  color: #e4405f;
}

.next-previous-post {
  border-bottom: 1px solid #f1f1f1;
  border-top: 1px solid #f1f1f1;
  padding: 18px 0 17px;
  display: flex;
  justify-content: space-between;
}

.next-previous-post a {
  font-size: 15px;
  text-transform: capitalize;
  color: #000000;
}

.next-previous-post a:hover {
  color: #ff2f2f;
}

@media only screen and (max-width: 767px) {
  .blog-comment-wrapper.mt-55 {
    margin-top: 25px;
  }
}

.blog-comment-wrapper .blog-dec-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .blog-comment-wrapper .blog-dec-title {
    font-size: 18px;
  }
}

.blog-comment-wrapper .single-comment-wrapper {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .blog-comment-wrapper .single-comment-wrapper {
    display: block;
  }
  .blog-comment-wrapper .single-comment-wrapper.mt-35 {
    margin-top: 20px;
  }
  .blog-comment-wrapper .single-comment-wrapper.mt-50 {
    margin-top: 30px;
  }
}

.blog-comment-wrapper .single-comment-wrapper .blog-comment-img {
  flex: 0 0 120px;
  margin-right: 28px;
}

@media only screen and (max-width: 767px) {
  .blog-comment-wrapper .single-comment-wrapper .blog-comment-img {
    margin-right: 28px;
    margin-bottom: 20px;
  }
}

.blog-comment-wrapper .single-comment-wrapper .blog-comment-img img {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .blog-comment-wrapper .single-comment-wrapper .blog-comment-img img {
    width: auto;
  }
}

.blog-comment-wrapper .single-comment-wrapper .blog-comment-content h4 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  line-height: 1;
}

.blog-comment-wrapper .single-comment-wrapper .blog-comment-content span {
  display: block;
  font-size: 15px;
  margin: 6px 0 8px;
}

.blog-comment-wrapper .single-comment-wrapper .blog-comment-content p {
  font-size: 15px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .blog-comment-wrapper .single-comment-wrapper .blog-comment-content p {
    font-size: 14px;
  }
}

.blog-comment-wrapper .single-comment-wrapper .blog-comment-content .blog-details-btn a {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  color: #000000;
}

.blog-comment-wrapper .single-comment-wrapper .blog-comment-content .blog-details-btn a:hover {
  color: #ff2f2f;
}

@media only screen and (max-width: 767px) {
  .blog-comment-wrapper .single-comment-wrapper.ml-120 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .blog-reply-wrapper.mt-50 {
    margin-top: 30px;
  }
}

.blog-reply-wrapper .blog-dec-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .blog-reply-wrapper .blog-dec-title {
    font-size: 18px;
  }
}

.blog-reply-wrapper .blog-form {
  margin-top: 35px;
}

@media only screen and (max-width: 767px) {
  .blog-reply-wrapper .blog-form {
    margin-top: 20px;
  }
}

.blog-reply-wrapper .blog-form .leave-form input {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #eaeaea;
  box-shadow: none;
  color: #000000;
  height: 50px;
  margin-bottom: 30px;
  padding-left: 20px;
}

.blog-reply-wrapper .blog-form .text-leave textarea {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #eaeaea;
  box-shadow: none;
  color: #000000;
  height: 200px;
  padding-top: 20px;
  margin-bottom: 20px;
  padding-left: 20px;
}

@media only screen and (max-width: 767px) {
  .blog-reply-wrapper .blog-form .text-leave textarea {
    height: 120px;
  }
}

.blog-reply-wrapper .blog-form .text-leave input {
  background-color: #ff2f2f;
  border: medium none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  height: inherit;
  margin-top: 10px;
  padding: 13px 30px 13px;
  width: inherit;
}

.blog-reply-wrapper .blog-form .text-leave input:hover {
  background-color: #000000;
}

/*-------- 27. Order tracking style ---------*/
.order-tracking-content p {
  line-height: 30px;
  margin: 0 auto;
  text-align: center;
  width: 89%;
  color: #333;
}

@media only screen and (max-width: 767px) {
  .order-tracking-content p {
    width: 100%;
  }
}

.order-tracking-content .order-tracking-form {
  margin: 30px 0 0;
}

.order-tracking-content .order-tracking-form .sin-order-tracking {
  margin: 0 0 36px;
}

.order-tracking-content .order-tracking-form .sin-order-tracking label {
  letter-spacing: .2px;
  font-weight: 600;
  margin: 0 0 10px;
  display: block;
}

.order-tracking-content .order-tracking-form .sin-order-tracking input {
  background: transparent;
  border: 1px solid #cecece;
  height: 60px;
  padding: 10px 20px;
  color: #000000;
  font-size: 14px;
}

.order-tracking-content .order-tracking-form .sin-order-tracking input:focus {
  border: 1px solid #262626;
}

.order-tracking-content .order-tracking-form .order-track-btn {
  text-align: center;
}

.order-tracking-content .order-tracking-form .order-track-btn a {
  display: block;
  font-weight: 600;
  color: #ffffff;
  background-color: #000000;
  line-height: 1;
  padding: 22px 45px;
  text-transform: uppercase;
}

.order-tracking-content .order-tracking-form .order-track-btn a:hover {
  background-color: #ff2f2f;
}
